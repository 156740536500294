import styled from "styled-components";

export const Container = styled.div`

    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

`;

export const MainTable = styled.div`

    display: flex;
    flex-direction: column;
    width: 85%;

    @media only screen and (max-width: 720px)  {
        width: 90%;
    }

`;

export const TableHeader = styled.div`

    display: flex;

    color: #FFF;
    font-weight: 400;
    padding: 1rem;
    text-align: left;
    line-height: 1.5rem;
    background: var(--purple);

    h3{
        color: #FFF;
    }  

    select{
        background: var(--purple);
        margin: 0 .7rem;
        border: none;
        color: #FFF;
        font-size: 1.2rem;
        font-weight: bold;
    }
`;


export const ClassItem = styled.div`

    cursor: pointer;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    transition: all .3s;

    p,strong{
        color: var(--text-body);
    }

    svg{
        margin: 0;
        padding: 0;
        color: var(--text-body);
    }

    &:hover{
        filter: brightness(.9);
        transition: all .3s;
    }

`