import { Container, RegisterForm, RegisterImage } from "./styles";
import registerImg from '../../../assets/images/register-image.png'
import { useState } from "react";
import { IMaskInput } from "react-imask";
import { cpf } from 'cpf-cnpj-validator'
import { api } from "../../../services/api";
import { useModals } from "../../../hooks/useModalsContext";

export function Register(){

    const defaultMessages = {
        'none':{
            code:'none',
            color:'',
            message:'',
        },
        'validation':{
            code:'validation',
            color:'#a21300',
            message:'Preencha todos os dados para cadastrar',
        },
        'error':{
            code:'error',
            color:'#a21300',
            message:'Aconteceu algum erro! Entre em contato com suporte@classhelper.com ou tente novamente mais tarde',
        },
        'success':{
            code:'success',
            color:'#218838',
            message:'Cadastro efetuado com sucesso! Verifique seu e-mail!',
        },
    }
    const [ user, setUser ] = useState({
        name:'',
        email:'',
        cpf:'',
        mobile:''
    })
    const [ invalidEmail, setinvalidEmail ] = useState('none')
    const [ invalidCPF, setinvalidCPF ] = useState('none')
    const [ message, setMessage ] = useState(defaultMessages['none'])
    const { handleOpenTermsModal } = useModals()
    const [ inviteCode, setInviteCode ] = useState('')

    async function validateEmail(email:string){

        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        
        if (!email.match(validRegex)) {
            setinvalidEmail('invalid')
            updateUser('email','')
        }else{
            const { data } = await api.post('/checkduplicateemail',{email:email})
            if(data){
                setinvalidEmail('duplicate')
                updateUser('email','')
            }else{
                setinvalidEmail('none')
            }
        }
    }

    async function validateCPF(value:string){
        if(!cpf.isValid(value)){
            setinvalidCPF('invalid')
            updateUser('cpf','')
        }else{
            const cpfvar = value.replace(/[^?0-9]/g,"")
            const { data } = await api.post('/checkduplicatecpf',{cpf:cpfvar})
            if(data){
                setinvalidCPF('duplicate')
                updateUser('cpf','')
            }else{
                setinvalidCPF('none')
            }
            
        }
    }

    function updateUser(att:any, value:any){

        switch(att){
            case 'name':
                setUser({...user, name:value})
                break;
            case 'email':
                setUser({...user, email:value})
                break;
            case 'cpf':
                setUser({...user, cpf:value})
                break;
            case 'mobile':
                setUser({...user, mobile:value})
                break;
        }

    }

    async function registerUser(){
        if(user.name !=='' && user.email !=='' && user.cpf && user.mobile !==''){
            const payload = {

                name:user.name,
                email:user.email,
                cpf:user.cpf,
                mobile:user.mobile

            }


            const data = await api.post('registeruser',payload)

            if(data?.status === 201){
                setMessage(defaultMessages['success'])
                setUser({
                    name:'',
                    email:'',
                    cpf:'',
                    mobile:''
                })
            }

        }else{
            setMessage(defaultMessages['validation'])
        }
    }


    return(
        //@ts-ignore
        <Container id="register">
            <RegisterForm>
                
                {
                    (inviteCode==='ch2023'||inviteCode==='CH2023' || true)
                    ?<>
                        <h2>Se cadastre aqui</h2>
                        <p>E teste <strong>grátis</strong> por 7 dias!</p>
                        <span style={{marginTop:'2rem'}}>
                        
                            <IMaskInput 
                                //@ts-ignore
                                type="text" 
                                placeholder="Nome completo" 
                                value={user?.name}
                                onChange={(e:any)=>updateUser('name', e.target.value)}
                            />

                            {
                                invalidEmail === 'invalid'
                                ?<p style={{paddingLeft:'1rem', color:'#a21300'}}>E-mail inválido</p>
                                :invalidEmail === 'duplicate'
                                ?<p style={{paddingLeft:'1rem', color:'#a21300'}}>E-mail já utilizado</p>
                                :null
                            
                            }
                            
                            <IMaskInput 
                                //@ts-ignore
                                type="email" 
                                placeholder="E-mail" 
                                value={user?.email}
                                onChange={(e:any)=>updateUser('email', e.target.value)}
                                onBlur={(e:any)=>validateEmail(e.target.value)}
                            />

                                {
                                    invalidCPF === 'invalid'
                                    ?<p style={{paddingLeft:'1rem', color:'#a21300'}}>CPF inválido</p>
                                    :invalidCPF === 'duplicate'
                                    ?<p style={{paddingLeft:'1rem', color:'#a21300'}}>CPF já existe</p>
                                    :null
                                }

                            <div className="twoColums">

                                <IMaskInput 
                                    //@ts-ignore
                                    type="text" 
                                    mask="000.000.000-00"
                                    placeholder="CPF"
                                    value={user?.cpf}
                                    onChange={(e:any)=>updateUser('cpf', e.target.value)}
                                    onBlur={(e:any)=>validateCPF(e.target.value)}
                                    
                                />                        
                                
                                <IMaskInput 
                                    //@ts-ignore
                                    type="text" 
                                    mask="(00) 00000-0000"
                                    placeholder="Celular" 
                                    value={user?.mobile}
                                    onChange={(e:any)=>updateUser('mobile', e.target.value)}
                                />

                            </div>
                            <p style={{marginTop:'1rem', color:'#777'}}>
                                Ao se cadastrar você concorda com os <a href="#" onClick={handleOpenTermsModal}>termos de uso e políticas de privacidade</a> do ClassHelper
                            </p>
                            {
                                message.code !== 'none'
                                ?<p style={{marginTop:'.5rem', color:message.color}}>{message.message}</p>
                                :null
                            }
                            <div style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
                                <button className="submitButton" onClick={registerUser}>Cadastrar</button>
                            </div>
                        </span>
                    </>
                    :<>
                        <h2>Digite seu código de convite</h2>
                        <input type="password" style={{width:'50%', marginTop:'2rem'}} 
                            placeholder="Código" 
                            onChange={(e)=>setInviteCode(e.target.value)}
                        />
                        <p style={{color:'#666',marginTop:'2rem'}}>Para solicitar seu código entre em contato pelo e-mail: <a href="mailto:contato@classhelper.com.br">contato@classhelper.com.br</a></p>
                    </>
                }
                
            </RegisterForm>
            <RegisterImage>
                <img className="registerImage" src={registerImg} alt="" />
            </RegisterImage>

        </Container>
    )
}