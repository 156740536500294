import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Container, Menu, NewStudent, Pill, Students, PillUser } from './styles'


import closeImg from '../../assets/images/close.svg'
import { useModals } from '../../hooks/useModalsContext'
import { api } from '../../services/api'
import { useInfoUtils } from '../../hooks/useInfoUtilsContext'

import { AiOutlineEyeInvisible, AiOutlineEye, AiFillDelete, AiOutlineFileSync } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
// import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { RiMailSendLine } from 'react-icons/ri'




export function StudentsModal(){

    const { isStudentsModalOpen, handleCloseStudentsModal, dataStudentModal, setDataStudentModal, handleOpenAskStudentNewClassModal } = useModals()
    const [ newStudentName, setNewStudentName ] = useState('')
    const [ newStudentEmail, setNewStudentEmail ] = useState('')
    const [ newStudentValue, setNewStudentValue ] = useState(0)
    const [ students, setStudents ] = useState([] as any)
    const [ editingStudent, setEditingStudent ] = useState({} as any)
    const { reload } = useInfoUtils();
    const [ invalidEmail, setInvalidEmail ] = useState('none')

    async function getClass(){

      const { data } = await api.get('getoneclassofteacher/'+dataStudentModal.id)

      if(data){
        setStudents(data[0].students)
        reload()
      }
      
    }

    async function validateEmail(email:string){

      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
      if (!email.match(validRegex)) {
          setInvalidEmail('invalid')
          setNewStudentEmail('')
      }else{
          const { data } = await api.post('/checkduplicateemail',{email:email})
          if(data){
              setInvalidEmail('duplicate')
              setNewStudentEmail('')
          }else{
              setInvalidEmail('none')
          }
      }
  }

    async function sendEmail(item:any){

      const data = await api.post('sendemailstudent',{name:item.name,email:item.email})

      const newList = students.map((student:any)=>{
        if(student.id === item.id){
          return {...student, resend: true}
        }else{
          return{
            ...student
          }
        }
      })

      setStudents(newList)

    }

    useEffect(()=>{

      if(dataStudentModal?.default_value>0){
        setNewStudentValue(parseFloat(dataStudentModal?.default_value))
      }
  
      if(dataStudentModal?.students){
          
        setStudents(dataStudentModal?.students)
      }
    },[dataStudentModal])

    useEffect(()=>{
      
      setNewStudentName(editingStudent.name)
      setNewStudentEmail(editingStudent.email)
      setNewStudentValue(editingStudent.student_value)
      
    },[editingStudent])


    async function addStudent(){

      if(editingStudent.id>0){

        await api.put('editstudent/'+editingStudent.id,{
          name:newStudentName,
          email:newStudentEmail,
          value:newStudentValue,
          id_class:dataStudentModal.id
        })

        setEditingStudent({
          "id": 0,
          "name": "",
          "email": "",
          "student_value": 0
      })

      }else{

        await api.post('createstudent',{
          name:newStudentName,
          email:newStudentEmail,
          value:newStudentValue,
          id_class:dataStudentModal.id
        })
      
      }


      const { data } = await api.get('getoneclassofteacher/'+dataStudentModal.id)

      setStudents(data[0]?.students)

      setNewStudentEmail('')
      setNewStudentName('')
      setNewStudentValue(parseFloat(dataStudentModal?.default_value))
      

      reload()

    }

    async function blockStudent(student:any) {
      
      await api.get('blockstudent/'+student.id)
      getClass()

    }


    async function deleteStudent(student:any) {

      const confirmation = window.confirm('Deseja realmente excluir o aluno?')

      if(confirmation){

        await api.get('deletestudent/'+student.id)
        getClass()

      }      

    }

    async function selectNewClass(student:any) {
      handleCloseStudentsModal()
      handleOpenAskStudentNewClassModal({...student,class_id:dataStudentModal?.id})
    }
    

   


    return(
        <Modal 
          isOpen={isStudentsModalOpen}
          onRequestClose={handleCloseStudentsModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content full-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseStudentsModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

            <h3>Alunos</h3>
            <Menu>
              
              <div style={{width:'100%', margin:'.2rem'}}>
                <input type="text" placeholder='Nome' value={newStudentName} onChange={(e)=>setNewStudentName(e.target.value)} />
              </div>
              
              <div style={{width:'100%', margin:'.2rem'}}>
                <input 
                  type="email" 
                  placeholder='E-mail' 
                  value={newStudentEmail} 
                  onChange={(e)=>setNewStudentEmail(e.target.value)} 
                  onBlur={(e:any)=>validateEmail(e.target.value)}
                  />
              </div>
              <div className='valueStudent'>
                <p>R$</p>
                <input type="number" value={newStudentValue} onChange={(e)=>setNewStudentValue(parseFloat(e.target.value))} />
              </div>
              

              {
                editingStudent.id>0
                ?<>
                  {/*@ts-ignore*/}
                  <Pill onClick={()=>setEditingStudent({
                                          "id": 0,
                                          "name": "",
                                          "email": "",
                                          "student_value": 0
                                      })}>
                    Cancelar
                  </Pill>
                  {/*@ts-ignore*/}
                  <Pill onClick={addStudent}>
                    Salvar
                  </Pill>
                </>//@ts-ignore
                :<Pill onClick={addStudent}>
                  Adicionar
                </Pill>
              }

            </Menu>
            {
              invalidEmail==='invalid'
              ?<p style={{fontSize:'.8rem', color:'#ad1300', marginTop:'.5rem', marginLeft:'1rem'}}>E-mail inválido!</p>
              :invalidEmail==='duplicate'
              ?<p style={{fontSize:'.8rem', color:'#ad1300', marginTop:'.5rem', marginLeft:'1rem'}}>E-mail já está em uso por outro usuário!</p>
              :null
            }
            
            <Students>

              {
                students !== undefined && students.length >0
                ?students.map((item:any)=>{
                  return(
                    //@ts-ignore
                    <PillUser className={item.active?'':'inactiveUser'}>
                          <span>
                            <div className='name'>
                              <strong>{item?.name}</strong>
                            </div>
                            <div style={{fontSize:'.8rem'}}>
                              {item?.email}
                            </div>
                            <div style={{width:'100%', justifyContent:'center'}}>
                              <p>R$ {item?.student_value}</p>
                            </div>
                          </span>
                          <span>
                            <div>
                              <RiMailSendLine title='Enviar e-mail com senha'
                                style={{color:item.resend?'green':'black'}}
                                onClick={()=>sendEmail(item)}
                              />
                              <FiEdit title='Editar'
                                onClick={()=>setEditingStudent(item)}
                              />
                              {
                                item.active
                                ?<AiOutlineEyeInvisible title='Desativar aluno'
                                  onClick={()=>blockStudent(item)}
                                />
                                :<AiOutlineEye title='Ativar aluno'
                                  onClick={()=>blockStudent(item)}
                                />
                              }
                              
                              
                              <AiFillDelete title='Excluir aluno'
                                onClick={()=>deleteStudent(item)}
                              />

                              <AiOutlineFileSync title="Mudar aluno de Turma"
                                onClick={()=>selectNewClass(item)}
                              />
                            </div>
                          </span>
                        </PillUser>
                  )
                })
                :null
              }

            </Students>
          
          </Container>

        </Modal>
    )
}