import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import './styles.css'

export function LoadingScreen(){


    const navigate = useNavigate();

    async function LoadNewClass() {

        setTimeout(() => {
   
            navigate('/watch');
        }, 2000);

    }

    useEffect(()=>{

        LoadNewClass();

    },[])

    
    return(
        <>
            <svg  xmlns="http://www.w3.org/2000/svg"   width="197px" height="197px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <rect x="19.5" y="34.5" width="11" height="31" fill="#da70dc">
                <animate attributeName="y" repeatCount="indefinite" dur="1.4492753623188404s" calcMode="spline" keyTimes="0;0.5;1" values="28.3;34.5;34.5" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2898550724637681s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1.4492753623188404s" calcMode="spline" keyTimes="0;0.5;1" values="43.4;31;31" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2898550724637681s"></animate>
                </rect>
                <rect x="44.5" y="34.5" width="11" height="31" fill="#a53faa">
                <animate attributeName="y" repeatCount="indefinite" dur="1.4492753623188404s" calcMode="spline" keyTimes="0;0.5;1" values="29.85;34.5;34.5" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.14492753623188406s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1.4492753623188404s" calcMode="spline" keyTimes="0;0.5;1" values="40.3;31;31" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.14492753623188406s"></animate>
                </rect>
                <rect x="69.5" y="34.5" width="11" height="31" fill="#da70dc">
                <animate attributeName="y" repeatCount="indefinite" dur="1.4492753623188404s" calcMode="spline" keyTimes="0;0.5;1" values="29.85;34.5;34.5" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1.4492753623188404s" calcMode="spline" keyTimes="0;0.5;1" values="40.3;31;31" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                </rect>
            </svg>
        </>
    )
}