import styled from "styled-components";

export const Container = styled.div`

    max-height: 70vh;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    
`;

export const IndividualContact = styled.div`

    cursor: pointer;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        margin-top: 1rem;

        p{
            display: inline-block;            
        }

        span{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 1rem;
            small{
                color: var(--text-body);
            }
        }
    }

    hr{
        padding-left: 5rem;
        margin-top: 1rem;
        opacity: 0.3;
    }
`;

interface ProfileImageProps {
    hasNewMessage: boolean
}

export const ProfileImage = styled.img<ProfileImageProps>`
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    border: ${(props)=>props.hasNewMessage?'2px red solid':'0'};
`;

export const SearchBar = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-around;

    border: 1px solid #d7d7d7;
    background: #e7e9ee;
    border-radius: 0.25rem;

    margin-right: 1rem;
    margin-bottom: 1rem;

    img{
        width: 1rem;
        margin: 0 0.5rem;
    }
    input{
        width: 90%;
        padding: 0 1.5rem;
        height: 2rem;
        background: transparent;
        border: 0;       
        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
    }
`;