import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Container, PillLevel, PillContainer } from './styles'


import closeImg from '../../assets/images/close.svg'
import { useModals } from '../../hooks/useModalsContext'
import { api } from '../../services/api'
import { useInfoUtils } from '../../hooks/useInfoUtilsContext'



export function AddClassModal({data}:any){

    const { isAddClassModalOpen, handleCloseAddClassModal, dataClassModal, handleSetDataClass } = useModals()
    const { reload } = useInfoUtils();
    const [ alertMessage, setAlertMessage ] = useState(false)

    const [ newClass, setNewClass ] = useState({
        name:'',
        id_subject:0,
        default_value:'',
        active:true
    })

    const [ subjects, setSubjects ] = useState([])
    // const [ selectedSubject, setSelectedSubject ] = useState({} as any)
    const [ levels, setLevels ] = useState([])

    function chooseSubject(id:number){
        const theSubject = subjects.filter((item:any)=>item.id===id)
        //@ts-ignore
        setLevels(theSubject[0].levels)
        setNewClass({...newClass, id_subject:id})
        
    }

    async function getSubjects(){
        const { data } = await api.get('subjects')
        setSubjects(data)
    }

    async function selectLevel(id:number){
        const newLevelList = levels.map((item:any)=>{
            if(item.id === id){
                return {...item, selected:!item?.selected}
            }else{
                return {...item}
            }
        })
        //@ts-ignore
        setLevels(newLevelList)
    }


    async function setEditData(){
        if(dataClassModal?.id>0){


            setNewClass({
                name:dataClassModal.name,
                id_subject:dataClassModal.id_subject,
                default_value:dataClassModal.default_value,
                active:true
            })

            const theSubject = subjects.filter((item:any)=>item.id===dataClassModal.id_subject)
            //@ts-ignore
            const theSubjectLevels = theSubject[0].levels
            
            const levelsOnTable = await api.get('getclasslevels/'+dataClassModal.id)
                    
            //@ts-ignore
            levelsOnTable.data.map((item)=>{
                theSubjectLevels.map((tsbl:any)=>{
                    if(tsbl.id === item){
                        tsbl.selected = true
                    }
                })
            })

            
            //@ts-ignore
            setLevels(theSubjectLevels)

        }else{
            setNewClass({
                name:'',
                id_subject:0,
                default_value:'',
                active:true
            })
        }
    }

    useEffect(()=>{

        getSubjects()
        setEditData()

    },[isAddClassModalOpen])

    async function replaceLevels(id:number){
        const levelsToSave = levels.filter((level:any)=>level.selected === true)

        await api.post('replacelevels/'+id,levelsToSave)

    }

    async function saveNewClass(){


        if(newClass.name !== '' && newClass.id_subject >0){

            if(dataClassModal.id>0){

                const { data } = await api.put('classes/'+dataClassModal.id,{
                    name:newClass.name,
                    id_subject:newClass.id_subject,
                    default_value:newClass.default_value!==''?parseInt(newClass.default_value):0,
                    active:true
                })
                replaceLevels(data.id)

            }else{

                const { data } = await api.post('classes',{
                    name:newClass.name,
                    id_subject:newClass.id_subject,
                    default_value:newClass.default_value!==''?parseInt(newClass.default_value):0,
                    active:true
                })
                replaceLevels(data.id)

            }

            

            setNewClass({
                name:'',
                id_subject:0,
                default_value:'',
                active:true
            })

            reload()
            setAlertMessage(false)

            handleCloseAddClassModal()

        }else{
            setAlertMessage(true)
        }
    

    }


   


    return(
        <Modal 
          isOpen={isAddClassModalOpen}
          onRequestClose={handleCloseAddClassModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content-small percent90"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseAddClassModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

            <h3>Nova turma</h3>

            <input type="text" placeholder='Nome da Turma' value={newClass.name} onChange={(e)=>setNewClass({...newClass, name:e.target.value})} />
            <input type="number" placeholder='Valor padrão por aluno' value={newClass.default_value} onChange={(e)=>setNewClass({...newClass, default_value:e.target.value})}/>
            <select onChange={(e)=>chooseSubject(parseInt(e.target.value))}>
                <option value={0} >Selecione a matéria</option>
                {
                    subjects.map((subject:any)=>{
                        
                        return(
                            <option selected={subject.id === newClass.id_subject?true:false} value={subject.id}>{subject.name}</option>
                        )
                    })
                }
                
            </select>

            {
                levels?.length>0
                ?<>
                <p style={{marginLeft:0,color:'#444'}}>Selecione os níveis</p>
                <PillContainer>
                    {levels?.map((level:any)=>
                        //@ts-ignore
                        <PillLevel
                            className={level?.selected?'selected':''}
                            onClick={()=>selectLevel(level.id)}
                        >
                            {level?.name}                        
                        </PillLevel>
                        
                        )
                    }
                </PillContainer>

                </>                    
                :null
            }

            {
                alertMessage
                ?<p>Preencha os campos acima antes de salvar</p>
                :null
            }

            <button onClick={saveNewClass}>Salvar</button>

          
          </Container>

        </Modal>
    )
}