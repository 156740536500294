import { Container, IndividualContact, ProfileImage, SearchBar } from './styles'
import { api } from '../../../services/api';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import profileImg from '../../../assets/images/profile.svg'
import searchImg from '../../../assets/images/search.svg'
import { useEffect, useState } from 'react';

interface Contact {
    id: number,
    name: string,
    hasNewMessage: boolean,
    photo: string
    class:{
        id: number,
        name: string
    }
}

interface ActiveChatData{
    userId: number
    userName: string
    userPhoto: string
    className: string

}

interface ChatContactsProps {

    refreshContacList: boolean
    handleChangeChatData: (transaction: ActiveChatData) => Promise<void>;

}

export function ChatContacts({ handleChangeChatData, refreshContacList }:ChatContactsProps){

    const [ userContacts, setUserContacts ] = useState<Contact[]>([])

    const [ filteredContacts, setFilterdContacts ] = useState('')

    async function getUserContacts() {

        const { data } = await api.get('/contacts')
        setUserContacts(data)

        handleChangeChatData({
            userId: data[0].id,
            userName: data[0].name,
            userPhoto: data[0].photo,
            className: data[0].class.name,
        })
        
    }

    useEffect(()=>{

        getUserContacts()
        

    },[refreshContacList])
    

    return(
        <Container>
            <SearchBar>
                <img src={searchImg} alt="" />
                <input type="text" placeholder="Contato" onChange={(e)=>setFilterdContacts(e.target.value)} />
            </SearchBar>

            <SimpleBar style={{ maxHeight: '60vh' }}>
               
                {userContacts.filter(item=> item.name.toLowerCase().includes(filteredContacts.toLowerCase())).map((item)=>{
                        return(
                            //@ts-ignore
                            <IndividualContact key={item.id}>
                                <div 
                                    onClick={()=>{
                                        handleChangeChatData(
                                            {
                                                userId:item.id,
                                                userName: item.name,
                                                userPhoto: item.photo,
                                                className: item.class.name
                                            }
                                        )
                                    }}
                                    >
                                    {/*@ts-ignore*/}
                                    <ProfileImage src={item.photo?item.photo:profileImg} hasNewMessage={item.hasNewMessage} alt="" />
                                    <span>
                                        <p>{item.name}</p>
                                        <small>{item.class.name}</small>
                                    </span>
                                </div>
                                <hr />
                            </IndividualContact>
                        )
                            
                    })}

            </SimpleBar>
            
           
            
        </Container>
    )
}