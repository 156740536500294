import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    padding: 1rem;

    h3{
        margin-bottom: 1rem;
    }
    /* .topMargin{
        margin-top: 1rem;
    } */

    input{
        width: 100%;
        height: 2rem;
        border-radius: 1rem;
        border: none;
        padding-left: 1rem;
        color: #888;
    }

    div{
        padding: 0;
    }

    select{
        display: inline-block;
        height: 3rem;
        border-radius: .25rem;
        border: 0;
        margin: 1rem 0;
        padding-left: 1rem;
        font-size: 1.2rem;
        color: var(--text-body);
    }

    button{
        height: 3rem;
        color: #FFF;
        font-size: 1.2rem;
        background: var(--purple);
        border-radius: .25rem;
        border: 0;
        margin: 1rem 0;
    }


   


`;