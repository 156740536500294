
import Modal from 'react-modal'

import { Container } from './styles'
import  cogImg from '../../assets/images/cog.svg'

import closeImg from '../../assets/images/close.svg'
import { useModals } from '../../hooks/useModalsContext'


export function TutorialVideoModal(){

   
    const { isTutorialVideoModalOpen, videoTutorialVideoModal, handleCloseTutorialVideoModal } = useModals()

    function dontShowAnymore(){
      localStorage.setItem("donotshow", 'true')
      window.alert('Você pode ver novamente o vídeo clicando na engrenagem  e depois em Ajuda')
      handleCloseTutorialVideoModal()
    }
    

    return(
        <Modal 
          isOpen={isTutorialVideoModalOpen}
          onRequestClose={handleCloseTutorialVideoModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseTutorialVideoModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>
          <h3>Aprenda a usar o ClassHelper</h3>
          <br />
          <iframe 
            width="100%" 
            height="100%" 
            style={{border:'none', aspectRatio: '16 / 9', marginTop:'1rem', borderRadius:'1rem'}}
            src={videoTutorialVideoModal}
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
          </iframe>

          <p style={{marginTop:'1rem',marginBottom:'1rem'}}>Você pode encontrar esse vídeo clicando em <img src={cogImg} width={'20px'} alt={'Configurações'} /> e depois em <strong>Ajuda</strong> </p>
          <div style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
            <button   
              className='dontShow'
              onClick={dontShowAnymore}
              >Não mostrar mais</button>
          </div>
         
          </Container>

        </Modal>
    )
}