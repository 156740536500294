import { useEffect, useState } from "react";
import { ClassItem } from "../ClassItem";
import { Container, MainTable, TableHeader } from "./styles";

import { api } from "../../services/api";
import { AddButton } from "../AddButton";
import { AddClassModal } from "../AddClassModal";
import { useInfoUtils } from "../../hooks/useInfoUtilsContext";
import { StudentsModal } from "../StudentsModal";
import { useNavigate } from 'react-router-dom';

interface Class{
    id: number
    name: string
    subject: string
    active: boolean
    students: []
}

export function ClassesTable(){

    const [ classes, setClasses ] = useState<Class[]>([])
    const [ userRole, setUserRole] = useState(0)
    const { infoUtils,reloadPage } = useInfoUtils();
    const navigate = useNavigate();
    

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")
        setUserRole(data.userRole)
    }

    async function getClasses(){
        const { data } = await api.get('/getclassesteacher')
        setClasses(data)
    }

    useEffect(()=>{

        if(!infoUtils.allowAccess){
            navigate('/purchase')
        }
        

        getUserInfo()        

    },[])

    useEffect(()=>{
        
        getClasses()       

    },[reloadPage])

    return(
        <Container>
            {classes.length === 0
            ?<p>Nenhuma turma cadastrada</p> 
            :<MainTable>
                    <TableHeader>
                        <h3>Tuma</h3>
                    </TableHeader>
                        {
                            classes.map((item => <ClassItem key={ item.id } data={ item } getClasses={getClasses} />))
                        }
                </MainTable>

            }
            
                {
                    userRole===1
                    ?<AddButton isClass />
                    :null
                }
                
                <AddClassModal />
                <StudentsModal />

        </Container>
    )
}