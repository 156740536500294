import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Container } from './styles'


import closeImg from '../../../assets/images/close.svg'
import { useModals } from '../../../hooks/useModalsContext'




export function MenuModal(){

    const { isMenuModalOpen, handleCloseMenuModal } = useModals()


   


    return(
        <Modal 
          isOpen={isMenuModalOpen}
          onRequestClose={handleCloseMenuModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content-menu"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseMenuModal} 
            className="react-modal-close"
          >
            <img style={{filter:'brightness(5)'}} src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

                    <a href="#home" onClick={handleCloseMenuModal} >início</a>
                    <a href="#about" onClick={handleCloseMenuModal} >sobre</a>
                    <a href="#register" onClick={handleCloseMenuModal} >cadastrar</a>
                    <a href="#contact" onClick={handleCloseMenuModal} >contato</a>
                    
                    <Link className="rounded" to="/login" >
                      <p>entrar</p>
                  </Link>
          
          </Container>

        </Modal>
    )
}