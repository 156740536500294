import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const RedirectToLogin = () => {

    const navigate = useNavigate();

    function redirect(){

        navigate('/login')

    }

    useEffect(()=>{
        redirect()
    },[])

    return(
        <>
        </>
    )
    
 }

 export default RedirectToLogin;