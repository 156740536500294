import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useInfoUtils } from '../../hooks/useInfoUtilsContext'
import { api } from '../../services/api'
import { Container } from './styles'


import closeImg from '../../assets/images/close.svg'
import  playTitleImg from '../../assets/images/play-title.svg'
import { useModals } from '../../hooks/useModalsContext'
import { StorageUse } from './StorageUse'
import { PlanInfo } from './PlanInfo'
import { Navigation } from './Navigation'
import { PersonalData } from './PersonalData'
import { SubjectsAndLevels } from './SubjectAndLevels'
import { Help } from './Help'

interface Class{
    id: number
    name: string
    subject: string
}


export function UserModal(){

    

    const [ activeScreen, setActiveScreen ] = useState('general')


    

    const [ selectedClass, setSelectedClass ] = useState(0)

    const [ classes, setClasses ] = useState<Class[]>([])

    const { infoUtils, setInfoUtils } = useInfoUtils()
    const { isUserModalOpen, handleCloseUserModal } = useModals()

    const navigate = useNavigate();

    async function getClasses(){
        const { data } = await api.get('/getclassesteacher')
        setClasses(data)
        setSelectedClass(data[0]?.id)
    }

    useEffect(()=>{
        getClasses();
        
    },[])

    function handleSelectClass(){

        

        let newClass = ''
        classes.map(item=>item.id===selectedClass?newClass=item.name:'')

        setInfoUtils({
            ...infoUtils,
           screen: `Dar Aula ${newClass}`
        })

        handleCloseUserModal()

        navigate('/loading');


    }

    return(
        <Modal 
          isOpen={isUserModalOpen}
          onRequestClose={handleCloseUserModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content full-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseUserModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>
          <h3>Usuário</h3>

          <Navigation activeScreen={activeScreen} setActiveScreen={setActiveScreen} />

          {
            activeScreen==='general'
            ?<>
                <StorageUse />
                <PlanInfo />
            </>
            :activeScreen==='personal'
            ?<>
                <PersonalData />
            </>
            :activeScreen==='config'
            ?<>
                <SubjectsAndLevels />
            </>
            :<>
                <Help />
            </>
          }


          
          
          </Container>

        </Modal>
    )
}