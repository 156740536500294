import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    padding: 1rem;

    .smallText{
        color: #888;
        font-size: .8rem;
        margin-bottom: .5rem;
    }

`

export const ProgressBar = styled.div`

    display: flex;
    width: 100%;
    background: #ccc;
    border-radius: 1rem;
    overflow: hidden;

    .used{
        width: 1%;
        height: 1rem;
        background: var(--purple);
        
        /* border-radius: 1rem; */
    }



`