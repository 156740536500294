import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { Container, Levels, Pill, Subjects } from "./styles";
import deleteImg from '../../../assets/images/delete.svg'
import { HiArrowCircleUp, HiArrowCircleDown } from 'react-icons/hi'
import { AiFillSave, AiFillDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { GiConfirmed, GiCancel } from 'react-icons/gi'

export function SubjectsAndLevels(){


    const [ subjects, setSubjects ] = useState([])
    const [ idEditingLevel, setIdEditingLevel ] =useState(0)
    const [ idEditingSubject, setIdEditingSubject ] =useState(0)
    const [ levels, setLevels ] = useState([])
    const [ newSubject, setNewSubject ] = useState('')
    const [ newLevel, setNewLevel ] = useState('')
    const [ confirmation, setConfirmation ] = useState(0)
    const [ confirmationSubject, setConfirmationSubject ] = useState(0)
    const [ selectedSubject, setSelectedSubject ] = useState({id:0, name:''})

    useEffect(()=>{
        
        getSubjects()

    },[])


    useEffect(()=>{
        getLevels()
        setConfirmation(0)
    },[selectedSubject])

    async function getSubjects(){
        const { data } = await api.get('/subjects')
        
        setSubjects(data)
    }


    async function upLevel(level:any){
        if(level.order===1){
            return
        }else{

            await api.put('levels/'+level.id,{order:level.order-1})
    
            const otherLevel = levels.filter((item:any) => item.order === level.order-1)
            //@ts-ignore
            await api.put('levels/'+otherLevel[0].id,{order:level.order})

            getLevels()
        }
        
    }

    async function downLevel(level:any){
        if(level.order===levels.length){
            return
        }else{

            await api.put('levels/'+level.id,{order:level.order+1})
    
            const otherLevel = levels.filter((item:any) => item.order === level.order+1)
            //@ts-ignore
            await api.put('levels/'+otherLevel[0].id,{order:level.order})

            getLevels()
        }
        
    }

    async function deleteLevel(level:any){

        
            setConfirmation(level.id)
            
            setTimeout(()=>setConfirmation(0), 5000)
        

    }

    async function finalDelete(){
        const {data} = await api.put('levels/'+confirmation,{deactivate:true})
        setConfirmation(0)
        getLevels()
    }

    async function deleteSubject(subject:any){

        setConfirmationSubject(subject.id)
        
        setTimeout(()=>setConfirmationSubject(0), 5000)
    

    }

    async function finalDeleteSubject(){
        const {data} = await api.put('subjects/'+confirmationSubject,{deactivate:true})
        setConfirmationSubject(0)
        getSubjects()
    }

    


    

    async function getLevels(){
        const { data } = await api.get('/levelsbysubject/'+selectedSubject.id)
        setLevels(data)
    }


    async function insertSubject(){

        if(newSubject !== ''){
            if(idEditingSubject>0){

                const {data} = await api.put('subjects/'+idEditingSubject,{name:newSubject})

                setIdEditingSubject(0)
                // setNewLevel('')
            }else{
                await api.post('/subjects',{name:newSubject})
            }
            
        }
        setNewSubject('')
        getSubjects()
        
    }

    async function editLevel(level:any){

        setIdEditingLevel(level.id)
        setNewLevel(level.name)

        setTimeout(()=>{
            setIdEditingLevel(0)
            setNewLevel('')
        }, 10000)

    }

    async function editSubject(subject:any){

        setIdEditingSubject(subject.id)
        setNewSubject(subject.name)

        setTimeout(()=>{
            setIdEditingSubject(0)
            setNewSubject('')
        }, 10000)

    }

    async function insertLevel(){

        if(newLevel !== ''){

            if(idEditingLevel>0){

                const {data} = await api.put('levels/'+idEditingLevel,{name:newLevel})

                setIdEditingLevel(0)
                setNewLevel('')
            }else{

                await api.post('/levels',{
                    name: newLevel,
                    order:levels.length>0?levels.length+1:1,
                    id_subject: selectedSubject.id,
                    active: true
          })

            }
            
        }
        setNewLevel('')
        getLevels()

    }

    return(
        <Container>

            <Subjects>
                <h5>Matérias</h5>
                <div className="insertNew">
                    <input type="text" value={newSubject} onChange={(e)=>setNewSubject(e.target.value)} />
                    <button onClick={insertSubject}>{idEditingSubject>0?<AiFillSave/>:'+'}</button>
                </div>
                <div style={{display:`${confirmationSubject===0?'none':'flex'}`, marginBottom:'1rem', width:'100%', justifyContent:'space-between'}}>
                    <p  style={{display:'inline'}}>Confirma exclusão?</p>
                    <div>
                        <GiConfirmed  
                            style={{display:'inline', marginRight:'1rem', cursor:'pointer'}}
                            onClick={finalDeleteSubject}
                            /> 
                        <GiCancel  
                            style={{display:'inline', cursor:'pointer'}}
                            onClick={()=>setConfirmationSubject(0)}
                            />
                    </div>
                </div>
                {
                    subjects.map((item:any)=>{
                        
                        return(
                            //@ts-ignore
                            <Pill
                                onClick={()=>setSelectedSubject({name:item.name, id:item.id})}
                                style={{background:selectedSubject.id===item.id?'#ccc':'transparent'}}
                            >
                                {item.name}
                                <span style={{display:'flex', width:'25%'}}>
                                    <FiEdit 
                                        onClick={()=>editSubject(item)}
                                    />
                                    <AiFillDelete
                                        onClick={()=>deleteSubject(item)} 
                                    />
                                </span>
                            </Pill>
                        )
                    })
                }
            </Subjects>

            <Levels>
                <h5>Níveis</h5>

                {
                    selectedSubject.id === 0
                    ?<p style={{width:'100%', color:'#666', textAlign:'center', marginTop:'2rem'}}>Selecione uma matéria</p>
                    :<>
                        <div className="insertNew">
                            <input type="text" value={newLevel} onChange={(e)=>setNewLevel(e.target.value)} />
                            <button onClick={insertLevel}>{idEditingLevel>0?<AiFillSave/>:'+'}</button>
                        </div>
                        <div style={{display:`${confirmation===0?'none':'flex'}`, marginBottom:'1rem', width:'100%', justifyContent:'space-between'}}>
                            <p  style={{display:'inline'}}>Confirma exclusão?</p>
                            <div>
                                <GiConfirmed  
                                    style={{display:'inline', marginRight:'1rem', cursor:'pointer'}}
                                    onClick={finalDelete}
                                    /> 
                                <GiCancel  
                                    style={{display:'inline', cursor:'pointer'}}
                                    onClick={()=>setConfirmation(0)}
                                    />
                            </div>
                        </div>
                        
                        {
                            levels.map((item:any)=>{
                                
                                return(
                                    <Pill >
                                        {item.name}
                                        <span style={{display:'flex', width:'40%'}}>
                                            <FiEdit 
                                                onClick={()=>editLevel(item)}
                                            />
                                            <HiArrowCircleUp 
                                                style={{zIndex:'10',color:`${item.order===1?'#ccc':'#333'}`}}
                                                onClick={()=>upLevel(item)}
                                            />
                                            <HiArrowCircleDown
                                                
                                                style={{zIndex:'10',color:`${item.order===levels.length?'#ccc':'#333'}`}}
                                                onClick={()=>downLevel(item)}
                                            />
                                            <AiFillDelete
                                                onClick={()=>deleteLevel(item)} 
                                            />
                                            {/* <img src={deleteImg} alt="Excluir" 
                                            onClick={deleteLevel}/> */}
                                        </span>
                                        
                                    </Pill>
                                )
                            })
                        }   
                    </>
                }
            </Levels>
        </Container>
    )
}