import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    width: 80%;

    @media (max-width: 720px){
        flex-direction: column;
    }

`;

export const RegisterForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 720px){
        width: 100%;
    }

    h2{
        margin-bottom: .2rem;
    }

    input{
        width: 100%;
        margin: .2rem 0;
        height: 2.2rem;
        border-radius: 1.1rem;
        padding-left: .5rem;
        font-size: 1rem;
        border: 1px solid #666;
        color: #666;
        
    }

    p{
        font-size: .8rem;
    }

    .twoColums{
        display: flex;
        width: 100%;
        input{
            width:50%;
            &+input{
                margin-left: .2rem;
            }
        }
        

    }

    .submitButton{
        width: 8rem;
        margin-right: 1rem;
        margin-top: 1rem;
        height: 2.4rem;
        border: 2px solid var(--purple);
        background: var(--purple);
        font-size: 1rem;
        font-weight: bold;
        border-radius: 1.2rem;
        color: #fff;

        &:hover{
            filter: brightness(1.2);
        }
    }

`;

export const RegisterImage = styled.div`
    display: flex;
    width: 50%;
    @media (max-width: 720px){
        width: 100%;
        margin-top: 5rem;
    }

    .registerImage{
        width: 100%;
    }

`;