import { FeedItem } from "./FeedItem";
import { Container } from "./styles";

import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { AddPost } from "./AddPost";
import { useInfoUtils } from "../../hooks/useInfoUtilsContext";
import { useNavigate } from 'react-router-dom';



interface FeedItemData {
    
    id: number
    created_at: string
    id_class: number
    class_name: string
    text: string
    postitems: PostItemProps[]
    
}

interface PostItemProps {
    id: number
    id_post: number
    type: string
    value: string
}



export function Feed(){

    const [ feedItems, setFeedItems ] = useState<FeedItemData[]>([])
    const [ metaInfo, setMetaInfo ] = useState( {} as any )
    const [ classId, setClassId] = useState(-1)
    const [ teacherClasses, setTeacherClasses ] = useState([] as any)
    const [ userInfo, setUserInfo ] = useState({} as any)
    const { infoUtils } = useInfoUtils();
    const navigate = useNavigate();

    async function getTeacherClasses(){

        const { data } = await api.get("/getclassesteacher")

        setTeacherClasses(data)

        
    }

    async function getFeed(){


        if(classId>=0){

            const { data } = await api.get("/postsbyclass/"+classId)

        
            setFeedItems(data.data)
            setMetaInfo(data.meta)

        }

        
    }

    

    async function getMore(url:string){

        const { data } = await api.get("/postsbyclass/"+classId+url)
        // const { data } = await api.get("/posts"+url)

        setMetaInfo(data.meta)

        setFeedItems([...feedItems, ...data.data])

    }

    async function getUserInfo(){

        const userInfo = await api.get("/getuserinfo")


        setUserInfo(userInfo.data)

        if(userInfo.data.userRole === 2){
            setClassId(userInfo.data.userClasses[0])
        }else{
            setClassId(0)
            getTeacherClasses()
        }

    }

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };

      useEffect(()=>{
        
        getFeed()

    },[classId])

      useEffect(()=>{
        if(!infoUtils.allowAccess){
            navigate('/purchase')
        }
        getUserInfo()
      },[])

    return(
        <Container>

            

                {
                    userInfo.userRole===1
                    ?<>
                        <AddPost reload={getFeed} teacherClasses={teacherClasses} />
                        <span className="smallLine">
                            <select className="classSelector" onChange={(e)=>setClassId(parseInt(e.target.value))}>
                                <option value={0}>Ver todos</option>
                                {
                                    teacherClasses.map((item:any)=><option value={item.id}>{item.name}</option>)
                                }
                                
                            </select>
                        </span>
                    </>
                    :null

                }
            
            {feedItems.length === 0 ?
                <p className="middleText">Nenhum post em seu feed!</p> :
                feedItems.map((feeditem)=>
                
                    
                        <FeedItem key={feeditem.id}  reload={getFeed}  data={ feeditem } />
                    
                )
            }
            <span style={{display:'flex'}}>
                {
                    metaInfo.next_page_url !== null
                    ?<button style={{marginRight:'1rem'}}  className="button" onClick={()=>getMore(metaInfo?.next_page_url)}>Carregar mais</button>
                    :null
                }
                {
                    feedItems.length > 3
                    ?<button className="button" onClick={scrollToTop}>Voltar ao topo</button>
                    :null
                }
                
            </span>

                

        </Container>
    )
}