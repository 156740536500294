import { ChatHead, ChatHistory, ChatType, Container, WhoWhenInfo } from "./styles";
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import profileImg from '../../../assets/images/profile.svg'
import { useRef, useEffect, useState } from "react";
import { api } from "../../../services/api";



interface ActiveChatData{
    userId: number
    userName: string
    userPhoto: string
    className: string

}

interface ActiveChatProps {

    activeChat: ActiveChatData
    setRefreshContactList: (refreshContacList: boolean) => void;

}

interface Message {
    chatUserId: number
    messages:Messages[]
}

interface Messages {
    type: string
    date: string
    content: string
}

export function ActiveChat({ activeChat, setRefreshContactList }:ActiveChatProps){

    const bottomRef = useRef<HTMLHeadingElement>(null);


    const [ messages, setMessages ] = useState<Message[]>([])

    const [ newMessage, setNewMessage ] = useState('')

    const [ sentNewMessage, setSendNewMessage ] = useState(0)

    async function getMessages() {

        const { data } = await api.get('/chat')
        setMessages(data)
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        
    }

    useEffect(()=>{
        getMessages() 
    },[sentNewMessage, activeChat])


    async function refreshHasMessageStats(){
        await api.patch('/contacts/'+activeChat.userId,{
            hasNewMessage:false
        })

        setRefreshContactList(true)
    }




    async function handleSendMessage(){

        await api.put('/chat/'+activeChat.userId,{
            id:activeChat.userId,
            chatUserId:activeChat.userId,
            messages:[
                ...messages.filter(chat=>chat.chatUserId===activeChat.userId)[0].messages,
                {
                    type:"to",
                    date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                    content: newMessage
                }
            ]

        })

        setSendNewMessage(sentNewMessage+1)
        setNewMessage('')


    }


    return(
        <Container>
            <ChatHead>
                <img src={activeChat.userPhoto?activeChat.userPhoto:profileImg} alt="" />
                <h3>{activeChat.userName} <small>{activeChat.className}</small></h3>
            </ChatHead>
            {/*@ts-ignore*/}
            <ChatHistory onFocus={refreshHasMessageStats}>
                <SimpleBar style={{ maxHeight: '48vh' }} >
                    <>
                    {
                        
                        messages.filter(message => message.chatUserId === activeChat.userId).length>0
                        ?messages.filter(message => message.chatUserId === activeChat.userId)
                        .map((message)=>
                            message.messages.map((item, index, arr)=>{
                                const prevMsg = arr[index - 1];
                                return(
                                    <span key={index}>
                                        {prevMsg!==undefined && prevMsg?.type!==item.type?<hr />:""}
                                        <WhoWhenInfo>
                                            {prevMsg?.type===item.type?<p></p>:<strong>{item.type==='from'?activeChat.userName:"Eu"}</strong>}
                                            <small>{
                                                format(new Date(), 'yy')===format(new Date(item.date), 'yy')
                                                ?format(new Date(), 'd')===format(new Date(item.date), 'd')
                                                    ?format(new Date(item.date), 'HH:mm', {locale:ptBR})
                                                    :format(new Date(item.date), 'd MMM', {locale:ptBR})
                                                :format(new Date(item.date), 'd MMM yy', {locale:ptBR})
                                            
                                            }</small>
                                        </WhoWhenInfo>
                                        <p>{item.content}</p>
                                        
                                    </span>
                                )
                            }
                                
                            )
                            
                        )
                        :
                        <div className="centeredMessage">
                            <p>Envie uma mensagem para iniciar uma conversa</p>
                        </div>
                    }

                    <span ref={bottomRef} />
                    </>

                </SimpleBar>
            </ChatHistory>
            <ChatType>
                <form onSubmit={event => event.preventDefault()}>
                    <input type="text" onFocus={refreshHasMessageStats} value={newMessage} onChange={(e)=>setNewMessage(e.target.value)} />
                    <button type="submit" onClick={handleSendMessage}>
                        Enviar
                    </button>
                </form>
            </ChatType>
        </Container>
    )
}