import styled from "styled-components";

export const Container = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    

    div{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;

        @media (max-width: 720px){
        flex-direction: column;
        }

        input{
            @media (max-width: 720px){
                margin-bottom: 1rem;
            }

            @media (min-width: 721px){
                &+input{
                    margin-right: 1rem;
                }

                &:first-child{
                    margin-right: 1rem;
                }
            }
        
        
            
        }

        button{
            min-width: 10rem;
            font-size: 1rem;
            height: 2rem;
            border-radius: 1rem;
            color: #fff;
            background: var(--purple);
            border: none;

            @media (max-width: 720px){
                width: 100%;
            }
        }
    }

    

`;