import { Container } from "./styles"
import { AiFillYoutube } from 'react-icons/ai'
import { BsCardImage } from 'react-icons/bs'
import { useState } from "react"
import { api } from "../../../services/api"
import { v4 as uuidv4 } from 'uuid';


export function AddPost({reload, teacherClasses}:any){

    const [media, setMedia] = useState('none')
    const [postText, setPostText] = useState('')
    const [videoLink, setVideoLink] = useState('')
    const [idClassPost, setIdClassPost] = useState(0)
    const [tempFile, setTempFile] = useState(null as any)

    async function uploadImage(){
        
        document.getElementById('fileInputField')?.click()
        setMedia('image')
        
    }

    async function resetNewPost(){
        
        setMedia('none')
        setPostText('')
        setVideoLink('')
    }

    async function handleSubmit(e:any){

        let typeItem = ''
        let valueItem = ''
        let fileSize = 0

        if(postText===''){
            window.alert('Digite alguma mensagem para postar')
            return
        }

        if(media==='image'){

            if(tempFile===null) return

            const originalFile = tempFile;
  
            if(originalFile.size > 10485760){
    
            window.alert('Tamanho máximo permitido: 10 Mb')
            return;
    
            }
    
            const ext = originalFile.type.split('/')
    
    
            if(ext[1] !=='png' && ext[1] !=='jpg' && ext[1] !=='jpeg'){
            window.alert('Extensões permitidas: jpeg, png, jpg')
            return;
    
            }

            fileSize = originalFile.size
    
            const file = new File([originalFile], uuidv4()+'.'+ext[1]);
    
            const requestObject = {
                fileName: file.name,
                fileType: file.type
            }
    
            const  { data }   = await api.post('/generateuploadlink',requestObject)
                
                fetch(data, {
                    method:'PUT',
                    body :file
                }).then((res) => {
                    // setMediaItems(mediaItems+1)
                })




            typeItem = 'image'
            valueItem = file.name
        }

        if(media==='video'){
            typeItem = 'video'
            valueItem = videoLink
        }

        const postInfo = {
            text:postText,
            id_class:idClassPost
        }

        const postItem = {
            type: typeItem,
            value: valueItem,
            size: fileSize
        }

        const result = await api.post('/posts',{postInfo,postItem})

        resetNewPost()

        setTimeout(()=>{
            reload()
          }, 1000)

        
    }

    async function saveTempFile(e:any) {

        setTempFile(e.target.files[0])
        
    }


    return(
        <Container>
            <textarea rows={5} onChange={(e)=>setPostText(e.target.value)} value={postText}></textarea>
            <input onChange={(e)=>setVideoLink(e.target.value)} value={videoLink} className={media==='video'?'videoInput show':'hide'} type="text" placeholder="Link do vídeo do Youtube" />
            <input id="fileInputField" className={media==='image'?'show':'hide'}  type="file" onChange={saveTempFile} />
            <span className={media==='none'?'line show':'hide'}>
                <div className="smallButton" onClick={uploadImage}><BsCardImage /> </div>
                <div className="smallButton" onClick={()=>setMedia('video')}><AiFillYoutube /></div>
            </span>
            <span className="line">
                <select className="classSelector" onChange={(e)=>setIdClassPost(parseInt(e.target.value))}>
                    <option value={0}>Postar para todos</option>
                    {
                        teacherClasses.map((item:any)=><option value={item.id}>{item.name}</option>)
                    }
                </select>
                <button style={{background:'grey'}} onClick={resetNewPost}>Cancelar</button>
                <button onClick={handleSubmit}>Postar</button>
            </span>
            
        </Container>
    )
}