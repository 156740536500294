import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;

    input{
        height: 2.2rem;
        border-radius: 1.1rem;
        padding-left: 1rem;
        margin: .5rem 0;
        border: none;
    }

    select{
        height: 2.2rem;
        border-radius: 1.1rem;
        padding-left: 1rem;
        margin: .5rem 0;
        border: none;
        background: #FFF;
    }

    button{
        background: var(--purple);
        color: #FFF;
        height: 2.2rem;
        border-radius: 1.1rem;
        margin: .5rem 0;
        border: none;
    }

    p{
        color: #a21300;
        font-size:.9rem;
        margin-left: 1rem;
    }

    transition: all .4s;

    .selected{
        border: 1px solid #444;
        transition: all .4s;
    }

`;

export const PillLevel = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0 .5rem;
    height: 1.4rem;
    border-radius: .7rem;
    font-size: .8rem;
    background: #fff;
    color: #444;
    margin: 0.2rem;
    border: 1px solid #fff;
    cursor: pointer;

    &:hover{
        filter:brightness(.9);
    }


`;

export const PillContainer = styled.div`

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

`;