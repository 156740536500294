import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;

`;

export const Menu = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    @media (max-width: 720px){
        flex-direction: column;
    }

    input{
            display: flex;
            width:100%;
            height: 2rem;
            border-radius: 1rem;
            border: none;
            margin-right: 1rem;
            padding-left: 1rem;
            
    }

    .valueStudent{
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0;
        margin: .2rem;
        @media (max-width: 720px){
                margin-bottom: 1rem;
            }

        p{
            position: absolute;
            left: 1rem;
            top: 25%;
            font-size: .9rem;

            @media (max-width: 720px){
                top: 20%;
            }
        }

        input{
            padding-left: 3rem;
        }
    }

`;

export const NewStudent = styled.div`

`;

export const Students = styled.div`

    margin-top: 1rem;

    .inactiveUser{
        background: #ccc;
        /* filter: brightness(.7); */
        
    }

`;

export const Pill = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    min-height: 2rem;
    width: fit-content;
    border-radius: 1rem;
    border: 2px solid var(--purple);
    color: var(--purple);
    margin-bottom: .2rem;
    transition: .3s;
    font-weight: bold;

    @media (max-width: 720px){
        width: 100%;
    }

    &:hover{
        background: var(--purple);
        color: #fff;
        transition: .3s;
    }

    img{
        width: .8rem;
        z-index: 10;
        transition: .3s;
        margin:0 .3rem ;
        &:hover{
            filter:brightness(.7);
            transition: .3s;
        }
    }

    svg{
        z-index: 10;
        margin:0 .3rem ;
        
    }

`;

export const PillUser = styled.div`

    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    min-height: 2rem;
    border-radius: 1rem;
    border: 1px solid #ccc;
    color: #444;
    margin-bottom: .2rem;
    transition: .3s;
    /* font-weight: bold; */
    margin-top: .5rem;
    padding: 1rem;

    @media (max-width: 720px){
            flex-direction: column;
            
        }

    span{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &+span{
            width: 30%;
            @media (max-width: 720px){
            width: 100%;
        }
        }

        @media (max-width: 720px){
            margin-top: 1rem;
            width: 100%;
        }

        .name{
            min-width: 10rem;
        }

        

        div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg{
                cursor: pointer;
                width: 1rem;
                height: 1rem;
                display: inline;
                margin: 0;
                padding: 0;

                &:hover{
                    filter: brightness(.8);
                }
            }
        }
        
    }

`;