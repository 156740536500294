import { Route, Routes, BrowserRouter } from 'react-router-dom'

import { ClassesTable } from "../components/ClassesTable";
import { ContentTable } from "../components/ContentTable";
import { Feed } from "../components/Feed";
import { LoadingScreen } from "../components/LoadingScreen";
import { WatchClass } from "../components/WatchClass";

import { AskClassModal } from '../components/AskClassModal';
import Login from '../components/Login';
import Private from './private';

import Logout from '../components/Logout';
import { MainVideoModal } from '../components/MainVideoModal';
import { AddContentModal } from '../components/AddContentModal';
import { MediasModal } from '../components/MediasModal';
import { UserModal } from '../components/UserModal';
import { Site } from '../components/Site';
import { Purchase } from '../components/Purchase';
import { Payments } from '../components/Payments';
import { StudentConfigModal } from '../components/StudentConfigModal';
import { TutorialVideoModal } from '../components/TutorialVideoModal';
import { AskStudentNewClassModal } from '../components/AskStudentNewClassModal';


const RoutesPage = () => {  

    

    return(
        <BrowserRouter > 

            <AskClassModal />
            <AskStudentNewClassModal />
            <MainVideoModal />
            <AddContentModal />
            <MediasModal />
            <UserModal />
            <StudentConfigModal />
            <TutorialVideoModal />
            
            <Routes>
                <Route element ={<Private />}>
                    <Route element = { <Feed /> }   path="/feed" />
                    <Route element = { <ContentTable /> }  path="/content" />
                    <Route element = { <LoadingScreen /> }  path="/loading" />
                    <Route element = { <WatchClass /> }  path="/watch" />
                    <Route element = { <ClassesTable /> }  path="/classes" />
                    <Route element = { <Payments /> }  path="/payments" />
                    
                    
                </Route>
                <Route element = { <Site /> }   index />
                <Route element = { <Login /> }  path="/login" />
                <Route element = { <Logout /> }  path="/logout" />
                <Route element = { <Purchase /> }  path="/purchase" />
                
            </Routes>
            

            
            
        </BrowserRouter>
    )
 }

 export default RoutesPage;