import { ChangePassword } from "../ChangePassword";
import { Help } from "../UserModal/Help";
import { Container } from "./styles";
import closeImg from '../../assets/images/close.svg'
import { useModals } from '../../hooks/useModalsContext'
import Modal from 'react-modal'

export function StudentConfigModal(){

    const { isStudentConfigModalOpen, handleCloseStudentConfigModal } = useModals()


    return(

        <Modal 
          isOpen={isStudentConfigModalOpen}
          onRequestClose={handleCloseStudentConfigModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content full-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseStudentConfigModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

        <Container>
            <h3 className="bottomMargin">Alterar senha</h3>
            <ChangePassword />
            <h3 className="topMargin">Ajuda</h3>
            <Help />
        </Container>

        </Modal>
    )
}