import { Outlet } from 'react-router-dom';

// import { Header } from "../../components/Header";
import Cookies from 'js-cookie';
import RedirectToLogin from '../../components/RedirectToLogin';
import { ButtonBar } from '../../components/ButtonBar';
import { Header } from '../../components/Header';

const Private = ({ children }: any) => {


    if(!Cookies.get('token_classhelper')) return  <RedirectToLogin />
    return (
      <>
        <Header />
          {children?children:<Outlet/>}
        <ButtonBar />
      </>
    )
 }

 export default Private;
