import { Bar, Container, BarLink, BarLinkSimple } from './styles'

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import imgFeed from '../../assets/images/feed.svg'
import imgContent from '../../assets/images/content.svg'
import imgClass from '../../assets/images/class.svg'
import imgPost from '../../assets/images/post.svg'
import imgPlay from '../../assets/images/play.svg'
import imgMoney from '../../assets/images/money.svg'

import  contentTitleImg from '../../assets/images/content-title.svg'
import  feedTitleImg from '../../assets/images/feed-title.svg'
import  playTitleImg from '../../assets/images/play-title.svg'
import  classTitleImg from '../../assets/images/class-title.svg'

import { useInfoUtils } from '../../hooks/useInfoUtilsContext'
import { useModals } from '../../hooks/useModalsContext';
import { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { userInfo } from 'os';
import { useNavigate } from 'react-router-dom';




export function ButtonBar(){

    const { handleOpenAskClassModal } = useModals()
    const { infoUtils, setInfoUtils } = useInfoUtils();
    const [ userRole, setUserRole] = useState(0)
    const [ classLink, setClassLink] = useState('')
    const navigate = useNavigate();

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")
        setUserRole(data.userRole)
        setClassLink(data.classLink)
    }

    useEffect(()=>{
        getUserInfo()
    },[])

    async function teachClass(){

        const { data } = await api.get("/getuserinfo")
        
        if(data.classLink !==null && data.classLink !==''){
            window.open(data.classLink, '_blank')
        }else{
            handleOpenAskClassModal()
        }
    }

    async function watchClassFunc(){

        const { data } = await api.get("/getuserinfo")
        
        if(data.classLink !==null && data.classLink !==''){
            window.open(data.classLink, '_blank')
        }else{
            // setInfoUtils({
            //     ...infoUtils,
            //     screen:'Assistir aula'
            //     })
            
            // navigate('/loading')
            window.open(`https://meet.jit.si/classhelper-123e-4266554-${data.userClasses[0]}-4567-e89b-12d3-a456-40000#jitsi_meet_external_api_id=0&config.startWithAudioMuted=true&config.prejoinPageEnabled=false&config.disableInviteFunctions=true&config.hideMoreActionsButton=true&config.subject=%22Tempo%20de%20aula%22&config.toolbarButtons=%5B%22microphone%22%2C%22camera%22%2C%22chat%22%2C%22participants-pane%22%2C%22tileview%22%2C%22desktop%22%2C%22recording%22%2C%22fullscreen%22%2C%22shareaudio%22%2C%22sharedvideo%22%2C%22__end%22%5D&interfaceConfig.DISABLE_JOIN_LEAVE_NOTIFICATIONS=true&interfaceConfig.SHOW_CHROME_EXTENSION_BANNER=false&interfaceConfig.SETTINGS_SECTIONS=%5B%22devices%22%2C%22language%22%2C%22moderator%22%5D&interfaceConfig.CLOSE_PAGE_GUEST_HINT=true&interfaceConfig.HIDE_INVITE_MORE_HEADER=true&appData.localStorageContent=null`, '_blank')
        }
    }    

    // useEffect(()=>{
    //     getUserInfo()
    //     console.log('oioioi')
    // },[infoUtils])

    return(
        <Container>

            {userRole===1
                ?
                <Bar>
                    <BarLink 
                        to="/feed"
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Feed'
                            })}
                        >
                        <span><img src={imgFeed} alt="" /></span>
                        <p>Feed</p>
                    </BarLink>
                    <BarLink 
                        to="/content" 
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Conteúdo'
                            })}
                            >
                        <span><img src={imgContent} alt="" /></span>
                        <p>Conteúdo</p>
                    </BarLink>
                    <BarLink 
                        to="/classes" 
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Turmas'
                            })}
                            >
                        <span><img src={imgClass} style={{marginTop:5}} alt="" /></span>
                        <p>Turmas</p>
                    </BarLink>
                    {/* <BarLink 
                        to="/post"
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Postar'
                            })}
                        >
                        <span><img src={imgPost} alt="" /></span>
                        <p>Postar</p>
                    </BarLink> */}
                    {/*@ts-ignore*/}
                    <BarLinkSimple  onClick={teachClass}>
                        <span><img src={imgPlay} alt="" /></span>
                        <p>Dar aula</p>
                    </BarLinkSimple>
                    <BarLink 
                        to="/payments"
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Pagamentos'
                            })}
                        >
                        <span><img src={imgMoney} alt="" /></span>
                        <p>Pagamentos</p>
                    </BarLink>
                </Bar>
                :
                <Bar>
                    <BarLink 
                        to="/feed"
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Feed'
                            })}
                        >
                        <span><img src={imgFeed} alt="" /></span>
                        <p>Feed</p>
                    </BarLink>
                    {/*@ts-ignore*/}
                    <BarLinkSimple 
                        onClick={watchClassFunc}
                        >
                        <span><img src={imgPlay} alt="" /></span>
                        <p>Assitir aula</p>
                    </BarLinkSimple>
                    <BarLink 
                        to="/content"
                        onClick={()=>setInfoUtils({
                            ...infoUtils,
                            screen:'Conteúdo'
                            })}
                        >
                        <span><img src={imgContent} alt="" /></span>
                        <p>Conteúdo</p>
                    </BarLink>
                </Bar>
            }
        </Container>
    )
}