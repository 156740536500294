import {FaFacebook, FaInstagram, FaYoutube} from 'react-icons/fa'
import { Container } from './styles'


export function SocialItems(props:any){


    return(
        //@ts-ignore
        <Container bigScreen={props.bigScreen}>
                {/* <a href="https://www.facebook.com/Class-Helper-110215808175605">
                    <FaFacebook />        
                </a> */}
                <a target={'_blank'} rel={'noreferrer'} href="https://www.instagram.com/class.helper/">
                    <FaInstagram />
                </a>
                <a target={'_blank'} rel={'noreferrer'} href="https://www.youtube.com/channel/UCGIvM_Sw6Pd9Bi1Sz_i5_zA">
                    <FaYoutube />
                </a>
        </Container>
    )
}