import styled from 'styled-components'

export const Container = styled.div`

    background: #FFF;
    position: relative;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`

export const ScrollButtonDiv = styled.div`

    align-items: center;
    justify-content: center;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background: var(--purple);
    cursor: pointer;
    
    svg{
        padding: 0;
        margin: 0;
        color: #fff;
    }
`