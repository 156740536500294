import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;

    p{
        color: var(--text-body);
        font-size: .9rem;
    }

    select{
        display: inline-block;
        height: 3rem;
        border-radius: .25rem;
        border: 0;
        margin: 1rem 0;
        padding-left: 1rem;
        font-size: 1.2rem;
        color: var(--text-body);
    }

    button{
        height: 3rem;
        color: #FFF;
        font-size: 1.2rem;
        background: var(--purple);
        border-radius: .25rem;
        border: 0;
        margin: 1rem 0;
    }
`;