import React from 'react'
import { useModals } from "../../hooks/useModalsContext"
import { Container } from "./styles"


// interface DataModaltProps {
//     level:{
//         name:string;
//         id:number;
//     };
//     subject:{
//         name:string;
//         id:number;
//     };
// }

interface ButtonProps {
    isContent?:boolean,
    isClass?:boolean,
    data?: any,
}

export function AddButton({isContent, isClass, data}:ButtonProps){

    const { handleOpenAddContentModal,handleSetData, handleOpenAddClassModal } = useModals()

    function handleClick(){
        if(isContent){
            handleOpenAddContentModal()
            handleSetData(data)
        }

        if(isClass){
            handleOpenAddClassModal()
            // handleSetData(data)
        }

        
    }

    return(
        //@ts-ignore
        <Container onClick={handleClick}>
            <h2>+</h2>
        </Container>
    )


}