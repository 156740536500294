import styled from 'styled-components'
import { isPropertySignature } from 'typescript';

interface ContainerProps {
    active: boolean
}

export const Container = styled.div<ContainerProps>`

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg{
        margin:0;
        padding:0;
        height: 1.5rem;
        width: 1.5rem;
        display: inline;
        cursor: pointer;
        transition: filter 0.2s;
        &:hover{
            filter: brightness(0.8);
        }
    }

    p,strong{
        display: inline;
        color: var(--text-body);
        margin-top: 0;
        text-align: center;
    }

    .containers{
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        svg{
            margin-right: 1rem;
        }
    }


    padding: 1rem;
    border: 0;
    background: ${(props) => props.active ? 'var(--shape)' : '#f2f2f2'};
    color: var(--text-body);


    .greyImage{
        filter: brightness(.8);
        &:hover{
        filter: brightness(0.6);
    }
    }



    
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    
`;