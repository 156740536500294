import styled from "styled-components";

export const Container = styled.div`

.dontShow{
    color: #fff;;
    background: var(--purple);
    height: 2rem;
    border-radius: 1rem;
    border: none;
    padding: .5rem;
    font-weight: bold;
}


`;
