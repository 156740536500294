import { useState } from 'react'
import Modal from 'react-modal'

import closeImg from '../../assets/images/close.svg'
import { useModals } from '../../hooks/useModalsContext'
import { ActiveChat } from './ActiveChat'
import { ChatContacts } from './ChatContacts'
import { ActiveChatDiv, BoxChat, ContactsDiv, Container } from './styles'

interface ActiveChatData{
    userId: number
    userName: string
    userPhoto: string
    className: string

}



export function ChatModal(){

    const [ activeChatData, setActiveChatData ] = useState<ActiveChatData>({} as ActiveChatData)
    const [ refreshContacList, setRefreshContactList ] = useState(false)
    const { isChatModalOpen, handleCloseChatModal } = useModals()

    async function handleChangeChatData(data: ActiveChatData) {

        setActiveChatData(data)
        
    }

    function handleSetRefreshContactList(){
        setRefreshContactList(!refreshContacList)
    }

    return(
        <Modal 
          isOpen={isChatModalOpen}
          onRequestClose={handleCloseChatModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
          appElement={document.getElementById('root') || undefined}
      >
          <button 
            type="button" 
            onClick={handleCloseChatModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
          </button>

          <Container>
              <BoxChat>
                  <ContactsDiv>
                      <ChatContacts refreshContacList={refreshContacList} handleChangeChatData={handleChangeChatData}/>                      
                  </ContactsDiv>
                  <ActiveChatDiv>
                        <ActiveChat activeChat={activeChatData} setRefreshContactList={handleSetRefreshContactList} />
                  </ActiveChatDiv>
                  
              </BoxChat>
          </Container>


        </Modal>
    )
}