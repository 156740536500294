import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    padding: 1rem;

    .tutorial{
        height: 2rem;
        border-radius: 1rem;
        border: 1px solid #666;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .5rem;
        margin-bottom: .5rem;
        transition: all .5s;
        
        &:hover{

            border: 2px solid var(--purple);
            transition: all .5s;

        }
    }

    .sentMessage{
        margin-top: 2rem;
        color: #666;
        text-align: center;

    }

    .sendAnother{

        margin-top: 2rem;
        font-size: .8rem;
        color: #666;
        text-align: center;
        cursor: pointer;

    }

    .infoText{
        font-size: .8rem;
        margin: 1rem 0;
        color: #666;
    }

    select{
        background: #fff;
        border-radius: .5rem;
        color: #666;
    }

    textarea{
        background: #fff;
        border-radius: .5rem;
        border: none;
        padding: 1rem;
        color: #666;
    }


`