import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 120px;

    .smallLine{

        @media (min-width: 720px){
            width: 75%;
            border-radius: 0.25rem;
        }
        @media (min-width: 1080px){
            width: 55%;
            border-radius: 0.25rem;
        }
        
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: right;
    }

    .classSelector{
        border: none;
        background-color: transparent;
        margin-right: 1rem;
        font-size: 10px;
        color: #888;
        font-weight: bold;
        margin-bottom: 1rem;
    }
  
    .middleText{
        color: var(--text-body);
        margin-top: 10%;
        text-align: center;
    }

    .button{
        /* width: 2.5rem; */
        padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--purple);
        color: #FFF;
        /* height: 2.5rem; */
        border-radius: .5rem;
        border: none;
        margin-top: 10px;
        cursor: pointer;
    }
`;