import styled from "styled-components";


export const Container = styled.div`

    display: flex;
    flex-direction: column;
    padding: 1rem;

    p{
        color: #888;
        font-size: .8rem;
        margin-bottom: .5rem;
    }

    li{
        margin-left: 1.2rem;
    }
    ul{
        list-style-type: square;
        color: #888;
    }

    .planButons{
        width: 100%;
        display: flex;
        justify-content: space-around;

        button{
            width: 49%;           
            height: 1.6rem;
            border-radius: 0.8rem;

        }

        .selectedPlan{
            }

        .unselectedPlan{
            background: #888;
            opacity: .5;
        }
    }

`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    

    @media (max-width: 720px){
        flex-direction: column;
        }

`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 15rem;
    align-items: center;
    justify-content: space-between;
    margin: .4rem;
    padding: .4rem;
    padding-top: 1rem;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 3px rgba(0,0,0,.1);
    transition: all .5s;
    background: #8D72E1;

    div{
        height: 10rem;
        margin-top: 1rem;
    }

    h5,h4,h3,p, strong{
        color: #eee;
    }

    hr{
        border: 1px solid rgba(255,255,255,.5);
        width: 30%;
        margin-top: 1rem;
    }

    &:hover{
        box-shadow: 3px 3px 5px rgba(0,0,0,.3);
        transition: all .5s;
    }

    &:first-child{
        /* margin-right: 0; */
        background: #9F73AB;
        
    }
    &:last-child{
        /* margin-right: 0; */
        background: #7978FF;
    }

    &:hover{
            /* background: var(--purple); */
            scale: 1.2;
    }


`;

export const PaymentBox = styled.div`

    display: flex;
    flex-direction: column;

    .cols{
        display: flex;

        @media (max-width: 720px){
        flex-direction: column;
        }
    }

    .cardInfo{

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-height: 10rem;
        width: 50%;

        input, select{
            background: #FFF;
            font-size: .9rem;
            height: 2rem;
            border-radius: 1rem;
            border: none;
            margin: .5rem;
            color: #888;
            padding-left: 1rem;
        }
        select{
            padding: 0 .5rem;
        }

        .fullCols{
        display: flex;
        width: 95%;
        flex-direction: column;
            input{
                width: 100%;
            }

        }

        .halfCols{
            display: flex;
            width: 100%;
            input{
                width: 50%;
            }

        }

        @media (max-width: 720px){
            width: 100%;
        }

    }

    .cardPreview{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 10rem;
        width: 50%;

        @media (max-width: 720px){
            width: 100%;
        }
    }

    .resume{
        display: flex;
        flex-direction: row;

        div{
            flex-direction: column;
            width: 50%;

            .reverse{
                background: #ECECEC;
                color: var(--purple);

                &:hover{
                    background: var(--purple) ;
                    color: #ECECEC;
                }
            }

            .mainColor{
                background: var(--purple) ;
                color: #ECECEC;

                &:hover{
                    background: #ECECEC;
                    color: var(--purple);
                }

            }
            button{
                border: 2px solid var(--purple);
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                height: 2rem;
            }
        }


    }

`;

export const MiniCard = styled.div`

    display: flex;
    position: relative;
    width: 80%;
    height: 10rem;
    border-radius: 1rem;
    background: rgb(28,0,50);
    background: linear-gradient(245deg, rgba(28,0,50,1) 0%, rgba(58,0,94,1) 100%);

    p{
        color: #ccc;
        font-size: .9rem;
        @media (max-width: 720px){
            font-size: 1rem;
        }
    }

    .flag{
        width: 2rem;
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    .chip{
        width: 2rem;
        position: absolute;
        bottom: 2rem;
        right: 2rem;
    }

    .nameLabel{
        font-size: .6rem;
        position: absolute;
        left: 1rem;
        top: 2rem;

        @media (max-width: 720px){
            top: 2.5rem;
        }

    }
    .nameValue{
        font-weight: bold;
        position: absolute;
        left: 1rem;
        top: 2.7rem;

        @media (max-width: 720px){
            top: 3.2rem;
        }

    }

    .numberLabel{
        font-size: .6rem;
        position: absolute;
        left: 1rem;
        top: 4.5rem;

        @media (max-width: 720px){
            top: 5.5rem;
        }

    }
    .numberValue{
        font-weight: bold;
        position: absolute;
        left: 1rem;
        top: 5.2rem;

        @media (max-width: 720px){
            top: 6.2rem;
        }

    }

    .expLabel{
        font-size: .6rem;
        position: absolute;
        left: 1rem;
        top: 7rem;

        @media (max-width: 720px){
            top: 8.5rem;
        }

    }
    .expValue{
        font-weight: bold;
        position: absolute;
        left: 1rem;
        top: 7.7rem;

        @media (max-width: 720px){
            top: 9.2rem;
        }

    }

    .expValue2{
        font-weight: bold;
        position: absolute;
        left: 2.2rem;
        top: 7.7rem;

        @media (max-width: 720px){
            top: 9.2rem;
        }

    }


    .cvvLabel{
        font-size: .6rem;
        position: absolute;
        left: 6rem;
        top: 7rem;

        @media (max-width: 720px){
            top: 8.5rem;
        }

    }
    .cvvValue{
        font-weight: bold;
        position: absolute;
        left: 6rem;
        top: 7.7rem;

        @media (max-width: 720px){
            top: 9.2rem;
        }

    }

    @media (max-width: 720px){
            margin-top: 1rem;
            width: 100%;
            height: 12rem;
        }
    

`;