import axios from "axios";
import Cookies from 'js-cookie';

export const api = axios.create({
    // baseURL: 'http://192.168.0.106:3001/'
    baseURL: 'https://haode.click:3001'
})


// ADICIONA O TOKEN A REQUISICAO
api.interceptors.request.use((config) => {

    const token = `Bearer ${Cookies.get('token_classhelper')}`;
    if(config.headers) config.headers.Authorization = token;

    return config;
});
