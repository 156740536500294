import styled from "styled-components";
import { Link } from "react-router-dom";


export const Container = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const Bar = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 60px;
    background: var(--purple);
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100;

    @media (min-width: 720px){
        border-radius: 30px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        width: 50%;
        bottom: 1.5rem;

    }

`;

export const BarLink = styled(Link)`
    text-align: center;
    text-decoration: none;
    
    span{
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;

        img{
        max-width: 1.8rem;
        max-height: 1.7rem;
        cursor: pointer;
        transition: transform 0.2s;

        &:hover{
            transform: scale(1.1);
        }
    }
    }     


    p{
        font-size: 0.6rem;
        text-align: center;
        color: #FFF;   
        
    }

    
`

export const BarLinkSimple = styled.div`
    text-align: center;
    text-decoration: none;
    
    span{
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;

        img{
        max-width: 1.8rem;
        max-height: 1.7rem;
        cursor: pointer;
        transition: transform 0.2s;

        &:hover{
            transform: scale(1.1);
        }
    }
    }     


    p{
        font-size: 0.6rem;
        text-align: center;
        color: #FFF;   
        
    }

    
`