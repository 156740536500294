import styled from 'styled-components'


export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-top:4rem;
    padding: 0 1rem;

    @media (max-width: 720px){
                width: 100%;
                margin: 2rem 0;
            }
   
`;

export const MobileMenu = styled.div`

    cursor: pointer;

    @media (min-width: 720px){
                display: none;
            }

    font-size: 2rem;
    margin-left: 2rem;

    svg{
        color: var(--purple); 
        margin: 0;
    }

`

export const MenuItems = styled.div`

    @media (max-width: 720px){
                display: none;
            }


    width: 100%;

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    a:link{
        color: #777;
        font-size: .9rem;
        font-weight: bold;
    }

    a:hover{
        filter: brightness(.7);
    }

    a:visited{
        color: #777;
    }


    
`;



export const NavItem = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;



`;

export const Access = styled.div`

    @media (max-width: 1024px){
                margin-left: 2rem;
            }
    @media (max-width: 720px){
        display: none;
    }

    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 2rem;

    transition: .6s;

    
    font-weight: bold;

    border: 2px solid rgba(151, 68, 180,.5);
    border-radius: 2rem;
    background: transparent;

    a:link, a:visited{
        color: var(--purple);
    }
    a:hover{
        color: #fff;
        transition: .6s;
    }


    &:hover{
        background: var(--purple);
        border: 2px solid var(--purple);
        p{
            color: #fff;
        }
        transition: .6s;
    }

    .fullButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`;

export const Logo = styled.div`

@media (max-width: 720px){
                /* scale: .8; */
                width: 100%;
            }
  
  scale: .8;
  width: 80%;

  /* display:flex;
  flex-direction:row;
  align-items:center;
  align-self:center; */
  background-color:#fff;
  /* position: absolute; */
  /* border: solid; */
  /* border-width:4px; */
  border-color:var(--background);

  h1{
        
        color: var(--purple);
        font-weight: 400;

        display: inline-block;
        /* animation: changecolorfont;
        animation-duration: 2s;

        @keyframes changecolorfont {
            from {
                    color: #ececec;
                }
            to {
                    color: var(--purple);
                }
        } */

        em{
            display: inline-block;
            padding: .5rem;
            background: var(--purple);
            color: #fff;
            font-style: normal;

            /* animation: changecolor;
            animation-duration: 2s;

            @keyframes changecolor {
                from {
                        background-color: #ececec;
                         color: var(--purple); 
                    }
                to {
                        background-color: var(--purple);
                         color: #ececec; 
                    }
            } */

        }
    }

    

`;

