import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useInfoUtils } from '../../hooks/useInfoUtilsContext'
import { api } from '../../services/api'
import { Container } from './styles'


import closeImg from '../../assets/images/close.svg'
import  playTitleImg from '../../assets/images/play-title.svg'
import { useModals } from '../../hooks/useModalsContext'

interface Class{
    id: number
    name: string
    subject: string
}


export function AskClassModal(){

    const [ selectedClass, setSelectedClass ] = useState(0)

    const [ classes, setClasses ] = useState<Class[]>([])

    const { infoUtils, setInfoUtils } = useInfoUtils()
    const { isAskClassModalOpen, handleCloseAskClassModal } = useModals()

    const navigate = useNavigate();

    async function getClasses(){
        const { data } = await api.get('/getclassesteacher')
        setClasses(data)
        setSelectedClass(data[0]?.id)
    }

    useEffect(()=>{
        getClasses()
    },[isAskClassModalOpen])

    function handleSelectClass(){

        

        // let newClass = ''
        // classes.map(item=>item.id===selectedClass?newClass=item.name:'')

        // setInfoUtils({
        //     ...infoUtils,
        //     selectedClassId:selectedClass,
        //     screen: `Dar Aula ${newClass}`
        // })

        // handleCloseAskClassModal()

        // navigate('/loading');

        window.open(`https://meet.jit.si/classhelper-123e-4266554-${selectedClass}-4567-e89b-12d3-a456-40000#jitsi_meet_external_api_id=0&config.startWithAudioMuted=true&config.prejoinPageEnabled=false&config.disableInviteFunctions=true&config.hideMoreActionsButton=true&config.subject=%22Tempo%20de%20aula%22&config.toolbarButtons=%5B%22microphone%22%2C%22camera%22%2C%22chat%22%2C%22participants-pane%22%2C%22tileview%22%2C%22desktop%22%2C%22recording%22%2C%22fullscreen%22%2C%22shareaudio%22%2C%22sharedvideo%22%2C%22__end%22%5D&interfaceConfig.DISABLE_JOIN_LEAVE_NOTIFICATIONS=true&interfaceConfig.SHOW_CHROME_EXTENSION_BANNER=false&interfaceConfig.SETTINGS_SECTIONS=%5B%22devices%22%2C%22language%22%2C%22moderator%22%5D&interfaceConfig.CLOSE_PAGE_GUEST_HINT=true&interfaceConfig.HIDE_INVITE_MORE_HEADER=true&appData.localStorageContent=null`, '_blank')

        

        // if(data.userRole===2){
        //     setRoomName(`classhelper-123e-${data.userClasses[0]}-4567-e89b-12d3-a456-4266554-${teacherId}-40000`)
        // }else{
        //     setRoomName(`classhelper-123e-${infoUtils.selectedClassId}-4567-e89b-12d3-a456-4266554-${teacherId}-40000`)
        // }


    }

    return(
        <Modal 
          isOpen={isAskClassModalOpen}
          onRequestClose={handleCloseAskClassModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content-small percent90"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseAskClassModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

            {
                classes.length>0
                ?<>
                    <h3>Selecione a turma</h3>
                    <select name="classes" id="classes" autoFocus onBlur={(e)=>setSelectedClass(Number(e.target.value))}>
                        { classes.map(item=><option key={item.id} value={item.id}>{item.name}</option>)} 
                    </select>
                    <button onClick={handleSelectClass}>Dar aula</button>
                </>
                :<p>Não há nenhuma turma cadastrada</p>
            }
          
          </Container>

        </Modal>
    )
}