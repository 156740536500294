import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
`;

export const ChatHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 1rem;
    
    img{
        width: 3rem;
        height: 3rem;
        border-radius: 1.5rem;
        margin-right: 1rem;
    }
`;

export const ChatHistory = styled.div`
    height: 50vh;
    background: #FFF;
    border-radius: .5rem;
    margin: 1rem;
    padding: 1rem;

    hr{
        padding-left: 5rem;
        margin: 1rem 0;
        opacity: 0.2;
    }
    
    strong{
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: .9rem;
    }

    p{
        color: var(--text-body);
        font-size: .9rem;
        padding-right: 1.5rem;
    }
    
    small{
        font-size: .6rem;
    }

    .centeredMessage{
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const WhoWhenInfo = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;

`;

export const ChatType = styled.div`
    form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 1rem;
    


    input{
        width: 70%;
        padding: .5rem;
        color: var(--text-body);
        height: 3rem;
        background: #FFF;
        border: 0;       
        font-weight: 400;
        font-size: .9rem;
        word-break: break-word;

        &::placeholder{
            color: var(--text-body);
        }
    }

    button{
            font-size: 1rem;
            color: #FFF;
            background: var(--purple);
            border: 0;
            padding: 0 2rem;
            border-radius: 0.25rem;
            height: 3rem;

            transition: filter 0.2s;

            &:hover{
                filter: brightness(0.9)
            }
        }
    }

`;