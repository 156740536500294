import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    padding: 1rem;

    input{
        width: 100%;
        height: 2rem;
        border-radius: 1rem;
        border: none;
        padding-left: 1rem;
        color: #888;
    }

    .spaceAfterTitle{
        margin-bottom: 1rem;
    }

    div{
        margin-bottom: .2rem;
        padding: .2rem;
    }

    p{
        color: #888;
        font-weight: bold;
        font-size: .8rem;
        margin-bottom: .3rem;
        margin-left: .2rem;
    }

    .showCPFMessage{
        display: inline;
        color: #AD1300;
        font-size: .7rem;
    }

    .hideCPFMessage{
        display: none;
    }

    .informationBlock{
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .fullSize{
        width: 100%;

    }

    .halfSize{
        width: 50%;
        
    }

    .aQuarterSize{
        width: 25%;
    }

    .threeQuarterSize{
        width: 75%;
    }

`;