import styled from "styled-components";

export const Container = styled.div`

    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #FFF;

    @media (min-width: 720px){
        width: 75%;
        border-radius: 0.25rem;
    }
    @media (min-width: 1080px){
        width: 55%;
        border-radius: 0.25rem;
    }

    hr{
        margin-left: 4rem;
        opacity: 0.4;
    }
    
`;

export const PostDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 15px;
    position: relative;
    p{
        font-size: 0.8rem;
        color: var(--text-body);
    }

    .deleteIcon{
        cursor: pointer;
        width: 1rem;
        margin-left: 2rem;
        /* position: absolute;
        right: 1rem;
        top: 1rem; */
        border-radius: 0;
        box-shadow: none;
        &:hover{
            filter: brightness(.7);
        }
        z-index: 2;
    }
`;

export const PostContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5px 15px;
    position: relative;

    p{
        color: var(--text-body);
        margin-bottom: 1rem;
    }

    img{
        width: 100%;
        border-radius: 1rem;
        box-shadow: 3px 3px 1px #ccc;
        -webkit-box-shadow: 3px 3px 1px #ccc;
        -moz-box-shadow: 3px 3px 1px #ccc;
    }

    
`;

export const PostActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    margin-top: 2rem;

    img{
        width: 1.5rem;
        cursor: pointer;
        &:hover{
            filter: brightness(0.9)
        }
    }

    input{
        width: 70%;
        padding: 0 1.5rem;
        height: 2rem;
        border-radius: 0.25rem;

        color: var(--text-body);

        border: 0;
        background: #F8F8F8;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
    }

    button{
        font-size: 1rem;
        color: #FFF;
        background: var(--purple);
        border: 0;
        padding: 0 2rem;
        border-radius: 0.25rem;
        height: 2rem;

        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9)
        }
    }
    
`;

export const PostComments = styled.div`
    width: 100%;
    border-radius: 0.25rem;
    margin-top: 2rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* background-color: var(--background); */

    p{
        font-size: 0.8rem;
        color: var(--text-body);
    }

    strong{
        font-size: 1rem;
        color: var(--text-body);
        font-weight: 600;
        margin-bottom: 1rem;
    }

    ul li {
        list-style-type: none;
        display: inline;
        }

    /* ul li {
        list-style-type: none;
        display: inline;
        }

    .comment-area {
        display: grid; 
        grid-template-columns: 1fr 5fr; 
        grid-template-rows: 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
            ". text"; 
        margin: 1rem;

        img{
            width: 3rem;
            height: 3rem;
            border-radius: 1.5rem;
        }

        
    }
    .text {
        display: grid; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
            "."
            "."; 
        grid-area: text; 
        margin-right: 2rem;

        strong{
            font-size: 0.8rem;
            color: var(--text-body);
            font-weight: 600;
        }
    } */

`;