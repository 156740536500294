import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';

const Logout = () => {

    const navigate = useNavigate();

    

    Cookies.remove('token_classhelper');
    
    localStorage.clear();
            
    navigate('/login');

    return(
        <>
        </>
    )
    
 }

 export default Logout;