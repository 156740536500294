import { useEffect, useState } from "react"
import { api } from "../../../services/api"
import { Container, ProgressBar } from "./styles"

export function StorageUse(){

    const [used, setUsed] = useState({} as any)
    const [percentual, setPercentual] = useState(0)
    const [totalStorage, setTotalStorage] = useState(250)


    async function getStorageUse(){

        const { data } = await api.get('/getuserstorageuse')
        setUsed(data)
    }

    useEffect(()=>{

        const tempPercentual = used.total*100/totalStorage

        setPercentual(tempPercentual)

    },[used,totalStorage])


    useEffect(()=>{
        getStorageUse()
    },[])




    return(
        <Container>
            <p className="smallText">Uso de armazenamento: {percentual?.toFixed(2)}% : {used?.total?.toFixed(2)} MB de {totalStorage} MB</p>
            <ProgressBar>
                <div className="used" style={{width:`${percentual}%`}}></div>
                
            </ProgressBar>
        </Container>
    )
}