import styled from "styled-components";

export const Container = styled.div`

    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;

`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;

    h1{
        color: var(--purple);
        font-weight: 400;
        font-size: 16px;

        display: inline-block;
        
        em{
            display: inline-block;
            padding: .3rem;
            background: var(--purple);
            color: #FFF;
            font-style: normal;

        }
    }
    
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h2{
        font-size: 28px;
        font-weight: 400;
        color: var(--text-body);
    }

    img{
        width: 1.5rem;
        margin-right: 1rem;
    }
    
`;

export const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    img{
        width: 1.5rem;
        margin-right: 1rem;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9);
        }
    }
`;
