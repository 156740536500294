import { ChatModal } from './components/ChatModal';
import { InfoUtilsProvider } from './hooks/useInfoUtilsContext';
import { GlobalStyle } from "./styles/global";
import RoutesPage from './routes'
import { Header } from './components/Header';
import { ModalsProvider } from './hooks/useModalsContext';


function App() {

    return (
    <div className="App">
      <InfoUtilsProvider>
        <ModalsProvider>
          <ChatModal />
          <RoutesPage />
        </ModalsProvider>
      </ InfoUtilsProvider>
      
      <GlobalStyle />

      
    </div>
  );
}

export default App;
