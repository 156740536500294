import { useEffect, useState } from "react"
import { api } from "../../../services/api"
import { Container, CardsContainer, Card, PaymentBox, MiniCard } from "./styles"
import ccDefaultImg from '../../../assets/images/cc-default.svg'
import ccMasterCardImg from '../../../assets/images/cc-mastercard.svg'
import ccVisaImg from '../../../assets/images/cc-visa.svg'
import ccChipImg from '../../../assets/images/cc-chip.svg'
// import { Container, ProgressBar } from "./styles"
// import {add, compareDesc, differenceInDays} from 'date-fns'
import { useNavigate } from 'react-router-dom';

import { ImCancelCircle, ImSpinner11, ImCheckmark, ImHourGlass } from 'react-icons/im'
import { useInfoUtils } from "../../../hooks/useInfoUtilsContext"

import { getYear } from 'date-fns'

export function PlanInfo(){

    const defaultCC = {
        name:"",
        number:"",
        installments:"1",
        cvv:""
    }

    const defaultMessages = {
        "none":{
            code:'none',
            title:'',
            message:"",
            action:""
        },
        "approved":{
            code:'approved',
            title:'Pagamento aprovado!',
            message:"Você pode continuar usando o sistema normalmente",
            action:""
        },
        "refused":{
            code:'refused',
            title:'Pagamento recusado!',
            message:"Verifique se digitou corretamente os dados do cartão ou entre em contato com a operadora do cartão",
            action:"Tentar novamente"
        },
        "pending":{
            code:'pending',
            title:'Pagamento pendente!',
            message:"Aguarde, o pagamento deverá ser processado em breve!",
            action:""
        },
        "error":{
            code:'error',
            title:'Erro no procesamento!',
            message:"Verifique se digitou corretamente os dados do cartão e suas informações na aba 'Meus dados'. Se o erro persistir entre em contato pela aba 'Ajuda.'",
            action:"Tentar novamente"
        },
    }

    const [ userInfo, setUserInfo ] = useState({} as any)
    const [ showContainer, setShowContainer ] = useState(false)
    const [ showPayment, setShowPayment ] = useState(false)
    const [ userPersonalData, setUserPersonalData ] = useState({} as any)
    const [ plan, setPlan ] =useState('')
    const [ flag, setFlag ] = useState(ccDefaultImg)
    const [ creditCard, setCreditCard ] = useState(defaultCC)
    const [ expirationCC, setExpirationCC ] = useState({month:'', year:''})
    const [ transactions, setTransactions ] = useState([])
    const [ expiration, setExpiration ] = useState({} as any)
    // const [ transationResult, setTransationResult ] = useState('none')
    const [ message, setMessage ] = useState(defaultMessages['none'])
    const [ selectedPlan, setSelectedPlan ] = useState('monthly')
    const [ hasActiveSubscription, setHasActiveSubscription ] = useState(false)
    const [ subscriptionDetail, setSubscriptionDetail ] = useState({} as any)
    const navigate = useNavigate();
    const { infoUtils, setInfoUtils } = useInfoUtils();


    async function getUserInfo(){
        const {data} = await api.get("/getuserinfo")
        setUserInfo(data)

    }

    function getLimitAccessData(){

        if(userInfo.activeSubscription?.length>0){

            setHasActiveSubscription(true)
            setSubscriptionDetail(userInfo.activeSubscription[0])

        }

        setExpiration({expirationDate:userInfo.expirationDate, remainDays:userInfo.remainDays})
        
    }

   

    function changeCC(e:any){

        const numberCC = e.target.value

        if(numberCC.match(/^4\d{0,15}/)){
            setFlag(ccVisaImg)
        }else if(numberCC.match(/(^5[1-5]\d{0,2}|^22[2-9]\d|^2[3-7]\d{0,2})\d{0,12}/)){
            setFlag(ccMasterCardImg)
        }else{
            setFlag(ccDefaultImg)

        }

        setCreditCard({...creditCard,number:e.target.value})
    }

    async function getTransactions(){
        if(transactions.length>0){
            setTransactions([])
        }else{
            const { data } = await api.get('/transactions')
            setTransactions(data)
        }
        
    }

    async function configureVisibility(){        

        if(expiration.remainDays<=2 && !hasActiveSubscription){
            setShowContainer(true)
        }else{
            setShowContainer(false)
        }

    }

    async function tryAgain(){
        setShowContainer(false)
        setShowPayment(true)
        setMessage(defaultMessages['none'])
    }

    async function getUserPersonalData(){
        const {data} = await api.get('/getuserpersonaldata')
        setUserPersonalData(data)
    }
    

    useEffect(()=>{
        getUserInfo();
    },[])

    useEffect(()=>{
        getUserPersonalData()
        getLimitAccessData()
    },[userInfo])

    useEffect(()=>{
        configureVisibility()
    },[expiration, hasActiveSubscription])

    useEffect(()=>{
        if(message.code==='approved' || message.code==='pending'){
            setInfoUtils({...infoUtils, allowAccess:true})
            navigate('/content')
        }
    },[message])

    async function purchase(plan:string){

        await getUserPersonalData()


        if(userPersonalData.address
            && userPersonalData.city
            && userPersonalData.cpf 
            && userPersonalData.email
            && userPersonalData.mobile
            && userPersonalData.name 
            && userPersonalData.neighborhood
            && userPersonalData.number 
            && userPersonalData.state 
            && userPersonalData.zipcode
            ){
                setShowContainer(false)
                setShowPayment(true)
                setPlan(plan)
            }else{
                alert("Preencha todos os campos da aba 'Meus dados' antes de prosseguir com a compra")
            }

    }

    async function purchaseFinal(){

        if(
            !(creditCard.cvv!==''
            &&creditCard.name!==''
            &&creditCard.number!==''
            &&expirationCC.month!==''
            &&expirationCC.year!=='')
        ){
            window.alert('Preencha todos os dados do cartão');
            return;
        }

        if(selectedPlan==='anual'){

            try{

                const { data } = await api.post('/carts',{description:plan, installments:creditCard.installments})
    
                if(data){   
    
    
                    try{

                        setShowPayment(false)

                        

                        const newResponse = await api.post('/transactions',{
                            cart_id:data.id,
                            installments:parseInt(creditCard.installments),
                            credit_card_number:creditCard.number,
                            credit_card_expiration:(expirationCC.month+expirationCC.year).toString(),
                            credit_card_holder_name:creditCard.name,
                            credit_card_cvv:creditCard.cvv
                        })
        
                        if(newResponse){
                            if(newResponse.data.response.status==='approved'){
                                setMessage(defaultMessages['approved'])
                                // setShowPayment(false)
                                getUserInfo()
                            }else if(newResponse.data.response.status==='refused'){
                                setMessage(defaultMessages['refused'])
                                // setShowPayment(false)
                            }else{
                                setMessage(defaultMessages['pending'])
                                // setShowPayment(false)
                            } 
                        }
                    }catch(err){
                        setMessage(defaultMessages['error'])
                        // setShowPayment(false)
                    }
        
                    
        
                }
    
            }catch(err){
                setMessage(defaultMessages['error'])
                // setShowPayment(false)
            }

        }else{

            try{
                setShowPayment(false)

                const newResponse = await api.post('/subscriptions',{
                    plan_name:plan,
                    credit_card_number:creditCard.number,
                    credit_card_expiration:(expirationCC.month+expirationCC.year).toString(),
                    credit_card_holder_name:creditCard.name,
                    credit_card_cvv:creditCard.cvv
                })

                if(newResponse){

                    // console.log(newResponse)

                    if(newResponse.data.response.status==='approved'){
                        setMessage(defaultMessages['approved'])
                        // setShowPayment(false)
                        getUserInfo()
                    }else if(newResponse.data.response.status==='refused'){
                        setMessage(defaultMessages['refused'])
                        // setShowPayment(false)
                    }else if(newResponse.data.response.status === 'error'){
                        setMessage(defaultMessages['error'])
                        // setShowPayment(false)
                    }else{
                        setMessage(defaultMessages['pending'])
                        // setShowPayment(false)
                    } 
                }
            }catch(err){
                setMessage(defaultMessages['error'])
                // setShowPayment(false)
            }
            
        }
        
        
        
    }

    function selectAnotherPlan(){
        setCreditCard({...creditCard, installments:"1"})
        setShowContainer(true)
        setShowPayment(false)
    }



    return(
            <Container>

                

                {
                    hasActiveSubscription
                    ?<>
                        <h4 style={{marginBottom:'1rem'}}>Você possui uma assinatura mensal ativa!</h4>
                        <span style={{marginLeft:'1rem'}}>
                            <p>{subscriptionDetail?.created_at?`Data de início: ${new Intl.DateTimeFormat('pt-BR',{ day:'numeric',month: 'short', year: 'numeric'}).format(new Date(subscriptionDetail?.created_at))}`:null}</p>
                            <p>{subscriptionDetail?.name?`Plano: ${(subscriptionDetail?.name).toUpperCase()}`:null}</p>
                            <p>{`Valor mensal: R$ ${subscriptionDetail?.monthly_value},00`}</p>
                            <p>{`Id da Assinatura: ${subscriptionDetail?.code}`}</p>
                        </span>

                    </>
                    :<p>{expiration?.expirationDate?'Seu plano é válido até '+new Intl.DateTimeFormat('pt-BR',{ day:'numeric',month: 'short', year: 'numeric'}).format(new Date(expiration?.expirationDate)) +' ('+(expiration?.remainDays>=0?expiration?.remainDays+' dias)':"Plano expirado)"):null}  &nbsp;
                
                    <span style={{cursor:'pointer', marginTop:'1rem', textDecoration:'underline'}} 
                                onClick={getTransactions}>
                                    {
                                        transactions.length>0
                                        ?"Ocultar histórico de compra"
                                        :"Ver histórico de compra"
                                    }
                                    </span>
    
    
                    </p>  
                }
                 
        

                <ul>
                    {transactions.map((transaction:any)=>{
                        return(
                            <>
                                <li>
                                    <p>{new Intl.DateTimeFormat('pt-BR',{ day:'numeric',month: 'numeric', year: 'numeric'}).format(new Date(transaction.created_at))}
                                    &nbsp;- {transaction.status==='approved'?'Pagamento Aprovado':transaction.status==='refused'?'Pagamento Recusado':'Pagamento Pendente'}
                                    &nbsp;- R$ {transaction.total}
                                    &nbsp;({transaction.installments} parcelas)</p>
                                </li>
                            </>
                        )
                    })}
                    </ul>



                    {
                        message.code !== 'none'
                        ?<div style={{display:'flex', flexDirection:"column", alignItems:'center', marginTop:'2rem'}}>
                            
                            <h2>
                                <span style={{display:'inline'}}>
                                {
                                    message.code==='approved'
                                    ?<ImCheckmark style={{display:'inline-block', margin:0, verticalAlign:'middle', color:'#004C00'}} />
                                    :message.code==='pending'
                                    ?<ImHourGlass style={{display:'inline-block', margin:0, verticalAlign:'middle', color:'#444'}}  />
                                    :<ImCancelCircle style={{display:'inline-block', margin:0, verticalAlign:'middle', color:'#AD1300'}}  />
                                }

                                </span>
                                &nbsp;{message.title}</h2>
                            <p style={{marginTop:"1rem"}}>{message.message}</p>
                            <h5 onClick={tryAgain} style={{marginTop:"1rem", cursor:'pointer'}}>{message.action}</h5>             
                        </div>
                        :null
                    }

                {
                    showContainer
                    ?<>
                    
                    <div style={{width:'100%', textAlign:'center', border:'1px solid #ccc', borderRadius:'1rem', marginTop:'.5rem'}}>
                        <h5 style={{margin:'.5rem 0', color:'#5C2E7E'}}>Preencha todos seus dados e selecione um plano abaixo!</h5>
                    </div>
                    {/* <div className="planButons">
                        <button className={selectedPlan==='anual'?'selectedPlan':'unselectedPlan'} onClick={()=>setSelectedPlan('anual')}>Plano anual</button>
                        <button  className={selectedPlan==='monthly'?'selectedPlan':'unselectedPlan'} onClick={()=>setSelectedPlan('monthly')}>Plano mensal</button>
                    </div> */}
                    {
                        // selectedPlan==='anual'
                        false
                        ?<CardsContainer>
                            {/*@ts-ignore*/}
                            <Card onClick={()=>purchase('standard')}>
                                <h4>Standard</h4>
                                
                                <hr />
        
                                <div>
                                    <p><strong>02</strong> matérias</p>
                                    <p><strong>50</strong> alunos</p>
                                    <p><strong>10</strong> classes</p>
                                    <p><strong>250</strong> Mb para mídias</p>
                                </div>
        
                                <span><h3>R$ 100,00/mês</h3>  <p style={{marginTop:".2rem"}}>Total de R$ 1.200,00</p></span>
                                
                            </Card>
                            {/*@ts-ignore*/}
                            <Card onClick={()=>purchase('professional')}>
                                <h4>Professional</h4>
        
                                <hr />
        
                                <div>
                                    <p><strong>05</strong> matérias</p>
                                    <p><strong>100</strong> alunos</p>
                                    <p><strong>50</strong> classes</p>
                                    <p><strong>500</strong> Mb para mídias</p>
                                    {/* <p>Sistema de mensagem</p> */}
                                </div>
        
                                <span><h3>R$ 180,00/mês</h3>  <p style={{marginTop:".2rem"}}>Total de R$ 2.160,00</p></span>
                            </Card>
                            {/*@ts-ignore*/}
                            <Card onClick={()=>purchase('premium')}>
                                <h4>Premium</h4>
        
                                <hr />
        
                                <div>
                                    <p><strong>10</strong> matérias</p>
                                    <p><strong>500</strong> alunos</p>
                                    <p><strong>100</strong> classes</p>
                                    <p><strong>2</strong> Gb para mídias</p>
                                    {/* <p>Sistema de mensagem</p> */}
                                    <p>Suporte premium</p>
                                </div>
        
                                <span><h3>R$ 250,00/mês</h3>  <p style={{marginTop:".2rem"}}>Total de R$ 3.000,00</p></span>
                            </Card>
        
                        </CardsContainer>
                        :<CardsContainer>{/*@ts-ignore*/}
                        <Card onClick={()=>purchase('standard')}>
                            <h4>Standard</h4>
                            
                            <hr />
    
                            <div>
                                <p><strong>02</strong> matérias</p>
                                <p><strong>50</strong> alunos</p>
                                <p><strong>10</strong> classes</p>
                                <p><strong>250</strong> Mb para mídias</p>
                            </div>
    
                            <span><h3>R$ 89,00/mês</h3>  <p style={{marginTop:".2rem"}}>Cobrado mensalmente</p></span>
                            
                        </Card>
                        {/*@ts-ignore*/}
                        <Card onClick={()=>purchase('professional')}>
                            <h4>Professional</h4>
    
                            <hr />
    
                            <div>
                                <p><strong>05</strong> matérias</p>
                                <p><strong>100</strong> alunos</p>
                                <p><strong>50</strong> classes</p>
                                <p><strong>500</strong> Mb para mídias</p>
                                {/* <p>Sistema de mensagem</p> */}
                            </div>
    
                            <span><h3>R$ 169,00/mês</h3>  <p style={{marginTop:".2rem"}}>Cobrado mensalmente</p></span>
                        </Card>
                        {/*@ts-ignore*/}
                        <Card onClick={()=>purchase('premium')}>
                            <h4>Premium</h4>
    
                            <hr />
    
                            <div>
                                <p><strong>10</strong> matérias</p>
                                <p><strong>500</strong> alunos</p>
                                <p><strong>100</strong> classes</p>
                                <p><strong>2</strong> Gb para mídias</p>
                                {/* <p>Sistema de mensagem</p> */}
                                <p>Suporte premium</p>
                            </div>
    
                            <span><h3>R$ 249,00/mês</h3>  <p style={{marginTop:".2rem"}}>Cobrado mensalmente</p></span>
                        </Card>
    
                    </CardsContainer>
                    }
                    
                    <p style={{textAlign:'center', marginTop:'1rem', fontSize:'1rem', color:'purple'}}>Para pagamento anual, com boleto, pix ou em caso de dificuldades <strong><a href="https://wa.me/5533991962158" target="_blank">clique aqui</a></strong></p>
                </>
                :showPayment
                ?<PaymentBox>

                <div className="cols">
                    <div className="cardInfo">
                        <span  className="fullCols">
                            <input type="text" placeholder="Número do cartão" value={creditCard.number} onChange={(e)=>changeCC(e)}/>
                            <input type="text" placeholder="Nome" value={creditCard.name}onChange={(e)=>setCreditCard({...creditCard,name:e.target.value})}/>
                        </span>
                        <span className="halfCols">
                            {/* <input type="text" placeholder="Validade" value={creditCard.expiration}onChange={(e)=>setCreditCard({...creditCard,expiration:e.target.value})}/> */}
                            <select onChange={(e)=>setExpirationCC({...expirationCC,month:e.target.value})}>
                                <option value={''}>Mês</option>
                                <option value={'01'} selected={expirationCC.month==='01'}>01</option>
                                <option value={'02'} selected={expirationCC.month==='02'}>02</option>
                                <option value={'03'} selected={expirationCC.month==='03'}>03</option>
                                <option value={'04'} selected={expirationCC.month==='04'}>04</option>
                                <option value={'05'} selected={expirationCC.month==='05'}>05</option>
                                <option value={'06'} selected={expirationCC.month==='06'}>06</option>
                                <option value={'07'} selected={expirationCC.month==='07'}>07</option>
                                <option value={'08'} selected={expirationCC.month==='08'}>08</option>
                                <option value={'09'} selected={expirationCC.month==='09'}>09</option>
                                <option value={'10'} selected={expirationCC.month==='10'}>10</option>
                                <option value={'11'} selected={expirationCC.month==='11'}>11</option>
                                <option value={'12'} selected={expirationCC.month==='12'}>12</option>
                            </select>
                            <select onChange={(e)=>setExpirationCC({...expirationCC,year:(e.target.value).substring(2,4)})}>
                                <option value={''}>Ano</option>
                                <option value={getYear(new Date())} selected={expirationCC.year===(getYear(new Date())).toString().substring(2,4)} >{getYear(new Date())}</option>
                                <option value={getYear(new Date())+1} selected={expirationCC.year===(getYear(new Date())+1).toString().substring(2,4)} >{getYear(new Date())+1}</option>
                                <option value={getYear(new Date())+2} selected={expirationCC.year===(getYear(new Date())+2).toString().substring(2,4)} >{getYear(new Date())+2}</option>
                                <option value={getYear(new Date())+3} selected={expirationCC.year===(getYear(new Date())+3).toString().substring(2,4)} >{getYear(new Date())+3}</option>
                                <option value={getYear(new Date())+3} selected={expirationCC.year===(getYear(new Date())+4).toString().substring(2,4)} >{getYear(new Date())+4}</option>
                                <option value={getYear(new Date())+5} selected={expirationCC.year===(getYear(new Date())+5).toString().substring(2,4)} >{getYear(new Date())+5}</option>
                                <option value={getYear(new Date())+6} selected={expirationCC.year===(getYear(new Date())+6).toString().substring(2,4)} >{getYear(new Date())+6}</option>
                            </select>
                            <input type="text" placeholder="CVV" value={creditCard.cvv}onChange={(e)=>setCreditCard({...creditCard,cvv:e.target.value})}/>
                            
                            
                        </span>
                        <span className="fullCols" style={{width:'100%'}}>
                            
                            {
                                selectedPlan==='anual'
                                ?<select onChange={(e)=>setCreditCard({...creditCard,installments:e.target.value})}>
                                    <option value={1}>1 parcela</option>
                                    <option value={2}>2 parcelas</option>
                                    <option value={3}>3 parcelas</option>
                                    <option value={4}>4 parcelas</option>
                                    <option value={5}>5 parcelas</option>
                                    <option value={6}>6 parcelas</option>
                                    <option value={7}>7 parcelas</option>
                                    <option value={8}>8 parcelas</option>
                                    <option value={9}>9 parcelas</option>
                                    <option value={10}>10 parcelas</option>
                                    <option value={11}>11 parcelas</option>
                                    <option value={12}>12 parcelas</option>
                                </select>
                                :null
                            }
                        </span>
                    </div>
                    <div className="cardPreview">
                        <MiniCard>
                            <img className="flag" src={flag} alt="Bandeira" />
                            <img className="chip" src={ccChipImg} alt="Chip" />

                            <p className="nameLabel">Nome do Titular</p>
                            <p className="nameValue">{creditCard.name?creditCard.name:"Fulano da Silva"}</p>

                            <p className="numberLabel">Número do cartão</p>
                            <p className="numberValue">{creditCard.number?creditCard.number:"1234 5678 9012 3456"}</p>

                            <p className="expLabel">Expiração</p>
                            <p className="expValue" style={{display:'inline'}}>
                                {
                                    expirationCC.month 
                                    ?expirationCC.month+'/'
                                    :"02/"
                                }
                            </p>
                            <p className="expValue2" style={{display:'inline'}}>
                                {
                                    expirationCC.year 
                                    ?expirationCC.year
                                    :"32"
                                }
                            </p>

                            <p className="cvvLabel">CVV</p>
                            <p className="cvvValue">{creditCard.cvv?creditCard.cvv:"123"}</p>

                        </MiniCard>
                    </div>
                </div>
                <div className="resume">
                    <div>
                        <h3 style={{margin:'1rem 0'}}>Você vai comprar</h3>
                        {
                            selectedPlan==='monthly'
                            ?<>
                                <strong>{plan==='standard'?'Plano mensal Standard':plan==='professional'?'Plano mensal professional':plan==='premium'?'Plano mensal premium':null}</strong>
                                <p style={{fontSize:'.9rem', marginTop:'.3rem', marginBottom:'0'}}>{plan==='standard'?'R$ 89,00/mês':plan==='professional'?'R$ 169,00/mês':plan==='premium'?'R$ 249,00/mês':null}</p>
                                <p style={{fontSize:'.9rem', marginTop:'.5rem', marginBottom:'0', color:'#ad1300'}}>Ao clicar em comprar você aceita que esse valor seja cobrado mensalmente do cartão acima como uma assinatura</p>
                                

                            
                            </>
                            :<>
                            <strong>{plan==='standard'?'Plano anual Standard':plan==='professional'?'Plano anual professional':plan==='premium'?'Plano anual premium':null}</strong>
                            <p style={{fontSize:'.9rem', marginTop:'.3rem', marginBottom:'0'}}>{plan==='standard'?'R$ 100,00/mês':plan==='professional'?'R$ 180,00/mês':plan==='premium'?'R$ 250,00/mês':null}</p>
                            <p style={{fontSize:'.9rem', marginTop:'.3rem', marginBottom:'0'}}>Parcelas {parseInt(creditCard.installments)>3?creditCard.installments:creditCard.installments+' (com desconto)'} </p>
                            <p style={{fontSize:'1rem', marginTop:'.3rem', color:'#333'}}>
                                {
                                    plan==='standard'
                                    ?parseInt(creditCard.installments)>3?'TOTAL: R$ 1.200,00':'TOTAL: R$ 1.140,00'

                                    
                                    :plan==='professional'
                                    ?parseInt(creditCard.installments)>3?'TOTAL: R$ 2.160,00':'TOTAL: R$ 2.052,00'

                                    :plan==='premium'
                                    ?parseInt(creditCard.installments)>3?'TOTAL: R$ 3.000,00':'TOTAL: R$ 2.850,00'

                                    :null}</p>

                        
                        </>
                        }
                        
                    </div>
                    <div>
                        <h3 style={{margin:'1rem 0'}}>&nbsp;</h3>
                        <button className="reverse" onClick={selectAnotherPlan}>Selecionar outro plano</button>
                        <button className="mainColor" onClick={purchaseFinal}>Comprar</button>
                    </div>
                    
                </div>
                
                
                
                </PaymentBox>
                :null
                }

                
                
              
                
            </Container>
            
    )
}