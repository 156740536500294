import styled from "styled-components";

export const Container = styled.div`

    .mediaItem{
        background-color: white;
        padding: 2rem;
        border-radius: 1rem;
        margin: 1rem;
    }

    .imageStyle{
        width: 100%;
        border-radius: 1rem;
    }

    .mediaPlayer{
        width: 100%;
    }

`;