import { Container, Images, Titles } from "./styles";
import noteboookImg from "../../../assets/images/notebook.png"
import mobileImg from "../../../assets/images/mobile.png"
import pdfImg from "../../../assets/images/pdf.png"
import videoImg from "../../../assets/images/video.png"

export function Hero(){
    return(
        //@ts-ignore
        <Container id="home">
            <Titles>
                <h4>PRÁTICO E FÁCIL</h4>
                <h1>Plataforma completa</h1>
                <p>para professores particulares!</p>
                <div className="buttonArea">
                    <a href="#register">
                        <button className="buttonTest">Teste grátis</button>
                    </a>
                    <a href="#about">
                        <button className="buttonMore">Saiba mais</button>
                    </a>
                </div>
            </Titles>
            <Images>
                <img className="notebook" src={noteboookImg} alt="" />
                <img className="mobile" src={mobileImg} alt="" />
                <img className="video" src={videoImg} alt="" />

            </Images>
        </Container>
    )
}