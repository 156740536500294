import { useEffect, useState } from "react"
import { api } from "../../../services/api"
import { Container } from "./styles"

export function Help(){

    const [ userPersonalData, setUserPersonalData ] = useState({} as any)
    const [ subject, setSubject ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ messageSent, setMessageSent ] = useState('')

    async function getUserPersonalData(){
        const {data} = await api.get('/getuserpersonaldata')
        // console.log(data)
        setUserPersonalData(data)
    }

    async function sendHelpMail(){

        if(subject ==='' || message === ''){
            alert('Selecione um assunto e digite uma mensagem antes de enviar')
        }else{
            if(message.length<20){
                alert('Sua mensagem precisa ter pelo menos 20 caracteres!')
            }else{

                // console.log({subject:subject,message:message, clientName:userPersonalData.name, clientEmail:userPersonalData.email})

                const data = await api.post('askhelp',{subject:subject,message:message, clientName:userPersonalData.name, clientEmail:userPersonalData.email})
                if(data){
                    setMessageSent('Sua solicitação de ajuda foi enviada! A resposta será encaminhada para seu e-mail!')
                }
            }
        }

        

    }

    useEffect(()=>{
        getUserPersonalData()
    },[])


    return(
        <Container>
            <a href="https://www.youtube.com/watch?v=_X0GeklCUPY&ab_channel=ClassHelper" target={'new'}>
                <div className="tutorial">
                Assistir tutorial
            </div>
            </a>
            {
                messageSent.length > 2
                ?<>
                    <p className="sentMessage">{messageSent}</p>
                    <p className="sendAnother" onClick={()=>setMessageSent('')}>Enviar outro pedido</p>
                </>
                :<>
                    <p>{userPersonalData?.name+' - '+userPersonalData?.email}</p>
                    <p className="infoText">A resposta para sua solicitação será enviada para o e-mail acima</p>
                    <select onChange={(e)=>setSubject(e.target.value)}>
                        <option value="">Selecione o assunto</option>
                        <option value="Erro no sistema">Erro no sistema</option>
                        <option value="Financeiro">Financeiro</option>
                        <option value="Dúvidas">Dúvidas</option>
                        <option value="Outro assunto">Outro assunto</option>
                    </select>
                    <textarea cols={30} rows={10} onChange={(e)=>setMessage(e.target.value)}></textarea>
                    <button onClick={sendHelpMail}>Enviar</button>
                </>
            }
            
        </Container>
        
    )
}