import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .smallLink{
      font-size: 12px;
      color: grey;
      cursor: pointer;

      &:hover{
        filter: brightness(.8);
      }
    }

    .resetButton{

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: .9rem;
      /* width: 5rem; */
      height: 2rem;
      border-radius: 1rem;
      color: #FFF;
      background: var(--purple);
      border: 1px solid var(--purple);
      padding: 0 1rem;
      margin: 1rem 0;

      svg{
        margin: 0;
        padding: 0;
        margin-left: 1rem;
      }

      /* margin-top: 3rem; */

      transition: 0.2s;

      &:hover{

          color: var(--purple);
          background: transparent;

      }

    }

`;

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    height: 19rem;
    width: 17rem;

    border: solid;
    
    border-width: 1.3px;
    border-radius: 25px;

    
    z-index:1;


    animation: drawLineBox .9s 1.0s;
    animation-fill-mode: both;

     @keyframes drawLineBox {
        from {
            border-color: var(--background);
            box-shadow: none;
        }
        to {
            border-color: var(--purple);
            box-shadow: 1px 5px 5px #d9d9d9;
    }
  }

`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    padding:20px;
    width:100%;
`;

  export const Logo = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  align-self:center;
  background-color:var(--background);
  position: absolute;
  border: solid;
  border-width:4px;
  border-color:var(--background);

  h1{
        
        color: var(--purple);
        font-weight: 400;

        display: inline-block;
        /* position: absolute;
        left: 10%;
        top: 20%; */

        em{
            display: inline-block;
            padding: .5rem;
            background: var(--purple);
            color: #FFF;
            font-style: normal;

        }

        animation: sizeDown 1.0s 0.5s;
        animation-fill-mode: both;

            @keyframes sizeDown {
        from {
                font-size: 35px;           
        }
        to {
                font-size: 30px;
        }
        }

    }


  

  animation: goUp 1.0s 0.5s;
  animation-fill-mode: both;

    @keyframes goUp {
  from {
    margin-bottom: 0rem;
    
  }
  to {
    margin-bottom:19rem;
    
  }
  }

  `

export const Input = styled.input`
    padding:5px;
    border: none;
    border-bottom: solid;
    border-bottom-width: 1.3px;
    border-bottom-color: var(--purple);
    margin-top: 10px;
    margin-bottom: 10px;
    font-size:14px;
    outline: none;
    color: #2E2E2E;
    background: var(--background);

    &:focus{
       border-bottom-width: 2px;
   };


   animation: drawLine .9s 1.0s;
   animation-fill-mode: both;

     @keyframes drawLine {
   from {
     opacity:0;
     width: 0%;
   }
   to {
     opacity:1;
     width: 100%;
   }
  }

`;

  export const Footer = styled.div`

  display:flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:flex-end;
  align-content: flex-end;
  animation: emerge .9s 1.5s;
  animation-fill-mode: both;

  button{

    /* position: absolute;
    right: 0; */

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: .9rem;
    width: 5rem;
    height: 2rem;
    border-radius: 1rem;
    color: #FFF;
    background: var(--purple);
    border: 1px solid var(--purple);
    padding: 0 .6rem;

    margin-top: 3rem;

    transition: 0.2s;

    &:hover{

        color: var(--purple);
        background: transparent;

    }

    }


    @keyframes emerge {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 }

  `;


// import styled from 'styled-components'
// import loginBgImg from '../../assets/images/login-bg.jpg'

// export const Container = styled.div`

//     width: 100%;
//     height: 100vh;

//     background: var(--background);

//     display: flex;
//     align-items: center;
//     justify-content: center;


// `;

// export const Highlighted = styled.div`

//     position: fixed;

//     @media (min-width: 720px){
//         width: 70%;
//         height: 80vh;
//     }

//     width: 100%;
//     height: 100vh;

//     background: #FFF;

//     display: flex;
//     align-items: center;
//     justify-content: space-around;

//     border-radius: 1rem;

//     h1{
//         font-size: 40px;
//         color: var(--purple);
//         font-weight: 400;

//         display: inline-block;
//         position: absolute;
//         left: 10%;
//         top: 20%;

//         em{
//             display: inline-block;
//             padding: .5rem;
//             background: var(--purple);
//             color: #FFF;
//             font-style: normal;

//         }

//     }

//     form{


//         position: absolute;
//         left: 10%;

//         @media (min-width: 720px){
//             width: 25%;
//         }
//         @media (max-width: 719px){
//             width: 80%;
//         }

//         input{
//             display: flex;

//             width: 100%;

//             height: 2rem;
//             border-radius: 1rem;
//             color: var(--text-body);
//             background: transparent;
//             border: 1px solid var(--purple);

//             margin-top: 0.5rem;
//             padding-left: 1rem;


//         }

//         button{

//             position: absolute;
//             right: 0;

//             display: flex;
//             align-items: center;
//             justify-content: center;

//             font-size: 1.2rem;

//             width: 7rem;

//             height: 2rem;
//             border-radius: 1rem;
//             color: #FFF;
//             background: var(--purple);
//             border: 1px solid var(--purple);

//             margin-top: 3rem;

//             transition: 0.2s;

//             &:hover{

//                 color: var(--purple);
//                 background: #FFF;

//             }

//         }
//     }

//     img{
//         @media (min-width: 995px){
//             position: absolute;
//             right: 20%;
//             width: 40%;
//             z-index: 100;
//         }
//         @media (max-width: 994px){
//             display: none;
//         }



//     }

//     .blueBackground{
//         position: absolute;

//         @media (min-width: 720px){
//             right: 0;
//             height: 80vh;
//             width: 40%;
//             border-radius: 0 1rem 1rem 0;
//         }

//         @media (max-width: 719px){
//             bottom: 0;
//             height: 25vh;
//             width: 100%;
//             /* border-radius: 0 0 1rem 1rem; */
//         }


        
//         background: url(${loginBgImg});
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: cover;

//         filter: brightness(.6);

//     }

//     .loader {
//     position: absolute;
//     left: 15%;
//     border: 16px solid #f3f3f3; /* Light grey */
//     border-top: 16px solid #003457; /* Blue */
//     border-width: 3.5px;
//     border-radius: 50%;
//     width: 6rem;
//     height: 6rem;
//     animation: spin 0.9s linear infinite;
//   }

//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }

// `;
