import { PersonalData } from "../UserModal/PersonalData";
import { PlanInfo } from "../UserModal/PlanInfo";
import { Container, Logo } from "./styles";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import logoutImg from '../../assets/images/logout.svg'

export function Purchase(){
    const navigate = useNavigate();

    function logout(){

        Cookies.remove('token_classhelper');
        localStorage.clear();
                
        navigate('/login');
  
      }
    return(
        <Container>
            <Logo>
                <h1>class <em>helper</em></h1>
            </Logo>
            <img    
                    style={{position:'fixed', top:'1rem', right:'1rem', cursor:'pointer'}}
                    width={'25px'}
                    src={logoutImg} 
                    alt="Sair" 
                    onClick={logout}
                />
            <PlanInfo />
            <PersonalData onlyData={true} />
        </Container>
    )
}