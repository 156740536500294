import styled from 'styled-components'

interface ContainerProps{
    bigScreen?: boolean

}

export const Container = styled.div<ContainerProps>`
    display: flex;
    width: 100%;

    @media (max-width: 1024px){
        display: ${(props:any)=>props.bigScreen?'none':'flex'};
    }
    
    svg{
        margin: 0 1rem;
        color: #444;
        cursor: pointer;
        transition: all 0.5s;

        &:hover{
            color: var(--purple);
            transition: all 0.5s;
            scale: 1.1;
        }
    }
    

    display: flex;
    align-items: center;
    @media (max-width: 720px){
        width: 100%;
        justify-content: space-between;
    }
    
    justify-content: ${(props:any)=>props.bigScreen?'center':'flex-start'};
`;