import { createContext, ReactNode, useContext, useState } from "react";

interface InfoUtils {
    screen: string,
    selectedClassId: number,
    allowAccess: boolean
}

interface InfoUtilsProvidertProps {
    children: ReactNode
}

interface InfoUtilsContextData {
    reloadPage:boolean,
    infoUtils: InfoUtils,
    setInfoUtils: (infoUtils: InfoUtils) => void;
    reload: () => void;
}

const InfoUtilsContext = createContext<InfoUtilsContextData>({} as InfoUtilsContextData)



export function InfoUtilsProvider({ children }: InfoUtilsProvidertProps){

    const [reloadPage, setReloadPage] = useState(false)

    async function reload(){

        setReloadPage(!reloadPage)
    }

    const [ infoUtils, setInfoUtils ] = useState<InfoUtils>({
        
            screen: '',
            selectedClassId: 0,
            allowAccess:false        

    })

    return(
        <InfoUtilsContext.Provider value={ {
            infoUtils, 
            setInfoUtils,
            reloadPage,
            reload 
           
            } }>
            { children }
        </InfoUtilsContext.Provider>
    )
}

export function useInfoUtils(){
    const context = useContext(InfoUtilsContext);
    return context;
}