import { useState } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import { About } from "./About";
import { Contatct } from "./Contact";
import { Hero } from "./Hero";
import { MenuModal } from "./MenuModal";
import { Navbar } from "./Navbar";
import { Register } from "./Register";
import { Container,ScrollButtonDiv } from "./styles";
import { TermsModal } from "./TermsModal";
import { Values } from './Values';

export function Site(){

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
        setVisible(true)
        }
        else if (scrolled <= 300){
        setVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);


    return(
        <Container>
            <Navbar />
            <Hero />
            <About />
            <Register />
            <Values />
            <Contatct />
            <TermsModal />
            <MenuModal />
            {/*@ts-ignore*/}
            <ScrollButtonDiv 
                style={{display: visible ? 'flex' : 'none'}} 
                onClick={scrollToTop}>
                <FaArrowCircleUp 
                    style={{fill: '#fff'}} 
                />
            </ScrollButtonDiv>
        </Container>
    )
}