import pdfImg from '../../../assets/images/pdf.svg'
import videoImg from '../../../assets/images/video.svg'
import folderImg from '../../../assets/images/folder.svg'
import editImg from '../../../assets/images/edit.svg'
import deleteImg from '../../../assets/images/delete.svg'

import { Container } from "./styles";
import { useEffect, useState } from 'react';
import { useInfoUtils } from '../../../hooks/useInfoUtilsContext';
import { useModals } from '../../../hooks/useModalsContext'
import { api } from '../../../services/api'

import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'

interface ContentItemProps {
    key:number,
    data:{
        id:number,
        name:string,
        file_id:number,
        mainvideo:string,
        order:number,
        contentitems:any
    }
    info:{
        level:any,
        subject:any,
        total:any
    }
}

export function ContentItem({data, info}:ContentItemProps){

    const [ userRole, setUserRole] = useState(0)
    const { reload } = useInfoUtils();

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")
        setUserRole(data.userRole)
    }


    useEffect(()=>{
        getUserInfo()
        getMainFileUrl()
    },[])

    async function openEditModal(){
        
        handleSetIsEditingId(data.id)
        handleOpenAddContentModal()
        handleSetData(info)
        
    }

    async function updateOrder(order:string){

        await api.put('/contents/'+data.id,{order:parseInt(order)})

    }

    async function alterOrderArrow(direction:any,id:any, order:any){

        if((direction==='up' && order===1) || (direction==='down' && order === info.total)){
            console.log('already done')
        }else{
            console.log(direction, id, order)
            await api.post('/reordercontent',{direction:direction, id:parseInt(id), order:parseInt(order)})
            reload()
        }

        

    }

        
    const { handleOpenMainVideoModal, 
            handleOpenMediasModal, 
            handleOpenAddContentModal, 
            handleSetIsEditingId,
            handleSetData,
            handleSetContentId
         } = useModals()
    const [mainFileUrl, setMainFileUrl] = useState('')


    async function getMediaUrl(name:string, type:string){

        const   fileUrl   = await api.post('/generatedownloadlink',{
          fileName: name,
          fileType:type
        })

        setMainFileUrl(fileUrl.data)
    }

    async function getMainFileUrl(){


        if(data.file_id>0){

            const file = await api.get('contentsitems/'+data.file_id)


            getMediaUrl(file.data[0].value, file.data[0].type)

            
            
            // await data.contentitems.map((item:any)=>{
            //     if(item.id===data.file_id && item.value !== undefined){
            //         getMediaUrl(item.value, item.type)
                    
            //     }
               
            // })
        }
    }

    async function openMediaModal(){

        handleSetContentId(data.id)


        handleOpenMediasModal(data.contentitems)

    }

    return(
        <Container>
                
                {/* <ContentMenu> */}
                <div className='orderEditor'>
                    <MdKeyboardArrowUp onClick={()=>alterOrderArrow('up',data.id, data.order)} className={data.order===1?'disable':''} />
                    <MdKeyboardArrowDown onClick={()=>alterOrderArrow('down',data.id, data.order)} className={data.order===info?.total?'disable':''} />
                </div>
                    <div className='titleStyle'>
                        <p>{data.name}</p>
                    </div>
                    <div>
                        <a href={mainFileUrl} className={data.file_id?'imgIcon':'disableIcon'} target={'_blank'} rel="noreferrer"><img className={data.file_id?'imgIcon':'disableIcon'} src={pdfImg} alt="Arquivo" /></a>
                    </div>
                    <div>
                        <img className={data.mainvideo?'imgIcon':'disableIcon'} src={videoImg} alt="Vídeo" onClick={()=>data.mainvideo?handleOpenMainVideoModal(data.mainvideo):null}/>
                    </div>
                    <div>
                        <img className={data.contentitems.length>0?'imgIcon':'disableIcon'} src={folderImg} alt="Mídias" onClick={()=>data.contentitems.length>0?openMediaModal():null}/>
                    </div>
                    {
                        userRole===1
                        ?<div>
                            <img className='imgIcon' src={editImg} alt="Editar" onClick={openEditModal} />
                            {/* <input 
                                style={{width:'2rem', border:'none', textAlign:'center', color:'grey'}} 
                                defaultValue={data.order} 
                                type="number" 
                                onBlur={(e)=>updateOrder(e.target.value)}
                            /> */}
                        </div>
                        :null
                    }
                    
                    
        </Container>
    )
}