import { createContext, ReactNode, useContext, useState } from "react";




interface ModalsProvidertProps {
    children: ReactNode
}


interface DataModaltProps {
    level:{
        name:string;
        id:number;
    };
    subject:{
        name:string;
        id:number;
    };
}

interface ModalsContextData {


    isChatModalOpen: boolean;
    handleOpenChatModal: () => void;
    handleCloseChatModal: () => void;

    isStudentsModalOpen: boolean;
    dataStudentModal:any;
    setDataStudentModal:(data:any) => void;
    handleOpenStudentsModal: (data:any) => void;
    handleCloseStudentsModal: () => void;

    isPaymentStudentsModalOpen: boolean;
    dataPaymentStudentModal:any;
    setDataPaymentStudentModal:(data:any) => void;
    handleOpenPaymentStudentsModal: (data:any) => void;
    handleClosePaymentStudentsModal: () => void;

    isAddClassModalOpen: boolean;
    dataClassModal:any;
    handleSetDataClass:(data:any) => void;
    handleOpenAddClassModal: () => void;
    handleCloseAddClassModal: () => void;

    isAskClassModalOpen: boolean;
    handleOpenAskClassModal:() => void;
    handleCloseAskClassModal:() => void;

    isMainVideoModalOpen: boolean;
    videoMainVideoModal: string;
    handleOpenMainVideoModal:(video:string) => void;
    handleCloseMainVideoModal:() => void;

    isTutorialVideoModalOpen: boolean;
    videoTutorialVideoModal: string;
    handleOpenTutorialVideoModal:() => void;
    handleCloseTutorialVideoModal:() => void;

    isAddContentModalOpen: boolean;
    isEditingId:number;
    dataContentModal:DataModaltProps;
    handleSetData:(data:any) => void;
    handleSetIsEditingId:(data:number) => void;
    handleOpenAddContentModal:() => void;
    handleCloseAddContentModal:() => void;

    isMediasModalOpen: boolean;
    dataMediasModal: any;
    contentId:number;
    handleSetContentId:(value:number)=>void;
    handleOpenMediasModal:(data:[{}]) => void;
    handleCloseMediasModal:() => void;

    isUserModalOpen: boolean;
    handleOpenUserModal: () => void;
    handleCloseUserModal: () => void;

    isTermsModalOpen: boolean;
    handleOpenTermsModal: () => void;
    handleCloseTermsModal: () => void;

    isStudentConfigModalOpen: boolean;
    handleOpenStudentConfigModal: () => void;
    handleCloseStudentConfigModal: () => void;

    isMenuModalOpen: boolean;
    handleOpenMenuModal: () => void;
    handleCloseMenuModal: () => void;

    isAskStudentNewClassModalOpen: boolean;
    askStudentNewClassModalData:any;
    handleOpenAskStudentNewClassModal: (student:any) => void;
    handleCloseAskStudentNewClassModal: () => void;
}



const ModalsContext = createContext<ModalsContextData>({} as ModalsContextData)



export function ModalsProvider({ children }: ModalsProvidertProps){

    //MODAL CHAT

    const [isChatModalOpen, setisChatModalOpen] = useState(false);

    function handleOpenChatModal(){
        document.body.style.overflow = 'hidden';
        setisChatModalOpen(true)
    }

    function handleCloseChatModal(){
        document.body.style.overflow = 'unset';
        setisChatModalOpen(false)
    }

    //MODAL STUDENTS

    const [isStudentsModalOpen, setIsStudentsModalOpen] = useState(false);
    const [dataStudentModal, setDataStudentModal] = useState()

    function handleOpenStudentsModal(data:any){
        document.body.style.overflow = 'hidden';
        setIsStudentsModalOpen(true)
        setDataStudentModal(data)
    }

    function handleCloseStudentsModal(){
        document.body.style.overflow = 'unset';
        setIsStudentsModalOpen(false)
    }

    //MODAL PAYMENT STUDENTS

    const [isPaymentStudentsModalOpen, setIsPaymentStudentsModalOpen] = useState(false);
    const [dataPaymentStudentModal, setDataPaymentStudentModal] = useState()

    function handleOpenPaymentStudentsModal(data:any){
        document.body.style.overflow = 'hidden';
        setIsPaymentStudentsModalOpen(true)
        setDataPaymentStudentModal(data)
    }

    function handleClosePaymentStudentsModal(){
        document.body.style.overflow = 'unset';
        setIsPaymentStudentsModalOpen(false)
    }

    //MODAL ADD CLASS

    const [isAddClassModalOpen, setIsAddClassModalOpen] = useState(false);
    const [dataClassModal, setDataClasstModal] = useState({} as any);

    function handleOpenAddClassModal(){
        document.body.style.overflow = 'hidden';
        setIsAddClassModalOpen(true)
    }

    function handleCloseAddClassModal(){
        document.body.style.overflow = 'unset';
        setIsAddClassModalOpen(false)
        setDataClasstModal({
            id:0,
            name:'',
            id_subject:0,
            default_value:'',
            active:true
        })
    }


    function handleSetDataClass(data:any){
        setDataClasstModal(data)
    }


    //MODAL USER

    const [isUserModalOpen, setisUserModalOpen] = useState(false);

    function handleOpenUserModal(){
        document.body.style.overflow = 'hidden';
        setisUserModalOpen(true)
    }

    function handleCloseUserModal(){
        document.body.style.overflow = 'unset';
        setisUserModalOpen(false)
    }

    //MODAL ASK CLASS
    const [isAskClassModalOpen, setIsAskClassModalOpen] = useState(false);

    function handleOpenAskClassModal(){
        document.body.style.overflow = 'hidden';
        setIsAskClassModalOpen(true)
    }

    function handleCloseAskClassModal(){
        document.body.style.overflow = 'unset';
        setIsAskClassModalOpen(false)
    }

    //MODAL ASK STUDENT NEW CLASS
    const [isAskStudentNewClassModalOpen, setIsAskStudentNewClassModalOpen] = useState(false);
    const [askStudentNewClassModalData, setAskStudentNewClassModalData] = useState(null)

    function handleOpenAskStudentNewClassModal(student:any){
        setAskStudentNewClassModalData(student)
        document.body.style.overflow = 'hidden';
        setIsAskStudentNewClassModalOpen(true)
    }

    function handleCloseAskStudentNewClassModal(){
        document.body.style.overflow = 'unset';
        setIsAskStudentNewClassModalOpen(false)
    }
    

    //MODAL MAIN VIDEO
    const [isMainVideoModalOpen, setIsMainVideoModalOpen] = useState(false);
    const [videoMainVideoModal, setVideoMainVideoModal] = useState('');

    function handleOpenMainVideoModal(video:string){
        document.body.style.overflow = 'hidden';
        setIsMainVideoModalOpen(true)
        const str = video;
        const res = str.split("=");
        const res2 = res[1].split("&");
        const embeddedUrl = "https://www.youtube.com/embed/" + res2[0];
        setVideoMainVideoModal(embeddedUrl)
        
    }

    function handleCloseMainVideoModal(){
        document.body.style.overflow = 'unset';
        setIsMainVideoModalOpen(false)
    }

    //MODAL TUTORIAL VIDEO
    const [isTutorialVideoModalOpen, setIsTutorialVideoModalOpen] = useState(false);
    const [videoTutorialVideoModal, setVideoTutorialVideoModal] = useState('');

    function handleOpenTutorialVideoModal(){
        document.body.style.overflow = 'hidden';
        setIsTutorialVideoModalOpen(true)
        const str = "https://www.youtube.com/watch?v=_X0GeklCUPY&ab_channel=ClassHelper";
        const res = str.split("=");
        const res2 = res[1].split("&");
        const embeddedUrl = "https://www.youtube.com/embed/" + res2[0];
        setVideoTutorialVideoModal(embeddedUrl)
        
    }

    function handleCloseTutorialVideoModal(){
        document.body.style.overflow = 'unset';
        setIsTutorialVideoModalOpen(false)
    }


    //MODAL ADD CONTENT
    const [isAddContentModalOpen, setIsAddContentModalOpen] = useState(false);
    const [isEditingId, setIsEditingId] = useState(0);
    const [dataContentModal, setDataContentModal] = useState({} as DataModaltProps);

    function handleOpenAddContentModal(){
        document.body.style.overflow = 'hidden';
        setIsAddContentModalOpen(true)
      
    }

    function handleCloseAddContentModal(){
        document.body.style.overflow = 'unset';
        setIsAddContentModalOpen(false)
    }

    function handleSetData(data:DataModaltProps){
        setDataContentModal(data)
    }

    function handleSetIsEditingId(value:number){
        setIsEditingId(value)
    }

    //MODAL MEDIAS
    const [isMediasModalOpen, setIsMediasModalOpen] = useState(false);
    const [dataMediasModal, setDataMediasModal] = useState([{}]);
    const [contentId, setContentId] = useState(0)

    function handleOpenMediasModal(data:any){
        document.body.style.overflow = 'hidden';
        setIsMediasModalOpen(true)
        setDataMediasModal(data)
        
    }

    function handleCloseMediasModal(){
        document.body.style.overflow = 'unset';
        setIsMediasModalOpen(false)
    }

    function handleSetContentId(value:number){
        setContentId(value)
    }

    //MODAL TERMOS

    const [isTermsModalOpen, setisTermsModalOpen] = useState(false);

    function handleOpenTermsModal(){
        document.body.style.overflow = 'hidden';
        setisTermsModalOpen(true)
    }

    function handleCloseTermsModal(){
        document.body.style.overflow = 'unset';
        setisTermsModalOpen(false)
    }

    //MODAL MENU

    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

    function handleOpenMenuModal(){
        document.body.style.overflow = 'hidden';
        setIsMenuModalOpen(true)
    }

    function handleCloseMenuModal(){
        document.body.style.overflow = 'unset';
        setIsMenuModalOpen(false)
    }

    //MODAL STUDENT CONFIG

    const [isStudentConfigModalOpen, setIsStudentConfigModalOpen] = useState(false);

    function handleOpenStudentConfigModal(){
        document.body.style.overflow = 'hidden';
        setIsStudentConfigModalOpen(true)
    }

    function handleCloseStudentConfigModal(){
        document.body.style.overflow = 'unset';
        setIsStudentConfigModalOpen(false)
    }

    

    return(
        <ModalsContext.Provider value={ {

            isChatModalOpen,
            handleOpenChatModal,
            handleCloseChatModal,
            isAddClassModalOpen,
            dataClassModal,
            handleSetDataClass,
            handleOpenAddClassModal,
            handleCloseAddClassModal,
            isAskClassModalOpen,
            handleOpenAskClassModal,
            handleCloseAskClassModal,
            isMainVideoModalOpen,
            videoMainVideoModal,
            handleOpenMainVideoModal,
            handleCloseMainVideoModal,
            isTutorialVideoModalOpen,
            videoTutorialVideoModal,
            handleOpenTutorialVideoModal,
            handleCloseTutorialVideoModal,
            isAddContentModalOpen,
            isEditingId,
            dataContentModal,
            handleSetData,
            handleSetIsEditingId,
            handleOpenAddContentModal,
            handleCloseAddContentModal,
            isMediasModalOpen,
            dataMediasModal,
            contentId,
            handleSetContentId,
            handleOpenMediasModal,
            handleCloseMediasModal,
            isUserModalOpen,
            handleOpenUserModal,
            handleCloseUserModal,
            isTermsModalOpen,
            handleOpenTermsModal,
            handleCloseTermsModal,
            isMenuModalOpen,
            handleOpenMenuModal,
            handleCloseMenuModal,
            isStudentsModalOpen,
            dataStudentModal,
            setDataStudentModal,
            handleOpenStudentsModal,
            handleCloseStudentsModal,
            isPaymentStudentsModalOpen,
            dataPaymentStudentModal,
            setDataPaymentStudentModal,
            handleOpenPaymentStudentsModal,
            handleClosePaymentStudentsModal,
            isStudentConfigModalOpen,
            handleOpenStudentConfigModal,
            handleCloseStudentConfigModal,
            isAskStudentNewClassModalOpen,
            handleOpenAskStudentNewClassModal,
            handleCloseAskStudentNewClassModal,
            askStudentNewClassModalData
            } }>
            { children }
        </ModalsContext.Provider>
    )
}

export function useModals(){
    const context = useContext(ModalsContext);
    return context;
}