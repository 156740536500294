import styled from 'styled-components'

export const Container = styled.div`

    display:flex;
    flex-direction: column;
    background: #FFF;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;


    @media (min-width: 720px){
        width: 75%;
        border-radius: 0.25rem;
    }
    @media (min-width: 1080px){
        width: 55%;
        border-radius: 0.25rem;
    }

    .classSelector{
        border: none;
        background-color: transparent;
        margin-right: 1rem;
        font-size: 10px;
        color: #888;
        font-weight: bold;
    }

    .videoInput{
        border: 1px solid #ccc;
        height: 1.5rem;
        padding-left: .5rem;
        margin-bottom: .5rem;
    }

    textarea{
        border: 1px solid #ccc;
        padding: .5rem;
        margin-bottom: .5rem;
        border-radius: 0.5rem;
        resize: none;
    }

    .hide{
        display: none;
    }

    .show{
        display: flex;
    }

    .smallButton{
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--purple);
        color: #FFF;
        height: 2.5rem;
        border-radius: .5rem;
        border: none;
        margin-top: 10px;
        cursor: pointer;

        &:last-child{
            margin-left: 1rem;
        }

        &:hover{
            filter: brightness(.9);
        }
        
    }

    .line{

        display: flex;
        button{
            width: 6rem;
            background-color: var(--purple);
            color: #FFF;
            height: 1.5rem;
            border-radius: .5rem;
            border: none;
            cursor: pointer;

            &:last-child{
                margin-left: 1rem;
            }

            &:hover{
            filter: brightness(.9);
        }
        }
        &:last-child{
            justify-content: right;
        }
    }

`