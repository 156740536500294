import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import arrLeft from '../../assets/images/arr-left.svg'
import arrRight from '../../assets/images/arr-right.svg'
import { useInfoUtils } from '../../hooks/useInfoUtilsContext';
import { api } from '../../services/api';
import { AddButton } from '../AddButton';
import { ContentItem } from './ContentItem';
import { useNavigate } from 'react-router-dom';

import { Container, HeaderTable, PrevNextButton, MainTable, SubjectCell, LevelCell } from "./styles";
import { useModals } from '../../hooks/useModalsContext';

interface Level {
    id: number,
    name: string,
    order: number,
    id_subject: number,
    active: boolean
}

interface Subject {
    id: number,
    name: string,
    levels: Level[],
    id_teacher: number,
    active: boolean
}

interface Content {
    id: number,
    name: string,
    subject_id: number,
    level: number,
    file_id: number,
    mainvideo:string,
    order:number,
    contentitems: [{}]
}


export function ContentTable() {

    const [subjects, setSubjects] = useState<Subject[]>([])
    const [selectedSubject, setSelectedSubject] = useState<Subject>({} as Subject)
    const [contents, setContents] = useState<Content[]>([])
    const [selectedLevel, setSelectedLevel] = useState({} as Level)
    const { infoUtils, reloadPage } = useInfoUtils();
    const [ userRole, setUserRole] = useState(0)
    const [ userClass, setUserClass ] = useState(0)
    const navigate = useNavigate();


    // const { handleOpenTutorialVideoModal } = useModals()

    // useEffect(()=>{
    //     if(!localStorage.getItem('donotshow')){
    //         handleOpenTutorialVideoModal('https://www.youtube.com/watch?v=_X0GeklCUPY&ab_channel=ClassHelper')
    //     }
    // },[])

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")
        setUserRole(data.userRole)
        if(data.userRole===2){
            setUserClass(data?.userClasses[0])
        }
    }



    async function getSubjectsFunction() {

        if(userRole===2){
            const  { data }  = await api.get('/studentsubjectandlevels/'+userClass,)
            const filteredSubjects = data.filter((item:any)=>item.levels.length>0)

            setSubjects(filteredSubjects)
            //@ts-ignore
            setSelectedSubject(filteredSubjects[0])
            //@ts-ignore
            setSelectedLevel(filteredSubjects[0]?.levels[0])
        }else{
            const  { data }  = await api.get<Subject[]>('/subjects')
        
            const filteredSubjects = data.filter(item=>item.levels.length>0)

            setSubjects(filteredSubjects)
            setSelectedSubject(filteredSubjects[0])
            setSelectedLevel(filteredSubjects[0]?.levels[0])
        }

        
                
        
    }

    

    async function getContentsFunction() {

        if(selectedLevel.id>0){
            const { data } = await api.get<Content[]>('/contentsbylevel/'+selectedLevel.id)
            setContents(data)    
        }
        
    }

   

    


    //funções para alterar matérias e níveis

    function handlePrevSubject() {
        if(selectedSubject.id > subjects[0].id){
            const subjectList = subjects.map(sub => sub.id)
            setSelectedSubject(subjects[subjectList.indexOf(selectedSubject.id) - 1])
        }
    }
    function handleNextSubject() {
        if(selectedSubject.id < subjects[subjects.length-1].id){
            const subjectList = subjects.map(sub => sub.id)
            setSelectedSubject(subjects[subjectList.indexOf(selectedSubject.id) + 1])
        }
    }

    function handlePrevLevel() {
        if(selectedLevel.order > selectedSubject.levels[0].order){
            const levelList = selectedSubject.levels.map(level => level.order)
            setSelectedLevel(selectedSubject.levels[levelList.indexOf(selectedLevel.order) - 1])
        }
    }
    function handleNextLevel() {
        if(selectedLevel.order < selectedSubject.levels[selectedSubject.levels.length-1].order){
            const levelList = selectedSubject.levels.map(level => level.order)
            setSelectedLevel(selectedSubject.levels[levelList.indexOf(selectedLevel.order) + 1])
        }
    }


    useEffect(() => {
        if(!infoUtils.allowAccess){
            navigate('/purchase')
        }
        getUserInfo()
        
    }, [])

    useEffect(()=>{
        if(userRole>0){
            getSubjectsFunction()
        }
    },[userRole])



    useEffect(() => {
        
        if(selectedSubject?.levels !== undefined && selectedSubject.levels !== null){
            setSelectedLevel(selectedSubject?.levels[0])
        }
        
    }, [selectedSubject])



    useEffect(() => {

        if(selectedLevel?.id>0)
        getContentsFunction()
    }, [selectedLevel, reloadPage])



    return (
        <Container>
            
            {subjects.length === 0 ?
                <p>Nenhuma matéria e/ou nível cadastrados</p> :
                <MainTable>
                    <HeaderTable>
                        <SubjectCell>
                            <PrevNextButton
                            //@ts-ignore
                                src={arrLeft} alt="Matéria anterior"
                                onClick={handlePrevSubject}
                                isEnable={selectedSubject?.id > subjects[0].id ? true : false}
                            />
                            <h3>{selectedSubject?.name}</h3>
                            <PrevNextButton
                            //@ts-ignore
                                src={arrRight} alt="Matéria posterior"
                                onClick={handleNextSubject}
                                isEnable={selectedSubject?.id < subjects[subjects.length-1].id ? true : false}
                            />
                        </SubjectCell>
                        <LevelCell>
                                    <PrevNextButton
                                    //@ts-ignore
                                        src={arrLeft} alt="Nível anterior"
                                        onClick={handlePrevLevel}
                                        isEnable={selectedLevel?.order > selectedSubject?.levels[0].order ? true : false}
                                    />
                                    <h3>Nível: {selectedLevel?.name}</h3>
                                    <PrevNextButton
                                    //@ts-ignore
                                        src={arrRight} alt="Nível posterior"
                                        onClick={handleNextLevel}
                                        isEnable={selectedLevel?.order < selectedSubject?.levels[selectedSubject?.levels.length-1].order ? true : false}
                                    />
                        </LevelCell>
                    </HeaderTable>

                
                        {
                            contents.length>0?
                            contents.map(content => {
                                return <ContentItem key={content.id} data={content} info={{level:selectedLevel,subject:selectedSubject, total:contents?.length}} />
                            }):<p>Sem conteúdo para essa matéria e/ou nível</p>
                        }
                        


                </MainTable>}
                {
                    userRole===1
                    ?<AddButton isContent data={{level:selectedLevel,subject:selectedSubject}} />
                    :null
                }
                
            
        </Container>
    )
}