import styled from "styled-components";

interface PrevSubjectProps {
    isEnable:boolean
}

export const PrevNextButton = styled.img<PrevSubjectProps>`
    cursor: ${(props)=>props.isEnable?'pointer':'not-allowed'};
    ${(props)=>props.isEnable?'':'filter: opacity(0.4);'};
    
    /* transition: filter 0.2s;

    &:hover{
        filter: brightness(0.9);
    } */
`;

export const Container = styled.div`
 
    margin-top: 3rem;
    margin-bottom: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    

    p{
        color: var(--text-body);
        margin-top: 10%;
        text-align: center;
    }


    body{
        p{
            width: 100%;
            text-align: center;
        }
    }
`;

// export const LevelCol = styled.div`
//     width: 25%;
// `;

export const MainTable = styled.div`

    display: flex;
    flex-direction: column;
    width: 85%;

    @media only screen and (max-width: 720px)  {
        width: 95%;
    }

`;

export const SubjectCell = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    @media only screen and (max-width: 720px)  {
        width: 100%;
        margin: .3rem;
        background: var(--purple);
        height: 2.5rem;
    }
`;

export const LevelCell = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    
    @media only screen and (max-width: 720px)  {
        width: 100%;
        margin: .3rem;
        background: var(--purple);
        height: 2.5rem;
    }
`;

export const HeaderTable = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-spacing: 0 0.5rem;
    color: #FFF;
    font-weight: 400;
    padding: 1rem;
    text-align: left;
    line-height: 1.5rem;
    background: var(--purple);

    @media only screen and (max-width: 720px)  {
        flex-direction: column;
        margin-bottom: .5rem;
        background: transparent;
        padding: 0;
    }

    h3{
             color: #FFF;
    }

    img{
        width: 13px;
    }

     /* th{
         color: #FFF;
         font-weight: 400;
         padding: 1rem;
         text-align: left;
         line-height: 1.5rem;
         background: var(--purple);
         div{
            display: flex;
            align-items: center;
            justify-content: space-around;
            
         }

         h3{
             color: #FFF;
         }

         &:not(:first-child){
             text-align: center;
         }

         img{
             width: 13px;
         } */
`;