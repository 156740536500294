import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    /* min-height: 80vh; */
    margin-top: 20vh;
    @media (max-width: 720px){
        flex-direction: column;
        margin-top: 5vh;
    }


`;

export const Titles = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;

    @media (max-width: 720px){
        width: 100%;
    }

    h1{
        font-size: 4rem;
        line-height: .9;
        margin: 2rem 0;
    }

    h4,p{
        color: #888;
    }

    p{
        font-size: 1.1rem;
    }

    .buttonArea{

        /* display: flex;
        justify-content: space-around; */

        width: 100%;
        padding-top: 3rem;

        button{
            &:hover{
                filter: brightness(1.2);
            }
        }

    }

    .buttonMore{

        width: 8rem;
        height: 2.4rem;
        border: 2px solid rgba(151, 68, 180,.5);
        color: var(--purple);
        font-weight: bold;
        border-radius: 1.2rem;
        background: transparent;
        

    }

    .buttonTest{

        width: 8rem;
        margin-right: 1rem;
        height: 2.4rem;
        border: 2px solid var(--purple);
        background: var(--purple);
        font-weight: bold;
        border-radius: 1.2rem;
        color: #fff;

    }

`;

export const Images = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80%;
    height: 50vh;

    @media (max-width: 720px){
        width: 100%;
    }
 
    

    @keyframes fadeIn1 {
        0% { opacity: 0; }
        0% { opacity: 0; }
        100% { opacity: 1;}
    }

    @keyframes fadeIn2 {
        0% { opacity: 0; }
        30% { opacity: 0; }
        100% { opacity: 1;}
    }

    @keyframes fadeIn3 {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1;}
        
    }

   

    .notebook{
        width: 100%;
        /* position: absolute; */
        animation: fadeIn1 2s;

    }

    .mobile{
        width: 25%;
        position: absolute;
        right: 2rem;
        top: 25vh;
        object-position: center top;
        -webkit-filter: drop-shadow(-3px -3px 5px #000);
        filter: drop-shadow(-3px -3px 5px #000);
        animation: fadeIn2 2s;

    }


    .video{
        width: 40%;
        position: absolute;
        left: 1rem;
        top: 15vh;
        object-position: center top;
        -webkit-filter: drop-shadow(3px 3px 5px #000);
        filter: drop-shadow(3px 3px 5px #000);
        animation: fadeIn3 2s;

    }
`;