import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useInfoUtils } from '../../hooks/useInfoUtilsContext'
import { api } from '../../services/api'
import { Container } from './styles'


import closeImg from '../../assets/images/close.svg'
import  playTitleImg from '../../assets/images/play-title.svg'
import { useModals } from '../../hooks/useModalsContext'

interface Class{
    id: number
    name: string
    subject: string
}


export function AskStudentNewClassModal(){

    const [ selectedClass, setSelectedClass ] = useState(0)

    const [ classes, setClasses ] = useState<Class[]>([])

    // const { infoUtils, setInfoUtils } = useInfoUtils()
    const { reload } = useInfoUtils();
    const { isAskStudentNewClassModalOpen, handleCloseAskStudentNewClassModal, askStudentNewClassModalData } = useModals()

    // const navigate = useNavigate();

    async function getClasses(){
        const { data } = await api.get('/getclassesteacher')
        setClasses(data)
        setSelectedClass(data[0]?.id)
    }

    useEffect(()=>{
        getClasses()
    },[isAskStudentNewClassModalOpen])

    async function handleSelectClass(){

        

        let newClass = 0
        classes.map(item=>item.id===selectedClass?newClass=item?.id:0)

        if(newClass===0){
            alert("Selecione uma turma para mover o aluno!")
            return;
        }

        // console.log('askStudentNewClassModalData',askStudentNewClassModalData)

        // console.log('remover',{id_user:askStudentNewClassModalData?.id,id_class:askStudentNewClassModalData?.class_id})
        // console.log('adicionar',{id_user:askStudentNewClassModalData?.id,id_class:newClass})


        await api.post('/removeuserfromclass',{id_user:askStudentNewClassModalData?.id,id_class:askStudentNewClassModalData?.id_class})
        await api.post('/addusertoclass',{id_user:askStudentNewClassModalData?.id,id_class:newClass, student_value:askStudentNewClassModalData?.student_value})

        reload()
        
        handleCloseAskStudentNewClassModal()


    }

    return(
        <Modal 
          isOpen={isAskStudentNewClassModalOpen}
          onRequestClose={handleCloseAskStudentNewClassModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content-small percent90"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseAskStudentNewClassModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

            {
                classes.length>0
                ?<>
                    <h3 style={{textAlign:'center'}}>Selecione a nova turma para o aluno {askStudentNewClassModalData?.name}</h3>
                    <select name="classes" id="classes" autoFocus onBlur={(e)=>setSelectedClass(Number(e.target.value))}>
                        <option key={0} value={0}>{"Selecione"}</option>
                        { classes.map(item=><option key={item.id} value={item.id}>{item.name}</option>)} 
                    </select>
                    <div style={{width:"100%", display:'flex', justifyContent:'space-between'}}>
                    <button style={{width:"100%",marginRight:'10px'}} onClick={handleSelectClass}>Mover aluno</button>
                    <button style={{width:"100%",marginLeft:'10px', background:"#FF4646"}} onClick={handleCloseAskStudentNewClassModal}>Cancelar</button>
                    </div>
                </>
                :<p>Não há nenhuma turma cadastrada</p>
            }
          
          </Container>

        </Modal>
    )
}