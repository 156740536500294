import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;

    .colHere{
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media (max-width: 720px){
            flex-direction: column;
        }


        div{
            margin: 0 .5rem;
            cursor: pointer;
            width: 50%;
            text-align: center;
            padding-top: .3rem;
            height: 1.5rem;
            border: 1px solid #ccc;
            border-radius: 1rem;
            /* border-bottom: 1px solid #ccc; */
            
            @media (max-width: 720px){
                width: 90%;
                margin-bottom: .2rem;
            }
            
            
        }
    }

    .selectedNav{
        
        background: #ccc;
        
        /* box-shadow: 3px -3px 3px rgba(0,0,0,.3); */
        border-bottom: none;
    }

    p{
        font-size: .8rem;
        font-weight: bold;
        color: #999;
    }

   

`