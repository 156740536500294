import React, { useState, useEffect } from 'react';
// import { Container, Highlighted } from './styles'
import { Container, LoginContainer, Logo, Form, Input, Footer } from './styles';
import { FaArrowRight } from "react-icons/fa";

import { api } from '../../services/api';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useInfoUtils } from '../../hooks/useInfoUtilsContext';
import { useModals } from '../../hooks/useModalsContext';


const Login = ()=> {

    const [ userData, setUserData ] = useState({ email:'', password:'' });
    const [ session, setSession ] = useState(Cookies.get('token_classhelper')?true:false)
    const [ loginError, setLoginError ] = useState('none')
    const navigate = useNavigate();

    const { infoUtils, setInfoUtils } = useInfoUtils()
    const [ resetPass, setResetPass ] = useState(false)
    const { handleOpenTutorialVideoModal } = useModals()

    async function forgetPass(){

      if(userData.email===''){
        setLoginError('no-email')
      }else{

        await api.post('forgotmypassword',{email:userData.email})
        alert('Verifique seu e-mail para acessar o sistema!')
        setLoginError('none')
        setResetPass(false)
      }

    }


    useEffect(()=>{
      if(session) {
        if(!localStorage.getItem('donotshow')){
          handleOpenTutorialVideoModal()
        }
        navigate('/content')
      }
    },[])

    // useEffect(() => {
    //   const keyDownHandler = (event:any) => {
  
    //     if (event.key === 'Enter') {
    //       event.preventDefault();

  
    //       logIn();
    //     }
    //   };
  
    //   document.addEventListener('keydown', keyDownHandler);
  
    //   return () => {
    //     document.removeEventListener('keydown', keyDownHandler);
    //   };
    // }, []);


    async function logIn(){


      const { email, password } = userData;

        if(email !== "" && password !== ""){

          setSession(true)

          await api.post('/login', userData)
          .then(async (res)=>{


            const { user, token, allowAccess } = res.data;
            const tch = user.id*347+42
            Cookies.set('tch', tch?tch.toString():'', { expires: 0.5, httpOnly:false, sameSite:"strict" });
            Cookies.set('token_classhelper', token.token, { expires: 0.5, httpOnly:false, sameSite:"strict" });
        

            if(user.role===1){
              setInfoUtils({
                ...infoUtils,
                screen:'Conteúdo',
                allowAccess:allowAccess
              })
            }else{

              setInfoUtils({
                ...infoUtils,
                screen:'Conteúdo',
                allowAccess:allowAccess
              })
              
            }

            if(!allowAccess){
              navigate('/purchase')
            }else{
              if(!localStorage.getItem('donotshow')){
                  handleOpenTutorialVideoModal()
              }
          
              navigate('/content')
            }
            
            
            
          })
          .catch(({response})=> {
            setLoginError(response.data)
            setSession(false)
          })

        }

    }

    function handleKeypress(e:any){

      if (e.keyCode === 13) {
        logIn();
      }

    }


    return(
        <Container>
        <LoginContainer>
  
        <Logo>
          <h1>class <em>helper</em></h1>
        </Logo>
  
          <Form 
              // onSubmit={(e)=> {
              //   e.preventDefault();
              //   logIn();
              // }}
          >

{/*@ts-ignore*/}
              <Input type='text' id='email' name='email' placeholder='e-mail' onChange={(e)=>setUserData({...userData, email:e.target.value})}/>
              {
                resetPass
                ?<button className='resetButton' type="button" onClick={forgetPass} >resetar senha <FaArrowRight /> </button>
                :<Input 
                //@ts-ignore
                  type='password' 
                  id='password' 
                  name='password' 
                  placeholder='senha' 
                  //@ts-ignore
                  onChange={(e)=>setUserData({...userData, password:e.target.value})}
                  onKeyDown={handleKeypress}
                />
              }
              
                {
                  loginError === 'invalid-class'
                  ?<p style={{fontSize:'.8rem', color:'#ad1300', marginTop:'.5rem', marginLeft:'1rem'}}>Acesso inválido!</p>
                  :loginError === 'invalid-credentials'
                  ?<p style={{fontSize:'.8rem', color:'#ad1300', marginTop:'.5rem', marginLeft:'1rem'}}>Usuário e/ou senha inválido(s)!</p>
                  :loginError === 'no-email'
                  ?<p style={{fontSize:'.8rem', color:'#ad1300', marginTop:'.5rem', marginBottom:'.5rem'}}>Digite seu e-mail</p>
                  :null
                }
                
  
  
              <Footer>
                {
                  resetPass
                  ?<>
                     <div className='smallLink' onClick={()=>setResetPass(false)}> fazer login</div>
                    
                  </>
                  :<>
                    <div className='smallLink' onClick={()=>setResetPass(true)}>esqueci minha senha</div>
                    <button id='sendButton' type="button" onClick={logIn} >entrar <FaArrowRight /> </button>
                  </>
                }
              </Footer>
  
  
          </Form>
  
        </LoginContainer>
        </Container>
    )
}

export default Login;
