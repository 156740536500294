import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { Container } from "./styles";
import { cpf } from 'cpf-cnpj-validator'
import parsePhoneNumber from 'libphonenumber-js'
import { ChangePassword } from "../../ChangePassword";

export function PersonalData({onlyData=false}:any){

    const [ userPersonalData, setUserPersonalData ] = useState({} as any)
    const [ cpfAlreadyExist, setCpfAlreadyExist ] = useState({class:'hideCPFMessage',message:''})

    useEffect(()=>{
        getUserPersonalData()
    },[])

    async function getUserPersonalData(){
        const {data} = await api.get('/getuserpersonaldata')
        setUserPersonalData(data)
    }

    async function saveUserInformation(info:string,value:string){

        

        if(info === 'cpf'){

            if(!cpf.isValid(value)){
                setCpfAlreadyExist({class:'showCPFMessage',message:'CPF inválido'})
                return
            }

            const { data } = await api.post('/saveuserinformation',{info,value})

            if(data?.code === 1){
                setCpfAlreadyExist({class:'showCPFMessage',message:'CPF pertence a outro usuário'})
            }else{
                setCpfAlreadyExist({class:'hideCPFMessage',message:''})
            }

        }else{
            const {data} = await api.post('/saveuserinformation',{info,value})
        }

        
        
        getUserPersonalData()

        
    }

    
    
    return(
        <Container>

            {!onlyData&&<><h3>Alterar senha</h3>

            <ChangePassword />

            <div>
                <h5>Link das aulas</h5>
                <br/>
                <input 
                    value={userPersonalData?.class_link} 
                    onChange={(e)=>setUserPersonalData({...userPersonalData,class_link:e.target.value})} 
                    onBlur={(e)=>saveUserInformation('class_link',e.target.value)} 
                    type="text" />
                <br/>
            </div></>}

            <h3 className="spaceAfterTitle">Dados pessoais</h3>
           
            <div className="fullSize">
                <p>Nome completo</p>
                <input 
                    value={userPersonalData?.name} 
                    onChange={(e)=>setUserPersonalData({...userPersonalData,name:e.target.value})} 
                    onBlur={(e)=>saveUserInformation('name',e.target.value)} 
                    type="text" />
            </div>

            <div className="fullSize">
                <p>E-mail</p>
                <input 
                    disabled
                    style={{border:'1px solid #ccc'}}
                    value={userPersonalData?.email} 
                    type="text" 
                />
            </div>
            

            <div className="informationBlock">
                <div className="halfSize">
                    <p>Celular</p>
                    <input 
                        value={userPersonalData?.mobile} 
                        onChange={(e)=>setUserPersonalData({...userPersonalData,mobile:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('mobile',e.target.value)} 
                        type="text" />
                </div>
                <div className="halfSize">
                    <p>CPF <span className={cpfAlreadyExist.class}> {cpfAlreadyExist.message}</span></p>
                    
                    <input 
                        style={{border:'1px solid #ccc'}}
                        onChange={(e)=>setUserPersonalData({...userPersonalData,cpf:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('cpf',(e.target.value).replace(/[^?0-9]/g,""))} 
                        value={userPersonalData?.cpf} 
                        type="text" 
                    />
                </div>
            </div>

            <div className="informationBlock">

                <div className="threeQuarterSize">
                    <p>Endereço</p>
                    <input 
                        value={userPersonalData?.address} 
                        onChange={(e)=>setUserPersonalData({...userPersonalData,address:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('address',e.target.value)} 
                        type="text" />
                </div>

                <div className="aQuarterSize">
                    <p>Número</p>
                    <input 
                        value={userPersonalData?.number}
                        onChange={(e)=>setUserPersonalData({...userPersonalData,number:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('number',e.target.value)} 
                        type="text" />
                </div>
                
            </div>

            <div className="informationBlock">
                <div className="halfSize">
                    <p>CEP</p>
                    <input 
                        value={userPersonalData?.zipcode} 
                        onChange={(e)=>setUserPersonalData({...userPersonalData,zipcode:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('zipcode',(e.target.value).replace(/[^?0-9]/g,""))} 
                        type="text" />
                </div>
                <div className="halfSize">
                    <p>Bairro</p>
                    <input 
                        value={userPersonalData?.neighborhood} 
                        onChange={(e)=>setUserPersonalData({...userPersonalData,neighborhood:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('neighborhood',e.target.value)} 
                        type="text" />
                </div>
            </div>

            <div className="informationBlock">
                <div className="halfSize">
                    <p>Estado</p>
                    <input 
                        value={userPersonalData?.state} 
                        onChange={(e)=>setUserPersonalData({...userPersonalData,state:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('state',e.target.value)} 
                        type="text" />
                </div>
                <div className="halfSize">
                    <p>Cidade</p>
                    <input  
                        value={userPersonalData?.city} 
                        onChange={(e)=>setUserPersonalData({...userPersonalData,city:e.target.value})} 
                        onBlur={(e)=>saveUserInformation('city',e.target.value)} 
                        type="text" />
                </div>
            </div>


        </Container>
    )
}