import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root{
    --red: #e52e4d;
    --blue: #5429cc;
    --blue-light: #6933ff;
    --text-title: #313131;
    --text-body: #969cb3;
    /* --background: #F0F2F5; */
    --background: #ECECEC;
    --green: #33CC95;
    --shape: #FFFFFF;
    --purple: #9744B4;
    --purple-dark: #581c6d;

}

 *{
    margin: 0 ;
    padding: 0;
    box-sizing: border-box;
 }

html{
    @media (max-width: 1080px){
        font-size: 93.75%;
    }
    @media (max-width: 720px){
        font-size: 87.5%;
    }
    scroll-behavior: smooth;
}

 body{
    background: var(--background);
    -webkit-font-smoothing: antialiased;
 }

 body, input, textarea, button {
     font-family: 'Popins', sans-serif;
     font-weight: 400;
 }

 h1, h2, h3, h4, h5, h6, strong{
     font-weight: 600;
     color: var(--text-title);
 }

 button {
     cursor: pointer;
 }

 a:link {
  color: var(--purple);
  text-decoration: none;
}

a:visited {
  color: var(--purple-dark);
}

a:hover {
  color: var(--purple-dark);
}

 [disabled]{
     opacity: 0.6;
     cursor: not-allowed;
 }


.react-modal-overlay{
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.react-modal-content{
    width: 100%;
    max-height: 98%;
    overflow-y: scroll;
    max-width: 576px;
    max-width: 720px;
    background: var(--background);
    padding: 1.5rem;
    position: relative;
    border-radius: 0.5rem;
    z-index: 1000;

    @media only screen and (max-width: 720px)  {
        max-height: 100%;
        border-radius: 0;
    }
    

}

.full-content{

    @media only screen and (max-width: 720px)  {
        height: 100%;
    }
}

.no-background{
    background: transparent;
}

.percent90{
    width: 90% !important;
}

.react-modal-content::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 3px;
	background-color: transparent;
}

.react-modal-content::-webkit-scrollbar
{
	width: 6px;
	background-color: transparent;
}

.react-modal-content::-webkit-scrollbar-thumb
{
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--purple);
}


.react-modal-content-small{
    width: 100%;
    max-width: 400px;
    background: var(--background);
    padding: 1.5rem;
    position: relative;
    border-radius: 0.25rem;

}


.react-modal-content-menu{
    width: 100%;
    height: 100%;
    max-width: 400px;
    background: var(--purple);
    padding: 1.5rem;
    position: relative;
    /* border-radius: 0.25rem; */

}

.react-modal-close{
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;

    &:hover{
        filter: brightness(0.8);
    }
}
`


