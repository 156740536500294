import React from 'react'
import Modal from 'react-modal'
import { Container, FieldCustom, FileField } from './styles'
import { v4 as uuidv4 } from 'uuid';
import ReactAudioPlayer from 'react-audio-player';
import deleteImg from '../../assets/images/delete.svg'
// import AWS from 'aws-sdk'
import closeImg from '../../assets/images/close.svg'
import loadingImg from '../../assets/images/loading-greybg.gif'
import { useModals } from '../../hooks/useModalsContext'
import { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useInfoUtils } from '../../hooks/useInfoUtilsContext';



interface ResponseCustom {
  signedUrl: {};
  // defina propriedades adicionais abaixo
}
interface ContentFile {
  id: number;
  type: string;
  value: string;
  url:string;
  description:string;
}

export function AddContentModal(){

  const emptyContent = {
    id_level:0,
    order:1,
    name:'',
    mainvideo:'',
    file_id:0
  }
  

    const { isAddContentModalOpen, 
        handleCloseAddContentModal, 
        dataContentModal, 
        isEditingId, 
        handleSetIsEditingId } = useModals()

    const { reload } = useInfoUtils()

    const [activeModalScreen, setActiveModalScreen] = useState(['showScreen', 'hideScreen','hideScreen'])
    const [progress , setProgress] = useState(0);
    const [files, setFiles] = useState<ContentFile[]>([])
    const [fileDescription, setFileDescription] = useState([])
    const [contentId, setContentId] = useState(0)
    // const [mediaItems, setMediaItems] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [tempFile, setTempFile] = useState({description:'',type:''})
    const [savingStatus, setSavingStatus] = useState('')
    const [mainFile, setMainFile] = useState(0)    
    const [ newContent, setNewContent ] = useState(emptyContent)

    useEffect(()=>{

      if(dataContentModal?.level?.id>0){
        setNewContent({...newContent, id_level:dataContentModal?.level?.id})
      }      

    },[dataContentModal])

    useEffect(()=>{
      
      if(contentId>0){
        // getFiles()
        getContent(isEditingId)
      }
      
    },[contentId])

    useEffect(()=>{
      if(isEditingId>0){
        setActiveModalScreen(['showScreen', 'showScreen','hideScreen'])

        // getContent(isEditingId)

        setContentId(isEditingId)

      }

    },[isEditingId])

    async function addLinkOrVideo(){
      const url = window.prompt("Insira o link")
      let type = 'link'

      if(url){
          if(url.substring(0,19)==='https://www.youtube'){
          type = 'video'
        }
      }

      

      const reload = await api.post('/savecontentitem',{contentId:contentId,contentItem:{type:type, size:0, value:url,description:''}})
      setIsLoading(true)
      setSavingStatus('Salvando...')
      if(reload) setTimeout(()=>{
        getFiles()
        setIsLoading(false)
        setSavingStatus('Salvo')
      }, 1000)

    }

    async function getContent(id:number) {

      const {data} = await api.get('contents/'+id)

      const editingContent = data.content[0]


      setNewContent({
        id_level:editingContent.id_level,
        order:editingContent.order,
        name:editingContent.name,
        mainvideo:editingContent.mainvideo,
        file_id:editingContent.file_id
      })

      getFiles()
      
    }

    async function getFiles() {

      const {data} = await api.get('getfiles/'+contentId)   

      const dataArr = Promise.all(data[0].contentitems.map(async (item:any)=>{

        return {
          ...item, 
          url:await getMediaUrl(item.value, item.type),
        }
      }))

      // setMediaItems(data?.contentitems?.length>0?data.contentitems.length:0)

      setFiles(await dataArr)
      
      
    }   

    async function deleteContent(){

      setActiveModalScreen(['hideScreen','hideScreen','showScreen'])

    }

    async function deleteContentNo(){

      setActiveModalScreen(['showScreen','showScreen','hideScreen'])

    }

    

    async function deleteContentFinal(){

      await api.delete('contents/'+contentId)

      closeModal()

    }

    async function handleSubmit(){



      const savedContent = await api.post(
        '/newcontent',
        newContent
      )

      setSavingStatus('Salvo')
      
      setActiveModalScreen(['showScreen','showScreen','hideScreen'])


      if(savedContent?.data?.content?.id>0){
        setContentId(savedContent.data.content.id)
      }


      
    }

    async function handleFileInput(e:any){
      const originalFile = e.target.files[0];

      if(e.target.files[0].size > 10485760){

        window.alert('Tamanho máximo permitido: 10 Mb')
        return;

      }

      const ext = originalFile.type.split('/')


      if(ext[1] !=='mpeg' && ext[1] !=='png' && ext[1] !=='pdf' && ext[1] !=='jpg' && ext[1] !=='jpeg'){
        window.alert('Extensões permitidas: pdf, mp3, png, jpg')
        return;

      }

      const file = new File([originalFile], uuidv4()+'.'+ext[1]);

      const requestObject = {
            fileName: file.name,
            fileType: file.type
      }

      const  { data }   = await api.post('/generateuploadlink',requestObject)
            
            fetch(data, {
                method:'PUT',
                body :file
            }).then((res) => {
                // setMediaItems(mediaItems+1)
            })

        
      const reload = await api.post('/savecontentitem',{contentId:contentId,contentItem:{type:originalFile.type, size:file.size, value:file.name,description:tempFile.description?tempFile.description:''}})
      setIsLoading(true)
      setSavingStatus('Salvando...')
      if(reload) setTimeout(()=>{
        getFiles()
        setIsLoading(false)
        setSavingStatus('Salvo')
      }, 1000)

      
      // getFiles()

  }

  async function updateDescription(id:number, description:string){
    await api.post('/updatedescription',{id:id,description:description})
  }

  async function updateName(name:string){
    await api.post('/updatename',{id:contentId,name:name})
  }

  async function updateMainVideo(mainvideo:string){
    await api.post('/updatemainvideo',{id:contentId,mainvideo:mainvideo})
  }

  async function defineMainFile(mainFileId:number){
    await api.post('/setmainfile',{contentId:contentId,mainFileId:mainFileId})
    if(mainFile !== mainFileId){
      setMainFile(mainFileId)
    }else{
      setMainFile(0)
    }
  }

  async function getMediaUrl(name:string, type:String){

    if(type === 'link'){
      return name
    }else if(type === 'video'){

      const str = name;
      const res = str.split("=");
      const res2 = res[1].split("&");
      const embeddedUrl = "https://www.youtube.com/embed/" + res2[0];
      return embeddedUrl
    }else{
      const   fileUrl   = await api.post('/generatedownloadlink',{
        fileName: name,
      })
  
      return fileUrl.data;
    }

    
  }

  async function deleteFile(name:string, id:number){

    const  { data }   = await api.post('/deleteobjects3',{
      fileName: name
    })

    await api.delete('/contentsitems/'+id)
    // setMediaItems(mediaItems-1)


    getFiles()
            
    
  }

  async function closeModal(){
    
    handleSetIsEditingId(0)

    setNewContent(emptyContent)
    
    setActiveModalScreen(['showScreen', 'hideScreen','hideScreen'])
    
    const emptyArray:any[] = []

    setFiles(emptyArray)

    setContentId(0)

    reload()

    handleCloseAddContentModal()
  }
    

    return(
        <Modal 
          isOpen={isAddContentModalOpen}
          onRequestClose={closeModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content full-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={closeModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>
          <Container>
            
          <h3>{isEditingId>0?'Editar':'Novo'} conteúdo </h3>
          <br />          
          <span className='titleMain'>
            <h4>{dataContentModal?.subject?.name} - {dataContentModal?.level?.name}</h4>
            <h5><img style={{display:isLoading?'inline':'none'}} src={loadingImg} alt="Carregando" /> {savingStatus}</h5>
          </span>
          
          <br />          
            <div className={activeModalScreen[0]} style={{marginBottom:'2rem'}}>

            <p className='label'>Nome do conteúdo</p>
            <FieldCustom 
              //@ts-ignore
              name='contentname' 
              id='contentname'
              type='text' 
              value={newContent.name}
              //@ts-ignore
              onBlur={(e)=>updateName(e.target.value)}
              //@ts-ignore
              onChange={(e)=>setNewContent({...newContent, name:e.target.value})}
            />

            <p className='label'>Vídeo principal</p>
            <FieldCustom 
              //@ts-ignore
              name='contentvideo' 
              id='contentvideo'
              type='text' 
              value={newContent.mainvideo}
              //@ts-ignore
              onBlur={(e)=>updateMainVideo(e.target.value)}
              //@ts-ignore
              onChange={(e)=>setNewContent({...newContent, mainvideo:e.target.value, id_level:dataContentModal?.level?.id})}
            />

                <span  className={activeModalScreen[1]==='showScreen'?'hideScreen':'showScreen'}>
                  <button type="button" className={isEditingId>0?'hideScreen':'showScreen'} onClick={handleSubmit}>Continuar</button>
                </span>
            </div> 

            <div className={activeModalScreen[1]}>

            {/* <br /> */}
              <h4 style={{marginBottom:'.5rem'}}>Você pode adicionar até <strong>{5-files.length}</strong> items de mídia</h4>
              <p className='smallText' style={{color:'#a01300', marginBottom:'1rem'}}>Extensões permitidas: pdf, mp3, png, jpg. Tamanho máximo: 10 Mb. </p>
              {/* <div>Native SDK File Upload Progress is {progress}%</div> */}

              <table style={{width:'100%'}}>
                {/* <tr>
                  <td>Tipo</td><td>Prévia</td><td>Excluir</td>
                </tr> */}
                {files.map((file=>{
                  return(
                    <div className='mediaItem'>

                    <img className='deleteIcon' src={deleteImg} onClick={()=>deleteFile(file.value, file.id)} />
                      <span>
                        {file.type.substring(0,5)==='image'
                          ?<img className='imageItem' src={file.url} alt="" />
                          :file.type.substring(0,11)==='application' || file.type.substring(0,4)==='text'
                          ?<a href={file.url} target='blank'>Baixar </a>
                          :file.type.substring(0,5)==='audio'
                          ?<ReactAudioPlayer 
                            src={file.url}
                            controls
                          />
                          :file.type.substring(0,4)==='link'
                          ?<a href={file.url} target='blank'>Acessar link </a>
                          :file.type.substring(0,5)==='video'
                          ?<iframe 
                            width="80px" 
                            height="100%" 
                            style={{border:'none', aspectRatio: '16 / 9'}}
                            src={file.url}
                            title="YouTube video player" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                          </iframe>
                          :null
                        }
                      </span>
                      <span>
                        <input type="text" defaultValue={file.description} placeholder='Descrição do arquivo' onBlur={(e)=>updateDescription(file.id, e.target.value)} />
                        {file.type.substring(0,11)==='application'
                        ?<p onClick={()=>defineMainFile(file.id)}>{mainFile===file.id?'Desmarcar como principal':'Marcar como principal'}</p>
                        :null}
                      </span>

                      
                  </div>
                  )
                }))}
                
              </table>

            <span className='buttonArea' style={{display:files.length>=5?'none':'flex'}}>
            {/*@ts-ignore*/}
            <FileField htmlFor='contentfile'>Add arquivo</FileField>
              
            {/* <button type='button' >Add vídeo</button> */}
              
            {/* <FileField htmlFor='contentfile'>Add áudio</FileField>

            <FileField htmlFor='contentfile'>Add arquivo</FileField> */}
              
              
            <button className='buttonClass' type='button' onClick={addLinkOrVideo} >Add Link/Vídeo</button>
            </span>

            {/* <p className='label'>Arquivo principal</p> */}
            {/* <FileField htmlFor='contentfile'>Escolher arquivo</FileField> */}
            <FieldCustom 
              //@ts-ignore
              name='contentfile' 
              id='contentfile'
              type='file' 
              onChange={handleFileInput}
            />

           
              
             
            </div>

            <span>
              <h5 className={activeModalScreen[1]} onClick={deleteContent} style={{color:'#A21300', marginTop:'1rem', cursor:'pointer'}}>Excluir</h5>
            </span>

            <span className={activeModalScreen[2]}>
              
            

              <h3>Aula {newContent.name}</h3>
              <br />

              <p>Confirma a exclusão do conteúdo acima?</p>

              <span style={{display:'flex'}}>
                <button style={{background:'#A21300'}} onClick={deleteContentFinal}>Confirmar</button>
                <button style={{marginLeft:'1rem'}} onClick={deleteContentNo}>Cancelar</button>
              </span>
              
            </span>
          
          
         
          </Container>

        </Modal>
    )
}