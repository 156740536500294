import { Container, CardsContainer, Card } from "./styles"

export function Values(){
   

    return(
        <Container id="values">
            <h2 style={{textAlign:'center', color:'#313131', marginBottom:'1rem'}}>Depois de testar o sistema você pode escolher o melhor plano para você!</h2>
            <h3 style={{textAlign:'center', color:'#313131', marginBottom:'1rem'}}>Até o final de 2024 todos os planos possuirão Suporte Premium!</h3>
            <CardsContainer>
                            {/*@ts-ignore*/}
                            <Card onClick={()=>console.log('standard')}>
                                <h3>Standard</h3>
                                
                                <hr />
        
                                <div>
                                    <p><strong>02</strong> matérias</p>
                                    <p><strong>50</strong> alunos</p>
                                    <p><strong>10</strong> classes</p>
                                    <p><strong>250</strong> Mb para mídias</p>
                                </div>
        
                                <span><h3 style={{marginBottom:'1rem'}}>R$ 89,00/mês</h3>  </span>
                                
                            </Card>
                            {/*@ts-ignore*/}
                            <Card onClick={()=>console.log('professional')}>
                                <h3>Professional</h3>
        
                                <hr />
        
                                <div>
                                    <p><strong>05</strong> matérias</p>
                                    <p><strong>100</strong> alunos</p>
                                    <p><strong>50</strong> classes</p>
                                    <p><strong>500</strong> Mb para mídias</p>
                                    {/* <p>Sistema de mensagem</p> */}
                                </div>
        
                                <span><h3 style={{marginBottom:'1rem'}}>R$ 169,00/mês</h3></span>
                            </Card>
                            {/*@ts-ignore*/}
                            <Card onClick={()=>console.log('premium')}>
                                <h3>Premium</h3>
        
                                <hr />
        
                                <div>
                                    <p><strong>10</strong> matérias</p>
                                    <p><strong>500</strong> alunos</p>
                                    <p><strong>100</strong> classes</p>
                                    <p><strong>2</strong> Gb para mídias</p>
                                    {/* <p>Sistema de mensagem</p> */}
                                    <p>Suporte premium</p>
                                </div>
        
                                <span><h3 style={{marginBottom:'1rem'}}>R$ 249,00/mês</h3> </span>
                            </Card>
        
                        </CardsContainer>
        </Container>
    )
}