import styled from "styled-components";

export const Container = styled.div`

    margin-top: .25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 720px)  {
        margin-bottom: 3rem;
    }


    iframe{
        border-radius: 5px;

    }

    button{
        margin-top: .5rem;
        background: var(--purple);
        line-height: 1.5rem;
        border: 0;
        color: #FFF;
        padding: 0 1rem;
        border-radius: .2rem;
    }
    

    

`;