
import Modal from 'react-modal'

import { Container } from './styles'


import closeImg from '../../assets/images/close.svg'
import { useModals } from '../../hooks/useModalsContext'


export function MainVideoModal(){

   
    const { isMainVideoModalOpen, videoMainVideoModal, handleCloseMainVideoModal } = useModals()
    

    return(
        <Modal 
          isOpen={isMainVideoModalOpen}
          onRequestClose={handleCloseMainVideoModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content no-background"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseMainVideoModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>
          {/* <h3>Vídeo aula</h3> */}
          <br />
          <iframe 
            width="100%" 
            height="100%" 
            style={{border:'none', aspectRatio: '16 / 9', marginTop:'1rem', borderRadius:'1rem'}}
            src={videoMainVideoModal}
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
          </iframe>
         
          </Container>

        </Modal>
    )
}