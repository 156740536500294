import styled from "styled-components";

export const Container = styled.div`


`;

export const BoxChat = styled.div`
    height: 70vh;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-top: 0.5rem;
`;

export const ContactsDiv = styled.div`
    width: 35%;
`;

export const ActiveChatDiv = styled.div`
    width: 65%;
`;