import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    background: #ececec;
    width: 100%;

    /* min-height: 40vh; */

    margin-top: 30vh;
    margin-bottom: 10vh;
    padding: 10vh 0;

    @media (max-width: 720px){
        margin-top: 10vh;
    }
`;

export const Content = styled.div`

    display: flex;
    width: 80%;

    @media (max-width: 720px){
        flex-direction: column;
    }


    div{
    
        width: 50%;
        @media (max-width: 720px){
            width: 100%;
        }
        
    }

    p,h2{
        color: #666;
        margin: 2rem 0;

    }
    p{
        font-size: 1.1rem;
    }

    iframe{
        border: none;       
        border-radius:1rem ;
        width: 90%;
        
        
        @media (max-width: 720px){
            width: 100%;
        }
    }

    .centralizedVideo{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
`;