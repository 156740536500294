import { Container, Logo, Menu, Title } from './styles'

import  notificationImg from '../../assets/images/notification.svg'
import  logoMessage from '../../assets/images/message.svg'
import  logoHelp from '../../assets/images/help.png'
import  cogImg from '../../assets/images/cog.svg'
import  logoProfile from '../../assets/images/profile.svg'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import logoutImg from '../../assets/images/logout.svg'

// import  feedTitleImg from '../../assets/images/feed-title.svg'
// import  playTitleImg from '../../assets/images/play-title.svg'

import { useInfoUtils } from '../../hooks/useInfoUtilsContext'
import { useModals } from '../../hooks/useModalsContext'
import { useEffect, useState } from 'react'
import { api } from '../../services/api'


export function Header(){

    const { infoUtils } = useInfoUtils()
    const { handleOpenChatModal, handleOpenUserModal, handleOpenStudentConfigModal } = useModals()
    const navigate = useNavigate();
    const [ userRole, setUserRole] = useState(0)

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")
        setUserRole(data.userRole)

        if(!data.allowAccess){
            navigate('/purchase')
        }
    }

    useEffect(()=>{
        getUserInfo()
    },[])

    function logout(){

        Cookies.remove('token_classhelper');
        localStorage.clear();
                
        navigate('/login');
  
      }
    

    return(
        <Container>
            <Logo>
                <h1>class <em>helper</em></h1>
            </Logo>
            <Title>
                <h2>{infoUtils.screen}</h2>
            </Title>
            <Menu>
                {/* <img 
                    src={logoMessage} 
                    onClick={handleOpenChatModal}
                    alt="Mensagem" 
                /> */}

                {
                    userRole===1
                    ?<>
                        <a href="https://wa.me/5533991962158" target="_blank">
                        <img 
                            src={logoHelp} 
                            // onClick={handleOpenChatModal}
                            alt="Ajuda" 
                        />
                        </a>
                        <img src={cogImg} alt="Configurações" onClick={handleOpenUserModal} />
                    </>
                    :<img src={cogImg} alt="Configurações" onClick={handleOpenStudentConfigModal} />
                    
                }
                <img 
                    src={logoutImg} 
                    alt="Sair" 
                    onClick={logout}
                />
            </Menu>
        </Container>
    )
}