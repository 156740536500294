import { useInfoUtils } from "../../hooks/useInfoUtilsContext";
import { useNavigate } from 'react-router-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { api } from "../../services/api";



export function WatchClass(){


    const { infoUtils } = useInfoUtils()
    const [ userInfo, setUserInfo ] = useState({} as any)
    const [ userRole, setUserRole] = useState(0)
    const navigate = useNavigate();
    const [ userPersonalData, setUserPersonalData ] = useState({} as any)
    const [roomName, setRoomName ] = useState('')

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")

        // console.log('user-info==>',data)

        setUserInfo(data)
        setUserRole(data.userRole)

        if(data.userRole===2){
            setRoomName(`classhelper-123e-${data.userClasses[0]}-4567-e89b-12d3-a456-4266554-${teacherId}-40000`)
        }else{
            setRoomName(`classhelper-123e-${infoUtils.selectedClassId}-4567-e89b-12d3-a456-4266554-${teacherId}-40000`)
        }
    }


    useEffect(()=>{
        // if(!infoUtils.allowAccess){
        //     navigate('/purchase')
        // }
        getUserInfo()
    },[])

    const teacherId = "32221df41sd53f465s"

    const isSmallScreen = window.innerWidth<720?'90%':'70%'
    
    // userRole === 2
    // ?`classhelper-123e-${userInfo.userClasses[0]}-4567-e89b-12d3-a456-4266554-${teacherId}-40000`
    // :`classhelper-123e-${infoUtils.selectedClassId}-4567-e89b-12d3-a456-4266554-${teacherId}-40000`

    const buttonBar = userRole === 2
    ?[
        'microphone', 
        'camera', 
        'chat', 
        'raisehand', 
        'participants-pane',
        'tileview',
        'fullscreen',
        // 'hangup',
        '__end'
    ]
    :[
        'microphone', 
        'camera', 
        'chat', 
        'participants-pane',
        'tileview',
        'desktop',
        'recording',
        'fullscreen',
        // 'hangup',
        'shareaudio',
        'sharedvideo',
        '__end'
    ]

    // Object.defineProperty(window.navigator, 'userAgent', {
    //   get: function () { return 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/80.0.3987.163 Chrome/80.0.3987.163 Safari/537.36'; }
    // });
  
    return(
        <Container>

            {
                // userRole===1
                // ?<h3>teacher{infoUtils?.selectedClassId}</h3>
                // :userRole===2
                // ?<h3>student{userInfo?.userClasses[0]}</h3>
                // :null
            }
            {
                <>
                    {/* <h5>{roomName}</h5>
                    <h5>name: {userInfo.userName}</h5> */}
                </>
            }

        {
            roomName !== ''
            ?<JitsiMeeting
                roomName = { roomName } 
                // roomName="etrkkjhghfhgjjkjhgf65d467g89j0j8976tyuyiuoijkpij98h8g"
                getIFrameRef = { iframeRef => { iframeRef.style.height = '65vh'; iframeRef.style.width = isSmallScreen; } }
                configOverwrite = {{
                    startWithAudioMuted: true,
                    prejoinPageEnabled: false,
                    disableInviteFunctions: true,
                    hideMoreActionsButton: true,
                    subject: 'Tempo de aula',
                    toolbarButtons: buttonBar

                }}
                interfaceConfigOverwrite = {{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    SETTINGS_SECTIONS: [ 'devices', 'language', 'moderator' ],
                    CLOSE_PAGE_GUEST_HINT:true,
                    HIDE_INVITE_MORE_HEADER: true
                }}
                userInfo = {{
                    displayName: userInfo.userName,
                    email: '-'
                }}
            />
            :null
        }

        </Container>
    )
}