import { Container, Content } from "./styles";

export function About(){
    return(
        //@ts-ignore
        <Container id="about">
            <Content>

                <div>
                    <h2>Sobre</h2>
                    <p>O ClassHelper foi desenvolvido para professores particulares terem uma plataforma de ensino completa, prática e acessível.</p>
                    <p>Você pode disponibilizar arquivos, vídeos, gerir turmas e alunos, controlar pagamentos, mural de avisos e aula por vídeo sem sair da plataforma!</p>
                    <p>Assista ao vídeo ao lado, é menos de um minuto!</p>
                </div>

                
                <div className="centralizedVideo">
                    

                <iframe 
                    // width="90%" 
                    height="100%" 
                    style={{border:'none', aspectRatio: '16 / 9', marginTop:'1rem'}}
                    src="https://www.youtube.com/embed/vQIcVqFnhL8" 
                    title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" >
                
                </iframe>

                </div>
            </Content>
        </Container>
    )
}