import { Container } from "./styles";

export function Navigation({activeScreen, setActiveScreen}:any){
    return(
        <Container>
            <div className="colHere">
                <div  
                    className={activeScreen==="general"?"selectedNav":""}
                    onClick={()=>setActiveScreen('general')}
                >
                    <p>Geral</p>
                </div>
                <div  
                    className={activeScreen==="personal"?"selectedNav":""}
                    onClick={()=>setActiveScreen('personal')}
                >
                    <p>Meus dados</p>
                </div>
            </div>
            <div className="colHere">
                <div  
                    className={activeScreen==="config"?"selectedNav":""}
                    onClick={()=>setActiveScreen('config')}
                >
                    <p>Configurações</p>
                </div>
                <div  
                    className={activeScreen==="help"?"selectedNav":""}
                    onClick={()=>setActiveScreen('help')}
                >
                    <p>Ajuda</p>
                </div>

            </div>
        </Container>
    )
}