import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding: 1rem; */

    @media only screen and (max-width: 720px)  {
        flex-direction: column;
    }

    svg{
        margin: 0;
        padding:0;
    }

    .insertNew{
        display: flex;
        align-items: center;
        justify-content: space-between;

        input{
            display: flex;
            width:100%;
            height: 2rem;
            border-radius: 1rem;
            border: none;
            margin-right: 1rem;
            padding-left: 1rem;
        }
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 2rem;
            min-width: 2rem;
            max-height: 2rem;
            max-width: 2rem;
            border-radius: 1rem;
            font-weight: bold;
        }
    }
    

`;

export const Subjects = styled.div`

    display: flex;
    width: 50%;
    /* height: 100%; */
    padding: 1rem;
    flex-direction: column;
    border-right: 1px solid #ccc;

    @media only screen and (max-width: 720px)  {
        width: 100%;
        border: none;
    }

`;

export const Levels = styled.div`

    display: flex;
    width: 50%;
    padding: 1rem;
    flex-direction: column;
    @media only screen and (max-width: 720px)  {
        width: 100%;
    }

`;

export const Pill = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 2rem;
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #ccc;
    color: #333;
    margin-bottom: .2rem;
    transition: .3s;

    &:hover{
        border: 1px solid #666;
        transition: .3s;
    }

    img{
        width: .8rem;
        z-index: 10;
        transition: .3s;
        margin:0 .3rem ;
        &:hover{
            filter:brightness(.7);
            transition: .3s;
        }
    }

    svg{
        z-index: 10;
        margin:0 .3rem ;
        
    }

`