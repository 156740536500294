import styled from "styled-components";

export const Container = styled.div`

    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    vertical-align: middle;

    &:hover{
        background: #f2f2f2;
    }

    .titleStyle{
        display:flex;
        justify-content:flex-start;
        align-items: center;
        padding-left:1rem;
        text-align: left;

        @media only screen and (max-width: 720px)  {
            padding-left:0;
            justify-content:center;
        }
    }

    .orderEditor{

        .disable{
            color: #f4f4f4;
            cursor:not-allowed ;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        margin-left: 1rem;
        svg{
            margin: 0;
            color: #B7B7B7;
            cursor: pointer;
        }

    }

    div{
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        p{
            margin-top: 0;
        }
    }

    .showDiv{
        display: flex;
    }

    .hideDiv{
        display: none;
    }

    .imgIcon{
        cursor: pointer;
        transition: filter 0.2s;
        &:hover{
            filter: brightness(0.9);
        }
    }

    img{
        width: 1.5rem;
        margin-right: 15px;
    }

    .disableIcon{
        cursor: not-allowed;
        opacity: 0.1;
    }

   
`;



// export const ContentMenu = styled.div`

//     display: flex;
//     justify-content: space-around;

//     img{
//         width: 1.5rem;
//         margin-right: 15px;
//     }

// `;
