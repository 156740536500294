
import Modal from 'react-modal'
import { useModals } from '../../hooks/useModalsContext'
import { Container } from './styles'
import closeImg from '../../assets/images/close.svg'
import ReactAudioPlayer from 'react-audio-player'
import { api } from '../../services/api'
import { useEffect, useState } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

interface ContentFile {
    id: number;
    type: string;
    value: string;
    url:string;
    description:string;
  }


export function MediasModal(){

    const { isMediasModalOpen, 
            dataMediasModal, 
            handleCloseMediasModal, 
            contentId, 
            handleSetContentId } = useModals()
    const [files, setFiles] = useState<ContentFile[]>([])
    // const [ audioErro, setAudioError ] = useState(false)

    async function getMediaUrl(name:string, type:String){

      if(type === 'link'){
        return name
      }else if(type === 'video'){
  
        const str = name;
        const res = str.split("=");
        const res2 = res[1].split("&");
        const embeddedUrl = "https://www.youtube.com/embed/" + res2[0];
        return embeddedUrl
      }else{
        const   fileUrl   = await api.post('/generatedownloadlink',{
          fileName: name,
        })
    
        return fileUrl.data;
      }
      }

      async function getFiles() {


        const {data} = await api.get('getfiles/'+contentId)   
  
        const dataArr = Promise.all(data[0].contentitems.map(async (item:any)=>{
  
          return {
            ...item, 
            url:await getMediaUrl(item.value, item.type),
          }
        }))
  
        setFiles(await dataArr)
        
        
      }

      async function cleanFiles(){
        handleSetContentId(0)
        setFiles([])
        handleCloseMediasModal()
      }


      useEffect(()=>{

          if(contentId>0){
            getFiles()
          }
        
      },[contentId])

      useEffect(()=>{

        handleSetContentId(parseInt(dataMediasModal[0].id_content))

      },[dataMediasModal])


    return(
        <Modal 
          isOpen={isMediasModalOpen}
          onRequestClose={cleanFiles}
          overlayClassName="react-modal-overlay"
          className="react-modal-content full-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={cleanFiles} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>
          <h3>Mídias</h3>

          {files.map((file=>{
                return(
                    <div  key={file.id} className='mediaItem'>
                        {file.type.substring(0,5)==='image'
                        ?<img className='imageStyle' src={file.url} width={50} alt="" />
                        :file.type.substring(0,11)==='application' || file.type.substring(0,4)==='text'
                        ?<a href={file.url} target='blank'>Baixar {file.description}</a>
                        :file.type.substring(0,5)==='audio' //&& !audioErro
                        // ?<ReactAudioPlayer
                        //     src={file.url}
                        //     className='mediaPlayer'
                        //     controls
                        //     autoPlay={false}
                        //     onError={()=>setAudioError(true)}
                        // />
                        ?<audio controls autoPlay={false} className='mediaPlayer'>
                          <source src={file.url} type={file.type} />
                        </audio>
                        // :file.type.substring(0,5)==='audio' && audioErro
                        // ?<a href={file.url} target='blank'>Baixar áudio</a>
                        :file.type.substring(0,4)==='link'
                          ?<a href={file.url} target='blank'>Acessar link {file.description}</a>
                          :file.type.substring(0,5)==='video'
                          ?<>
                            <p style={{marginBottom:'.5rem'}}>{file.description}</p>
                            <iframe 
                              width="100%" 
                              height="100%" 
                              style={{border:'none', aspectRatio: '16 / 9'}}
                              src={file.url}
                              title="YouTube video player" 
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                          </>
                          :null
                        }
        
                </div>
                )
           }))}
          
         
          </Container>

        </Modal>
    )
}