import { useState } from "react"
import { api } from "../../services/api"
import { Container } from "./styles"

export function ChangePassword(){

    const [ newPass, setNewPass ] = useState('')
    const [ confirmPass, setConfirmPass ] = useState('')
    const [ message, setMessage ] = useState({type:'none', content:''})

    
    async function changePassword(){

        if(newPass === confirmPass){

            if(newPass.length<8){
                setMessage({type:'error',content:'A senha deve ter ao menos 8 caracteres!'})
            }else{
                const { data } = await api.post('changepassword',{password:newPass})
                if(data){
                    setMessage({type:'success',content:'Senha alterada com sucesso!'})
                }
            }

        }else{
            setMessage({type:'error',content:'As senhas digitadas devem ser iguais!'})
        }

    }

    return(
        <Container>
            
            <div>
                <input type="text" placeholder="Nova senha" onChange={(e)=>setNewPass(e.target.value)}/>
                <input type="text" placeholder="Confirme a senha" onChange={(e)=>setConfirmPass(e.target.value)}/>
                <button onClick={changePassword}>Alterar senha</button>
            </div>
            {
                message.type==='error'
                ?<p style={{color:'#a21300', marginTop:'.5rem'}}>{message.content}</p>
                :message.type==='success'
                ?<p style={{color:'#218838', marginTop:'.5rem'}}>{message.content}</p>
                :null
            }
            
        </Container>
    )
}