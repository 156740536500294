import styled from "styled-components";


export const Container = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--purple);
    
    height: 3rem;
    width: 3rem;
    border-radius: 1.5rem;
    position: fixed;
    bottom: 6rem;
    right: 2rem;

    cursor: pointer;

    h2{
        color: #FFF;
    }

`;