import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .titleMain{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        h5{
            color: #aaa;
            margin-right: 2rem;
        }
    }

    .mediaItem{
        background-color: white;
        padding: 1rem;
        border-radius: 1rem;
        margin: 1rem;
        position: relative;
        max-height: 5rem;

        @media only screen and (max-width: 720px)  {
            max-height: 8rem;
            margin: 1rem 0;
        }

        span{
            display: inline-block;
            width: 50%;

            @media only screen and (max-width: 720px)  {
                width: 100%;
            }

            input{
                border: none;
                border-bottom: 1px solid var(--purple) ;
                width: 80%;
                margin-top: 1rem;
                margin-left: 1rem;

                @media only screen and (max-width: 720px)  {
                    width: 100%;
                    margin-left: 0;
                }
            }

            p{
                cursor: pointer;
                margin-left: 1rem;
                color: #555;
                font-size: 12px;
                margin-top: .5rem;

                &:hover{
                    filter: brightness(1.5);
                }

                @media only screen and (max-width: 720px)  {
                    width: 100%;
                    margin-left: 0;
                }

            }

        }
    }

    .imageItem{
        max-height: 3rem;
    }

    .deleteIcon{
        cursor: pointer;
        width: 1rem;
        position: absolute;
        right: 1rem;
        &:hover{
            filter: brightness(.7);
        }
        z-index: 2;
    }

    .hideScreen{
        display: none;
    }

    .showScreen{
        width: 100%;
        display: block;
    }

    .fielditem{
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        min-width: 100%;
        margin: .2rem 0;
    }

    .saveArea{
        display: inline-flex;
        justify-content: flex-end;
        min-width: 100%;
        margin: .4rem .4rem;
    }

    .buttonArea{
        display: flex;
        justify-content: flex-start;
        min-width: 100%;
        margin: 1rem .4rem;
    }

    .buttonClass{
            margin-left: 1rem;
            width: 100px;
            background-color: var(--purple);
            color: #FFF;
            text-align: center;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-size: .8rem;
            display: flex;
            border-radius: .5rem;
            margin-top: 10px;
            cursor: pointer;
    }

    .smallText{
        color: #aaa;
        font-size: 10px;
    }

    .smallTextLink{
        color: #aaa;
        font-size: 10px;
        cursor: pointer;
        margin-left: 1rem;
    }

    .label{
        color: #555;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        margin-top: .5rem;
        margin-bottom: .2rem;
    }

    input[type="file"] {
        display: none;
    }

    button{
            padding: 5px 5px;
            min-width: 60px;
            background-color: var(--purple);
            color: #FFF;
            text-align: center;
            font-size: .8rem;
            display: flex;
            border-radius: .5rem;min-width: 60px;
            margin-top: 10px;
            cursor: pointer;
            border: none;

        }

`;


export const FieldCustom = styled.input`

    display: flex;
    width: 100%;
    min-height: 1.8rem;
    color: #555;
    border-radius: .5rem;
    border: none;
    padding-left: 1rem;    

`

export const FileField = styled.label`
    /* padding: 5px 5px; */
    width: 100px;
    background-color: var(--purple);
    color: #FFF;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: .8rem;
    display: flex;
    border-radius: .5rem;
    margin-top: 10px;
    cursor: pointer;

`