import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    /* min-height: 10rem; */
    background: #ececec;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    

`;

export const Footer = styled.div`
    width: 100%;
    min-height: 5rem;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`;


export const Content = styled.div`
    width: 80%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    
    @media (max-width: 720px){
         flex-direction: column;
         padding-top: 3rem;
         height: 60vh;
        }


    svg{
        display: inline;
        margin: 0;
        padding: 0;
    }

    .infoDiv{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .marginOnMobile{       
            margin: 2rem 0;
            @media (max-width: 720px){
                margin: 2rem 0;
            }
        }

        @media (max-width: 720px){
            width: 100%;
        }

        h1,p{
            color: #444;
        }
    }
    .socialDiv{

        width: 50%;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        padding-top: 2rem;

        @media (max-width: 720px){
            width: 100%;
            /* height: 20%; */
        }
        
        div{
            width: 60%;
            display: flex;
            justify-content: space-around;
            svg{
                margin: 0;
                padding: 0;
            }
        }

    }

    .formDiv{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* height: 100%; */

        input, textarea{
            border: none;
            border-radius: 1rem;
            color: #444;
            padding: .5rem;
            width: 100%;
            margin: .5rem 0;
            font-size: 1.2rem;
        }

        input{
            height: 2.4rem;
        }

        button{
            background: var(--purple);
            color: #FFF;
            font-weight: bold;
            font-size: 1.2rem;
            height: 2.4rem;
            width: 100%;
            border: none;
            border-radius: 1.2rem;
            margin-top: .5rem;
        }
    }
`;