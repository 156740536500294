import { Container, PostActions, PostComments, PostContent, PostDetails } from "./styles";


import { CommentItem } from "../../CommentItem";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { useInfoUtils } from "../../../hooks/useInfoUtilsContext";
import { format } from "date-fns";
import deleteImg from '../../../assets/images/delete.svg'

interface FeedItemDataProps {
    data:{
        id: number
        created_at: string
        id_class: number
        class_name: string
        text: string
        postitems: PostItemProps[]
    },
    reload:any
}

interface PostItemProps {
    id: number
    id_post: number
    type: string
    value: string
    imageUrl?:string
}


export function FeedItem({ data, reload }:FeedItemDataProps){

    const  { infoUtils }  = useInfoUtils()
    const [post, setPost] = useState(data)
    const [postItems, setPostItems] = useState<PostItemProps[]>(data.postitems)
    const [ userRole, setUserRole] = useState(0)

    async function getUserInfo(){
        const { data } = await api.get("/getuserinfo")
        setUserRole(data.userRole)
    }


    useEffect(()=>{

        getUserInfo()

        updateImageUrl()

    },[])

    async function updateImageUrl(){

        const temp = Promise.all(postItems.map(async item=>{
            if(item.type!=='image'){
                return item
            }
            return {
                ...item, 
                imageUrl:await getMediaUrl(item.value),
              }
        }))

        setPostItems(await temp)

    }

    async function getMediaUrl(name:string){

        const   fileUrl   = await api.post('/generatedownloadlink',{
          fileName: name,
        })
    
        return fileUrl.data;
    }

    async function deletePost(){

        if(post.postitems.length>0){
            if(post?.postitems[0].type === 'image'){
                deleteFile(post.postitems[0].value)
            }
        }


        await api.delete('/posts/'+post.id)

        reload()

    }


    async function deleteFile(name:string){

        const  { data }   = await api.post('/deleteobjects3',{
          fileName: name
        })
        
        
      }

    // const [ newComment, setNewComment ] = useState('')
    
    // const [ comments, setComments ] = useState(data.comments)


    // async function postNewComment(){

    //     let newData = [...comments, {
    //         id:parseInt(localStorage.getItem('classhelper_user_id') || ''),
    //         userName:localStorage.getItem('classhelper_user_name') || '',
    //         profileImage:localStorage.getItem('classhelper_user_avatar') || '',
    //         date:format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    //         content:newComment
    //     }]

    //     api.patch('/feed/'+data.id,{
    //         comments: newData
    //     })

    //     setComments(newData)
    //     setNewComment('')
    // }

    


    return(
        <Container>

            <PostDetails>
                <p><strong>{post.class_name}</strong> - {new Intl.DateTimeFormat('pt-BR',{ day:'numeric',month: 'short', year: '2-digit', hour:'numeric', minute:'numeric' }).format(new Date(post.created_at))}</p>
                {
                    userRole===1
                    ?<img alt='Excluir' className='deleteIcon' src={deleteImg} onClick={deletePost} />
                    :null
                }
                
            </PostDetails>

            <PostContent>
            <p>{post.text}</p>
                {
                    postItems.map((item)=>
                        
                        {

                            let embeddedUrl = ''

                            if(item.type==='video'){
                                const str = item.value;
                                const res = str.split("=");
                                const res2 = res[1].split("&");
                                embeddedUrl = "https://www.youtube.com/embed/" + res2[0];
                            }

                            

                            
                            
                            return(item.type==='image'
                        ?<img src={item.imageUrl} alt="Imagem" />
                        :item.type==='video'
                        ?<iframe 
                            width="100%" 
                            height="100%" 
                            style={{border:'none', aspectRatio: '16 / 9', marginTop:'1rem', borderRadius:'1rem'}}
                            src={embeddedUrl}
                            title="YouTube video player" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                        </iframe>
                        :null)})

                        
                }

                


                {/* {data.type==="image"?
                <img src={data.content} alt="Imagem" />
                :<p>{data.content}</p>} */}
                
            </PostContent>
            {/*<PostActions>
                <input type="text" placeholder="Digite seu comentário" value={newComment} onChange={(e)=>setNewComment(e.target.value)}/>
                <button onClick={postNewComment}>Comentar</button>
            </PostActions>
            {comments.length>0&&
            <PostComments>
                <strong>Comentários</strong>
                <ul>
                   {
                       comments.map((comment, index, arr)=> 
                       {
                           const prevComment = arr[index - 1];
                           
                           return (
                            <>
                                {prevComment!==undefined?<hr/>:""}
                                <CommentItem key={comment.id} data={ comment } />
                            </>
                           
                           )
                    }
                    )
                   }
                </ul>
            </PostComments>
            } */}
        </Container>
    )
}