import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Container } from './styles'


import closeImg from '../../../assets/images/close.svg'
import { useModals } from '../../../hooks/useModalsContext'



export function TermsModal(){

    const { isTermsModalOpen, handleCloseTermsModal } = useModals()


   


    return(
        <Modal 
          isOpen={isTermsModalOpen}
          onRequestClose={handleCloseTermsModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleCloseTermsModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

            <h3>Termos de uso e política de privacidade</h3>

            <p>Estes
são os termos que determinarão o nosso relacionamento
Este contrato refere-se ao sistema ou SOFTWARE&nbsp;de gerenciamento de
conteúdo de aulas Class Helper,
abrangendo qualquer uma de suas versões.</p>

<p>A
utilização do SOFTWARE por parte do CLIENTE, mesmo que parcial ou a título de
teste, indica que este está ciente dos termos deste concordando com </p>

<p>Este contrato é
documento legal entre a Class Helper,
empresa desenvolvedora do SOFTWARE , (EMPRESA) e a
portadora da licença de uso do SOFTWARE (CLIENTE), sendo pessoa física ou
jurídica, tenha ela adquirido ou não diretamente com a Class
Helper.</p>

<p >Ao
adquirir a licença de uso do SOFTWARE, presume-se que este contrato foi
devidamente lido e aceito compulsoriamente como base para o relacionamento entre
a EMPRESA e a CLIENTE.</p>

<p >Para
efeitos de melhor entendimento, o sistema Class Helper será referido neste contrato como SOFTWARE ou
SISTEMA.</p>

<p >Este
SOFTWARE é protegido pelas leis de direitos autorais e tratados internacionais
relativos a direitos autorais do Brasil e com os países que este possui
relações diplomáticas, bem como por outras leis e tratados de propriedade
intelectual. O SOFTWARE é licenciado para uso e não vendido.</p>

<p >A
EMPRESA se reserva o direito de modificar as condições apresentadas nestes Termos,
a qualquer tempo, por meio de atualização no próprio site. Estas modificações
entrarão em vigor após 7 (sete) dias da data de publicação no site. Sendo que a
cópia mais atualizada estará disponível para consulta no endereço&nbsp;https://legacy.classhelper.com.br</p>

<p >O
usuário, ao efetuar o pagamento do SOFTWARE junto à EMPRESA, adquire as
Licenças de Uso sem possibilidade de alugar, revender, ceder, emprestar,
transferir ou efetuar qualquer tipo de operação comercial aonde os direitos de
uso do SOFTWARE sejam transferidos do usuário que primeiro possui a licença em
seu nome. O </p>

<p >A
Licença de Uso permite apenas o uso do SOFTWARE sem nenhuma ressalva adicional
referente a produto ou serviço agregado, oferecido pela EMPRESA.</p>

<p >Após
o cadastro o usuário poderá usufruir do SOFTWARE por 7 (sete) dias e após esse período
deverá selecionar um plano de contratação, após o fim do período de vigência se
não houver contratação de um novo plano o acesso ao SOFTWARE será bloqueado.</p>

<p >O
SOFTWARE é disponibilizado no modelo SAAS (software como serviço), ou seja, o
SOFTWARE é disponibilizado em forma de aluguel, sendo pago de acordo com o
plano contratado pelo CLIENTE.</p>

<p >O
SOFTWARE é fornecido de forma online e sem nenhuma garantia de funcionamento
referente a forma, período, condição, equipamento ou elementos especiais de uso
para os quais o SOFTWARE não foi inicialmente planejado.</p>

<p >O
SOFTWARE foi desenvolvido para funcionar perfeitamente nos principais sistemas
operacionais (Windows e MacOSX) e através dos
navegadores Mozilla Firefox, Google Chrome e Safari. Esta garantia não inclui
os problemas originados por travamentos, conflitos ou falha de hardware,
conexão da Internet ou qualquer outro recurso ou elemento diretamente
controlado pelo sistema operacional. Este SOFTWARE está sujeito às exceções no
Acordo de Licença de Usuário Final (EULA) do SOFTWARE acima citado.</p>

<p >Ao
acessar este SOFTWARE, o usuário está ciente que o mesmo
se encontra com todos os componentes e que não há obrigação da EMPRESA em
fornecê-lo gravado em mídia de qualquer espécie referente a cópia e versão CLIENTE
e bem como qualquer versão a ser lançada além da forma que o mesmo obteve.</p>

<p >O
SOFTWARE poderá sofrer modificação, atualizações e adequações cujas alterações
não serão em nenhum momento de obrigação da EMPRESA.</p>

<p >O
SOFTWARE poderá ter modificado o seu sistema de proteção sem a necessidade de
aviso prévio da EMPRESA para com o usuário. A EMPRESA reserva-se o direito de,
periodicamente e sem prévio aviso, autenticar eletronicamente o SOFTWARE via
sua rede. Tal autenticação poderá resultar no bloqueio do uso do SOFTWARE caso o mesmo tenha sido alterado, violado ou divulgado de alguma
forma ou em algum meio. A EMPRESA rescindirá imediatamente a licença de uso
para com o usuário identificado como portador da licença.</p>

<p >Quando
ocorrer modificação do sistema de proteção do SOFTWARE, a EMPRESA fornecerá a
nova senha ou chave eletrônica de acesso para os usuários devidamente
licenciados e dentro do prazo de atualização, caso necessário.</p>

<p >A
EMPRESA abdica de qualquer responsabilidade que venha ser atribuída ao usuário,
bem como danos causados a equipamentos, sistemas, informações inseridas, redes
e terceiros de forma direta ou indireta.</p>

<p >O
Suporte oferecido é referente à erros do SOFTWARE, não tendo a EMPRESA nenhuma
obrigação de oferecer suporte sobre descrição de procedimentos e uso de
recursos do SOFTWARE, o CLIENTE tem acesso à tutorial de uso no próprio SISTEMA.</p>

<p >A
EMPRESA não fornece manual informativo impresso. Quando o fizer, não possui
nenhuma obrigação de refazer o mesmo com atualizações ou futuras versões.</p>

<p>O CLIENTE concorda
com o uso e registro de suas informações pessoais. O CLIENTE poderá cadastrar
outros usuários com perfil de aluno para ter acesso ao conteúdo inserido pelo
CLIENTE. O conteúdo inserido pelo CLIENTE é de sua total responsabilidade não
sendo permitidos conteúdos discriminatórios, pornográfico, que incitem ódio,
que firam direitos autorais ou que sejam ilegais de alguma forma, nesses casos
a EMPRESA se reserva o direito de excluir o conteúdo e até bloquear o acesso ao
SISTEMA.</p>

<p >O
Suporte Técnico fornecido à CLIENTE limita-se a esclarecimentos sobre o
funcionamento do SOFTWARE e sua operação, assim sendo, pressupõe-se o mínimo de
conhecimento do uso do computador por parte do(s) usuário(s), o que inclui o
uso do computador e suas funções, o uso do sistema operacional sob o qual o
sistema irá trabalhar, e do assunto que o SOFTWARE se propõe a resolver.
Pressupõe-se também uma configuração adequada do computador no que se refere a
utilização do SOFTWARE licenciado e o bom estado de funcionamento deste
computador. O Suporte Técnico limita-se ao atendimento aos clientes, não
estando incluídos neste serviço ligações telefônicas.</p>

<p >A
EMPRESA somente oferece suporte via correio eletrônico no endereço suporte@classhelper.com.</p>

<p >A
EMPRESA não oferece garantias de qualquer tipo de suporte oferecido por seus
técnicos ou representantes, salvo os referentes à utilização do SOFTWARE nas
condições para o qual foi projetado.</p>

<p >A
EMPRESA não se responsabiliza por danos derivados de perdas de dados e
corrupção de arquivos originados por problemas no uso do SOFTWARE.</p>

<p >Todos
os títulos e direitos autorais relativos ao SOFTWARE (incluindo, mas não se
limitando, a quaisquer imagens, fotografias, animações, vídeos, áudios, textos,
códigos, imagens, arquivos incorporados ao SOFTWARE), os materiais impressos
que o acompanhem e quaisquer cópias do SOFTWARE são de propriedade da EMPRESA.
O SOFTWARE é protegido pelas leis de direitos autorais e tratados
internacionais aplicáveis. Assim, a CLIENTE deverá tratar o SOFTWARE como
qualquer outro material protegido por direito autoral. É vedada a reprodução
dos materiais impressos e digitais que por ventura
venham a acompanhar o SOFTWARE.</p>

<p >A
EMPRESA reserva-se o direito de acesso às informações registradas para
avaliação de denúncias, remoção de conteúdo que fira o previamente estabelecido
e por solicitação das autoridades. O SISTEMA utiliza de serviços de terceiros
para algumas funções bem como para sua hospedagem. O acesso poderá ser
interrompido em caso de problemas com infraestrutura, upgrades e migrações.</p>

<p >Todas
as modificações, melhorias e correções efetuadas no SOFTWARE, mesmo que
informadas, solicitadas, e eventualmente pagas pela CLIENTE, ficam incorporadas
ao SOFTWARE e sujeitas aos termos desta LICENÇA DE USO DE SOFTWARE, podendo
inclusive serem disponibilizadas pela EMPRESA a outras CLIENTES que utilizem o
mesmo modelo de SOFTWARE, assim como a CLIENTE pode eventualmente receber
melhorias de funcionamento, correções e novos recursos no SOFTWARE que utiliza.</p>

<p >A
CLIENTE desde já concorda com modificações, implementação de novos recursos, ou
ferramentas, ou melhorias ou correções no modelo de SOFTWARE que utiliza,
ficando a critério da EMPRESA o gerenciamento e aprovação destas modificações
no SOFTWARE. A instalação das atualizações é feita pela EMPRESA de forma
automática no SOFTWARE.</p>

<p >A
EMPRESA oferece upgrade gratuito para a versão CLIENTE pelo período
indeterminado, sendo que o CLIENTE estará com o sistema atualizado sempre que
houver novas funcionalidades, atualização e melhorias a contar da data do
registro do SOFTWARE junto a EMPRESA ou um de seus representantes.</p>

<p >O
prazo para utilização do SOFTWARE é definido por tempo indeterminado, ou seja,
o usuário pode utilizar o SOFTWARE pelo tempo que desejar, devendo, durante
esse período, se manter adimplente com o pagamento da mensalidade.</p>

<p >A
CLIENTE pode rescindir este contrato a qualquer momento. Em nenhuma hipótese
serão devolvidos quaisquer valores pagos. A EMPRESA pode rescindir este
contrato no caso do não cumprimento pela CLIENTE de suas obrigações
contratuais.</p>

<p >Sobre
o CLIENTE: Tudo quanto for devido, em razão deste contrato e que não comporte o
processo executivo, será cobrado em ação competente, ficando a cargo do
devedor, em qualquer caso, os honorários do advogado que o credor constitui
para ressalva dos seus direitos. Parágrafo Único: A infração de quaisquer das
cláusulas ou condições do presente instrumento obrigará a parte que der causa à
rescisão, ressarcindo os prejuízos que causar a outra parte em razão de seu
inadimplemento.</p>

<p >Se
a EMPRESA identificar quebra de CÓDIGOS FONTES, e/ou cópia do produto para uso
próprio, revenda, estudo, demonstrações por parte da CLIENTE, fica estipulada
multa no valor de R$ 30.000,00 (trinta mil reais).</p>

<p >Sem
prejuízo de quaisquer outros direitos, a EMPRESA poderá rescindir este contrato
caso o usuário não cumpra com seus termos e condições. O usuário será
comunicado via e-mail ou telefone e deverá eliminar todas as informações
referentes ao SOFTWARE, bem como todos os seus componentes.</p>

<p >Em
casos que a CLIENTE desejar cancelar o contrato, a EMPRESA disponibiliza as informações
inseridas no sistema, entregando ao cliente em arquivo (em formato CSV) texto e
arquivos de mídia em arquivo compactado em um prazo de 30 (trinta) dias úteis,
após a solicitação junto ao suporte. Desta forma, a CLIENTE poderá utilizar
este arquivo para visualizar ou importar suas informações posteriormente.</p>

<p >A
EMPRESA efetua 01&nbsp;backup semanal dos dados do sistema. O
backup de arquivos de mídia não são de responsabilidade da EMPRESA. </p>

<p >A
tolerância ou não do exercício de quaisquer direitos pactuados no presente
instrumento por parte da EMPRESA não constitui novação, não serão tomados como
renúncia dos direitos e não impedindo, portanto, o seu posterior exercício.</p>

<p >Nenhum
vínculo se estabelecerá entre as partes CLIENTES, de natureza civil ou
societária, bem como qualquer vínculo ou relacionamento empregatício ou
trabalhista entre os prepostos de uma ou de outra.</p>

<p >As partes elegem o Foro da Comarca de Piracicaba/SP&nbsp;como
competente para dirimir qualquer dúvida do presente instrumento com desistência
de qualquer outro, por mais privilegiado que seja.</p>
          
          </Container>

        </Modal>
    )
}