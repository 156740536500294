import { Actions, Container } from "./styles";

import imgClass from '../../assets/images/class.svg'
import editClass from '../../assets/images/edit.svg'
import activeClass from '../../assets/images/enable.svg'
import inactiveClass from '../../assets/images/disable.svg'
import { FiUsers, FiEdit, FiTrash } from 'react-icons/fi'
import { CgBlock, CgUnblock } from 'react-icons/cg'
import { api } from "../../services/api";
import { useModals } from "../../hooks/useModalsContext";

interface ClassItemProps {
    data:{
        id: number
        name: string
        subject: string
        active: boolean
        students: []
    }
    getClasses:()=>void;
}

export function ClassItem({ data, getClasses }:ClassItemProps){

    const { handleOpenAddClassModal, handleSetDataClass, handleOpenStudentsModal } = useModals()
    

    async function inactiveClass(){

        await api.put('classes/'+data.id,{deactivate:true})
        getClasses()

    }

    async function activeClass(){

        await api.put('classes/'+data.id,{active:true})
        getClasses()

    }

    async function editClass(data:any){

        handleSetDataClass(data)
        handleOpenAddClassModal()

    }

    async function removeClass(data:any){
        const confirmation = window.confirm("Deseja realmente excluir essa turma e TODOS os alunos nela? \nATENÇÃO\nEssa ação é IRREVERSÍVEL")
        if(confirmation){
            const returned = await api.get('removeclassandstudents/'+data.id)
            getClasses()
        }
    }

    return(
        //@ts-ignore
        <Container active={data.active}>
            <div>
                <strong>{data.name}</strong>
            </div>
            <div>
                <p>{data.subject}</p>
            </div>
            <div className="containers">
                <FiUsers onClick={()=>handleOpenStudentsModal(data)} /> {data.students.length}
            </div>
            <Actions>
                <FiEdit onClick={()=>editClass(data)} />
                {data.active?<CgBlock onClick={inactiveClass} />:<CgUnblock onClick={activeClass} />}
                <FiTrash onClick={()=>removeClass(data)} />
            </Actions>
        </Container>
    )
}