import { useInfoUtils } from '../../hooks/useInfoUtilsContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ClassItem, Container, MainTable, TableHeader } from './styles';
import { api } from '../../services/api';
import { getMonth, getYear } from 'date-fns'
import { FiUsers } from 'react-icons/fi'
import { PaymentStudentsModal } from './PaymentStudentsModal';
import { useModals } from '../../hooks/useModalsContext';


export function Payments(){

    const { reloadPage } = useInfoUtils();
    const navigate = useNavigate();

    const [ classes, setClasses ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ totalPayed, setTotalPayed ] = useState(0)
    const [ userInfo, setUserInfo ] = useState({} as any)
    const [ month, setMonth ] = useState(getMonth(new Date())+1)
    const [ year, setYear ] = useState(getYear(new Date()))
    const [ years, setYears ] = useState([] as any)
    const [ months, setMonths ] = useState([])

    const {handleOpenPaymentStudentsModal, setDataPaymentStudentModal} = useModals()

    // async function openClassModal(item:any){

    //     setDataPaymentStudentModal(item);
    //     handleOpenPaymentStudentsModal(item);

    // }


    async function getUserInfo(){
        const {data} = await api.get("/getuserinfo")
        
        setUserInfo(data)


        const yearArr = [];

        if(data){

            for(let i=(getYear(new Date())-getYear(new Date(data.createdAt)));i<=1; i++){
                yearArr.push(getYear(new Date(data.createdAt))+i)
            }

            setYears(yearArr)
    

        }
        
        

        // setYear(getYear(data.createdAt))
        // setMonth(getMonth(data.createdAt))

    }


    async function getClasses(){


        const { data } = await api.get('getclassifcreated/'+year+'/'+month)

        //total

        const valuesArr = data.map((item:any) => item.students.map((student:any)=>student.student_value))

        let totalValue = ([...valuesArr].reduce((acc, curVal) => acc.concat(curVal), [])).reduce((accumulator:any, value:any) => {
            return accumulator + value;
          }, 0);
        
        setTotal(totalValue)

        //total payed

        const valuesPayedArr = data.map((item:any) => item.students.map((student:any)=>student.payments.map((payment:any)=>payment.value)))

        let totalPayedValue = ([...valuesPayedArr].reduce((acc, curVal) => acc.concat(curVal), [])).reduce((acc:any, curVal:any) => acc.concat(curVal), []).reduce((accumulator:any, value:any) => {
            return accumulator + value;
          }, 0);
        
        setTotalPayed(totalPayedValue)

        
        
        // const total = data.map((classItem:any)=>console.log((classItem.students.filter((student:any)=>student?.payments?.length>0)).length))          
        
        setClasses(data)
        
    }

    useEffect(() => {

        getUserInfo()
        
    }, [])

    useEffect(()=>{
        getClasses()
    },[year, month, reloadPage])

    return(
        <Container>
            <MainTable>
                <TableHeader>
                    {/* <p>Mês</p>  */}
                    <select name="" id="">
                        {
                            years.map((item:any)=><option value={item}>{item}</option>)
                        }
                        
                    </select>
                    <select onChange={(e)=>setMonth(parseInt(e.target.value))}>
                        <option value={1} selected={month===1?true:false} >Jan</option>
                        <option value={2} selected={month===2?true:false}>Fev</option>
                        <option value={3} selected={month===3?true:false}>Mar</option>
                        <option value={4} selected={month===4?true:false}>Abr</option>
                        <option value={5} selected={month===5?true:false}>Mai</option>
                        <option value={6} selected={month===6?true:false}>Jun</option>
                        <option value={7} selected={month===7?true:false}>Jul</option>
                        <option value={8} selected={month===8?true:false}>Ago</option>
                        <option value={9} selected={month===9?true:false}>Set</option>
                        <option value={10} selected={month===10?true:false}>Out</option>
                        <option value={11} selected={month===11?true:false}>Nov</option>
                        <option value={12} selected={month===12?true:false}>Dez</option>
                    </select>
                    <span style={{display:'flex', width:'100%', justifyContent:'center'}}>
                        <p>Total R$ {totalPayed} / R$ {total}</p>
                    </span>
                </TableHeader>
                {
                    classes.length>0
                    ?classes.map((item:any)=>{
                        // let sum = 0
                        // for (let i = 0; i < item?.student?.length; i++) {
                        //     sum += item[i]?.students?.payments?.length
                        // }

                        // item.total = item?.student?.reduce((prev:any, curr:any) => prev?.payments?.lenght + curr?.payments?.lenght, 0)
                        item.total = item.students.filter((student:any)=>student?.payments?.length>0).length
                        
                        return(
                            //@ts-ignore
                            <ClassItem onClick={()=>handleOpenPaymentStudentsModal({...item, year, month})}>
                                <div>
                                    <strong>{item?.name}</strong>
                                </div>
                                <div style={{display:'flex'}}>
                                    <FiUsers />
                                    <p style={{marginLeft:'1rem'}}>
                                        {/* {
                                            item.total = item.map((item:any)=>{
                                                let sum = 0
                                                for (let i = 0; i < item?.length; i++) {


                                                    for (let i = 0; i < item?.student?.length; i++) {
                                                        sum += item[i]?.students?.payments?.length
                                                    }
                                                    
                                                
                                                }
                                                return sum

                                               
                                                
                                            })
                                        } */}
                                        {item.total}/{item?.students.length}</p>
                                </div>
                                
                            </ClassItem>
                        )
                    })
                    :<div style={{width:'100%', marginTop:'4rem', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <p style={{color:'#666'}}>Sem classes cadastradas</p>
                    </div>
                }
            </MainTable>

            <PaymentStudentsModal />
        </Container>
    )
}