import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 3rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    p,strong{
        display: inline;
        color: var(--text-body);
        margin-top: 10%;
        text-align: center;
    }

`;

export const MainTable = styled.div`

    width: 80%;
    border-spacing: 0 0.5rem;
    margin-bottom: 120px;

    @media only screen and (max-width: 720px)  {
        width: 90%;
    }

    div{
        width: 100%;
    }


`;

export const TableHeader = styled.div`
    
        color: #FFF;
        font-weight: 400;
        padding: 1rem;
        text-align: left;
        line-height: 1.5rem;
        background: var(--purple);

        h3{
            color: #FFF;
        }  
`;