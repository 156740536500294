import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Container, Menu, NewStudent, Pill, Students, PillUser } from './styles'

import { isValid, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR';

import closeImg from '../../../assets/images/close.svg'
import { useModals } from '../../../hooks/useModalsContext'
import { api } from '../../../services/api'
import { useInfoUtils } from '../../../hooks/useInfoUtilsContext'



import { AiFillDelete } from 'react-icons/ai'
import { BsPrinter } from 'react-icons/bs'




export function PaymentStudentsModal(){

    const { isPaymentStudentsModalOpen, handleClosePaymentStudentsModal, dataPaymentStudentModal, setDataPaymentStudentModal } = useModals()
    // const [ newStudentName, setNewStudentName ] = useState('')
    // const [ newStudentEmail, setNewStudentEmail ] = useState('')
    // const [ newStudentValue, setNewStudentValue ] = useState(0)
    const [ students, setStudents ] = useState([] as any)
    // const [ editingStudent, setEditingStudent ] = useState({} as any)
    const { reload } = useInfoUtils();
    // const [ invalidEmail, setInvalidEmail ] = useState('none')
    
    

    async function getClass(){

      const year = dataPaymentStudentModal.year
      const month = dataPaymentStudentModal.month

      const { data } = await api.post('getoneclasswithpayments/'+dataPaymentStudentModal.id,{month:month, year:year})

      if(data){
        
        // console.log({...data[0], month, year})
        setDataPaymentStudentModal({...data[0], month, year})
        reload()
      }
      
    }

    async function deletePayment(id:number){

      const confirmation = window.confirm("Confirma a exclusão do pagamento?")
      if(confirmation){
        await api.delete('payments/'+id)
        getClass()
      }
      
    }

    async function studentPay(id:number){

      let paymentDate = ''
      let paymentValue = ''


      if(document.getElementById('date'+id)){
        paymentDate = (document.getElementById('date'+id) as HTMLInputElement).value
      }

      if(document.getElementById('value'+id)){
        paymentValue = (document.getElementById('value'+id) as HTMLInputElement).value
      }

      if(paymentDate==='' || paymentValue===''){
        alert('Insira data e valor. Para valor zerado insira o número 0')
      }else{
        if(isValid(new Date(paymentDate))){
          if(parseInt(paymentValue) >= 0){

            

            const payload = {
              year:dataPaymentStudentModal.year,
              month:dataPaymentStudentModal.month,
              date:paymentDate,
              id_student:id,
              id_teacher:dataPaymentStudentModal.id_teacher,
              value:paymentValue,
            }

            const {data} = await api.post('payments', payload)

            if(data){
              getClass()
            }

          }else{
            alert('Insira um valor válido')
          }
        }else{
          alert('Insira uma data válida')
        }
        
      }
      
      
    }

    

    useEffect(()=>{

  
      if(dataPaymentStudentModal?.students){
          
        setStudents(dataPaymentStudentModal?.students)
      }
    },[dataPaymentStudentModal])


   


    return(
        <Modal 
          isOpen={isPaymentStudentsModalOpen}
          onRequestClose={handleClosePaymentStudentsModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content percent90"
          appElement={document.getElementById('root') || undefined}
        >

        <button 
            type="button" 
            onClick={handleClosePaymentStudentsModal} 
            className="react-modal-close"
          >
            <img src={closeImg} alt="Fechar modal" />
        </button>

          <Container>

            <h3>Alunos</h3>
            
          
            
            <Students>

              {
                students !== undefined && students.length >0
                ?students.map((item:any)=>{
                  return(
                    //@ts-ignore
                    <PillUser className={item?.payments?.length>0?'payed':''}>
                          <span>
                            <div className='name'>
                              <strong>{item?.name}</strong>
                            </div>
                          </span>
                          {
                            item?.payments?.length>0
                            ?<span className='spanPayed'>
                              <p>R$ {item?.payments[0]?.value}</p>
                              <p>{format(new Date(item?.payments[0]?.date), 'dd/MM/yy', {locale:ptBR})}</p>
                              <strong>PAGO</strong>
                              <AiFillDelete
                                onClick={()=>deletePayment(item?.payments[0]?.id)}
                              />
                              {/* <BsPrinter /> */}
                            </span>
                            :<span>
                              <div style={{fontSize:'.8rem'}}>
                                  <input id={'date'+item.id} type="date" />
                                </div>
                                <div style={{justifyContent:'center'}}>
                                  <input id={'value'+item.id} style={{width:'4rem'}} type="number" defaultValue={item?.student_value} />
                                </div>
                                <div>
                                  <button onClick={()=>studentPay(item.id)}>Pagar</button>
                                </div>
                              </span>
                          }
                          
                        </PillUser>
                  )
                })
                :null
              }

            </Students>
          
          </Container>

        </Modal>
    )
}