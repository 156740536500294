import styled from "styled-components";

export const Container = styled.div`
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
    margin: 0 auto;
    width: 75%;
    padding-top: 1rem;
    @media (max-width: 720px){
        width: 100%;
    }

button{
        height: 3rem;
        color: #FFF;
        font-size: 1.2rem;
        background: var(--purple);
        border-radius: .25rem;
        border: 0;
        margin: 1rem 0;
    }
    
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;

    h1{
        color: var(--purple);
        font-weight: 400;
        font-size: 20px;

        display: inline-block;
        
        em{
            display: inline-block;
            padding: .3rem;
            background: var(--purple);
            color: #FFF;
            font-style: normal;

        }
    }
    
`;