import styled from 'styled-components'

export const Container = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    font-size: 2rem;
    padding: 30% 0;

    a:link, a:visited{
        color: #fff;
    }

    .rounded{
        border: 2px solid #fff;
        padding: .5rem 1rem;
        /* height: 4rem; */
        border-radius: 2rem;
    }

`;