import { Link } from "react-router-dom";
import { Container, Logo, NavItem, Access, MenuItems, MobileMenu } from "./styles";

import {FiMenu} from 'react-icons/fi'
import { SocialItems } from "../SocialItems";
import { useModals } from "../../../hooks/useModalsContext";

export function Navbar(){

    const { isMenuModalOpen, handleOpenMenuModal} = useModals()

    return(
        <Container>

            <Logo>
                <h1>class <em>helper</em></h1>
            </Logo>
            
            <MenuItems>
                <NavItem>
                    <a href="#home">início</a>
                </NavItem>
                <NavItem>
                    <a href="#about">sobre</a>
                </NavItem>
                <NavItem>
                    <a href="#register">cadastrar</a>
                </NavItem>
                <NavItem>
                    <a href="#contact">contato</a>
                </NavItem>
            </MenuItems>

            <SocialItems bigScreen />

            

            <Access>
                <Link className="fullButton" to="/login" >
                    <p>entrar</p>
                </Link>
            </Access>

            <MobileMenu>
                <FiMenu onClick={handleOpenMenuModal} />
            </MobileMenu>

        </Container>
        
    )
}