import { Container, Content, Footer } from "./styles";
import { IoMdPin } from 'react-icons/io'
import { SocialItems } from "../SocialItems";
import { useModals } from "../../../hooks/useModalsContext";
import { useState } from "react";
import { api } from "../../../services/api";

export function Contatct(){

    const { handleOpenTermsModal } = useModals()
    const [ email, setEmail ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ status, setStatus ] = useState({status:'none', message:''})

    async function sendMessage(e){

        e.preventDefault()

        if(email === '' || message === ''){
            window.alert('Digite seu e-mail e uma mensagem!')
        }else{

            // console.log({email:email,message:message})
            try {
                const data = await api.post('/contactus',{email, emailform:email,message:message})
                // console.log(data)

                if(data){
                    setStatus({status:'success', message:"Mensagem enviada com sucesso!"})
                }else{
                    setStatus({status:'error', message:"Erro ao enviar! Tente novamente mais tarde ou entre em contato pelo e-mail: contato@classhelper.com"})
                }
                
            } catch (error) {
                setStatus({status:'error', message:"Erro ao enviar! Tente novamente mais tarde ou entre em contato pelo e-mail: contato@classhelper.com"})
            }


            


        }

    }

    return(
        <Container id="contact">
            <Content>
                <div className="infoDiv">
                    <h1> <IoMdPin /> São Paulo, Brasil</h1>
                    <a className="marginOnMobile" href="mailto:contato@classhelper.com.br">contato@classhelper.com.br</a>
                    <SocialItems style={{justifyContent:'flex-start !important'}} />   
                </div>
                <div className="socialDiv">
                    {
                        status.status==='none'
                        ?<form className="formDiv" onSubmit={(e) => sendMessage(e)}>
                            <input type="email" placeholder="E-mail" onChange={(e)=>setEmail(e.target.value)} />
                            <textarea rows="5" onChange={(e)=>setMessage(e.target.value)} ></textarea>
                            <button type="submit">Enviar mensagem</button>
                        </form> 
                        :status.status==='success'
                        ?<p style={{color:'#218838', margin:'1rem', textAlign:'center'}}>{status.message}</p>
                        :<p style={{color:'#ad1300', margin:'1rem', textAlign:'center'}}>{status.message}</p>
                    }
                </div>
            </Content>
            <Footer>
                <p  style={{fontSize:'.8rem'}}>Copyright © Class Helper 2021 | <a href="#" onClick={handleOpenTermsModal}>Termos de uso e políticas de privacidade</a></p>
                <p style={{fontSize:'.8rem', marginTop:'.5rem'}}>Desenvolvido por Haode Soluções</p>
            </Footer>
        </Container>
    )
}