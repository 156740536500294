import styled from "styled-components";


export const Container = styled.div`

    display: flex;
    flex-direction: column;
    padding: 1rem;
    width:100%;

    p{
        color: #888;
        font-size: 1rem;
        margin-bottom: .5rem;
    }

    li{
        margin-left: 1.2rem;
    }
    ul{
        list-style-type: square;
        color: #888;
    }

    .planButons{
        width: 100%;
        display: flex;
        justify-content: space-around;

        button{
            width: 49%;           
            height: 1.6rem;
            border-radius: 0.8rem;

        }

        .selectedPlan{
            }

        .unselectedPlan{
            background: #888;
            opacity: .5;
        }
    }

`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width:720px;
    margin: 0 auto;

    

    @media (max-width: 720px){
        flex-direction: column;
        }

`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 15rem;
    align-items: center;
    justify-content: space-between;
    margin: .4rem;
    padding: .4rem;
    padding-top: 1rem;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 3px rgba(0,0,0,.1);
    transition: all .5s;
    background: #8D72E1;

    div{
        height: 10rem;
        margin-top: 1rem;
    }

    h5,h4,h3,p, strong{
        color: #eee;
    }

    hr{
        border: 1px solid rgba(255,255,255,.5);
        width: 30%;
        margin-top: 1rem;
    }

    &:hover{
        box-shadow: 3px 3px 5px rgba(0,0,0,.3);
        transition: all .5s;
    }

    &:first-child{
        /* margin-right: 0; */
        background: #9F73AB;
        
    }
    &:last-child{
        /* margin-right: 0; */
        background: #7978FF;
    }

    &:hover{
            /* background: var(--purple); */
            scale: 1.2;
    }


`;
